import dayjs from 'dayjs';

type FormatOptions = {
  precision?: number;
  strict?: boolean;
};
type DateOptions = {
  // format string options: https://github.com/iamkun/dayjs/blob/dev/docs/en/API-reference.md#format-formatstringwithtokens-string
  format: string;
};
export function formatNumber(number: number, options?: FormatOptions): string {
  const {
    precision = 2,
    strict = true,
  } = options || {};
  const isInt = Math.round(number) === number;
  const decimalPlaces = isInt && !strict ? 0 : precision;

  return number.toFixed(decimalPlaces);
}
export function numberToCurrency(number: number, options?: FormatOptions): string {
  return `${formatNumber(number, options)} zł`;
}
export function numberToPercentage(number: number): string {
  return `${formatNumber(number)}%`;
}
export function formatDate(date: string, options?: DateOptions): string {
  const {
    format = 'DD.MM.YYYY',
  } = options || {};

  if (!date) return '';

  return dayjs(date).format(format);
}
export function termToMonths(term: number | null | undefined): string {
  if (typeof term !== 'number') {
    return '';
  }

  return dayjs().to(dayjs().add(term, 'month'), true);
}
