export default ({ colors, mediaQueries }) => ({
  container: {
    [mediaQueries.breakpointTablet]: {
      padding: '5px 0',
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    padding: '8px 3px',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.darkGrayLine}`,
  },
  greyedOut: {
    background: colors.monotone1,
    color: colors.monotone5,
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '46px',
    height: '24px',
  },
  lastRow: {
    borderBottom: 'none',
  },
  leftChild: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    borderRight: `1px solid ${colors.darkGrayLine}`,
    '& svg': {
      fill: colors.error,
      marginRight: '8px',
      height: '12px',
    },
    '@media screen and (max-width: 320px)': {
      flexDirection: 'column',
    },
  },
  naming: {
    fontSize: '13px',
    fontWeight: 400,
    alignSelf: 'flex-start',
    [mediaQueries.breakpointTablet]: {
      fontSize: '11px',
      color: colors.lightGray,
      lineHeight: '18px',
    },
  },
  data: {
    fontSize: '13px',
    fontWeight: 700,
    marginLeft: 'auto',
    paddingRight: '15px',
    textAlign: 'right',
    [mediaQueries.breakpointTablet]: {
      fontFamily: 'CoreSans Condensed',
      fontWeight: 400,
    },
  },
  pen: {
    fill: colors.secondary,
    width: '11px',
    height: '11px',
    transition: 'opacity .2s',
    opacity: '.75',
    '&:hover': {
      opacity: '1',
    },
  },
  error: {
    color: colors.error,
    '& .naming': {
      color: colors.error,
    },
  },
});
