/* eslint-disable no-else-return */
import emojiRegex from 'emoji-regex';
import XRegExp from 'xregexp';

function passwordStrict() {
  return (value: string): string | null => {
    if (emojiRegex().test(value)) {
      return 'password_contains_prohibited_characters';
    } else if (value && value.startsWith(' ')) {
      return 'password_cannot_start_with_space';
    } else if (value && value.endsWith(' ')) {
      return 'password_cannot_end_with_space';
    } else if (value && !XRegExp('\\p{N}').test(value)) {
      return 'password_needs_number';
    } else if (value && !XRegExp('\\p{Ll}').test(value)) {
      return 'password_needs_small_letter';
    } else if (value && !XRegExp('\\p{Lu}').test(value)) {
      return 'password_needs_capital_letter';
    } else if (value && !XRegExp('[\\p{P}\\p{S}]+').test(value)) {
      return 'password_needs_special_character';
    }

    return null;
  };
}

export default passwordStrict;
