export default (({
  colors,
}) => ({
  addressHeading: {
    margin: '32px 0 8px 0',
  },
  editableFields: {
    '& label': {
      paddingRight: '54px',
    },
    '& input': {
      paddingRight: '8px',
    },
  },
  submit: {
    marginTop: '16px',
  },
  backButton: {
    marginTop: '16px',
    marginRight: '10px',
    backgroundColor: colors.gray,
  },
  // `TextField` overrides
  actionButton: {
    textDecoration: 'none',
    color: colors.primary,
    cursor: 'pointer !important',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      display: 'none !important',
    },
  },
  deleteButton: {
    display: 'none',
  },
}));
