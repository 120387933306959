import platform from 'platform';

const mobileOsList = ['Android', 'iOS', 'Windows Phone'];

export default function getDeviceType() {
  const userAgent = window.navigator.userAgent;
  const detected = platform.parse(userAgent);

  return mobileOsList.indexOf(detected.os.family) !== -1 ? 'mobile' : 'desktop';
}
