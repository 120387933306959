import * as guards from '@common/routeGuards';
import * as R from '../routesList';
import { forbidMissingBank } from '@common/settings/guards';

const genericGuards = [guards.requireAuth, guards.forbidBlacklistedUser];
const dashboardRoutes = {};

R.SETTINGS_ROUTES.forEach(route => {
  Object.assign(dashboardRoutes, {
    [route]: [...genericGuards, route === R.CONFIRM_BANK_ACCOUNT && forbidMissingBank],
  });
});
export default {
  [R.DASHBOARD]: [...genericGuards,
  //  guards.forbidOpenLoanApplicationWithNoEmploymentData,
    guards.forbidOpenLoanApplicationWithNoBankAccountNumber],
  [R.PAYMENT]: [...genericGuards, guards.requireActiveLoan],
  [R.AGREEMENTS]: [...genericGuards],
  [R.APPLICATION_CONFIRM_BANK_ACCOUNT]: [...genericGuards, guards.requireBankAccount],
  [R.EXTENSION]: [...genericGuards, guards.requireActiveLoan, guards.requireExtensionPossible],
  [R.APPLICATION_ADDITIONAL_AMOUNT]: [...genericGuards, guards.requireActiveLoan, guards.requireAdditionalAmountPossible],
  ...dashboardRoutes,
};
