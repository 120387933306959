import { reducer as api } from './api';
import { reducer as apiUrls } from '4finance-http-pl';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { reducer as onionForm } from '4finance-onion-form-pl';
import { pendingActionsReducer as pendingActions } from '4finance-connect-pl';
import { State } from './types';
import authentication from './authentication/reducer';
import calculator from './calculator/reducer';
import dashboard from './dashboard/reducer';
import persistence from './lib/persistence/reducer';
import registration from './registration/reducer';
import settings from './settings/reducer';

export default ((history: History): State => ({
  api,
  apiUrls,
  authentication,
  calculator,
  dashboard,
  onionForm,
  pendingActions,
  persistence,
  registration,
  router: connectRouter(history),
  settings,
}));
