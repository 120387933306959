import * as R from '../../../../routes/routesList';

import { BodyText, Hyperlink } from '../../../../shared/components/typography';
import { compose } from 'redux';
import { connectActions, connectSelectors, connectState } from '@browser/connect';
import { RouteAction } from '@common/types';
import { Section } from '@shared/components/layout';
import { useCallback, useEffect } from 'react';
import { useTranslator } from '@localizations';
import Button from '@shared/components/Button';
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import Message from '@dynamic/components/Message';
import QA_CLASSES from '../../../../lib/qa';
import styles from './PennyTransfer.jss';
import type { IdentificationBank } from '@common/registration/actions';
import type { ImmutableMap } from '@common/lib/types';

type Props = {
  classes: Record<keyof ReturnType<typeof styles>, any>;
  updatePath: RouteAction;
  selectedBank: IdentificationBank;
  masterAccount: ImmutableMap;
  clientStatus: string,
  clientBankAccount: string,
  amount: number,
  term: number,
};

const PennyTransfer = (props: Props): JSX.Element => {
  const {
    classes,
    updatePath,
    selectedBank,
    masterAccount,
    clientStatus,
    amount,
    term,
    clientBankAccount,
  } = props;
  const {
    msg,
    cnt,
  } = useTranslator();
  const transferBank = selectedBank?.accountNumber ? selectedBank : masterAccount?.toJS?.();
  const isIdentified = clientStatus === 'IDENTIFIED';
  const pennyTransfer = isIdentified ? 'penny_transfer_identified' : 'penny_transfer';

  useEffect(() => {
    if (isIdentified && !!clientBankAccount) {
      updatePath(R.DASHBOARD);
    }
  }, [isIdentified, clientBankAccount]);

  const getMessage = useCallback(
    () => {
      if (!isIdentified) {
        return 'registration.penny_transfer.message';
      }

      if (term === 1) {
        return 'registration.penny_transfer_identified.message_singular';
      }

      if (term > 1 && term <= 4) {
        return 'registration.penny_transfer_identified.message_plural';
      }

      return 'registration.penny_transfer_identified.message_plural_over_4';
    },
    [isIdentified, term],
  );

  return (
    <>
      {isIdentified && (
        <Message
          body={msg(getMessage(), { amount, term })}
          className={classnames(classes.message, QA_CLASSES.ACCOUNT_NUMBER_MESSAGE)}
        />
      )}

      <Section gray title={msg(`registration.${pennyTransfer}.title`)} separator>
        <div className={classes.root}>
          <BodyText>
            {cnt(`registration.${pennyTransfer}.subtitle`)}
          </BodyText>
          <div className={classes.details}>
            {transferBank && (
            <>
              <BodyText>
                <b>{msg(`registration.${pennyTransfer}.receiver`)}</b>
                <span className={QA_CLASSES.RECEIVER}>
                  {transferBank.receiver}
                </span>
              </BodyText>
              <BodyText>
                <b>{msg(`registration.${pennyTransfer}.account`)}</b>
                <span className={QA_CLASSES.BANK_ACCOUNT_NUMBER}>
                  {transferBank.accountNumber}
                </span>
              </BodyText>
              <BodyText>
                <b>{msg(`registration.${pennyTransfer}.transfer_title`)}</b>
                <span className={QA_CLASSES.IDENTIFICATION_TRANSFER_NUMBER}>
                  {msg(`registration.${pennyTransfer}.transfer_title_text`, {
                    clientNumber: (transferBank.clientIdentificationNumber as any),
                  })}
                </span>
              </BodyText>
            </>
            )}
          </div>
          <BodyText className={classes.marginBottom}>
            {msg('registration.penny_transfer.post_revolut_info')}
          </BodyText>
          <BodyText className={classes.marginBottom}>
            {msg('registration.penny_transfer.legal')}
          </BodyText>
          {selectedBank?.homePageUrl && (
          <Button secondary to={selectedBank.homePageUrl} className={classes.marginBottom} external>
            {msg('registration.penny_transfer.button')}
          </Button>
          )}
          <BodyText className={classes.marginBottom}>
            {msg('registration.penny_transfer.footnote')}
          </BodyText>
          <Hyperlink to={R.CLIENT_SETTINGS}>
            {msg('registration.penny_transfer.go_to_profile')}
          </Hyperlink>
        </div>
      </Section>
    </>
  );
};

PennyTransfer.styleRoot = 'PennyTransfer';
export default compose<any>(connectSelectors({
  masterAccount: ['registration', 'masterAccount'],
}),
connectActions({
  updatePath: ['router', 'updatePath'],
}),
connectState({
  selectedBank: ['registration', 'bank'],
  clientStatus: ['api', 'fetch', 'client', 'query', 'data', 'status'],
  clientBankAccount: ['api', 'fetch', 'client', 'query', 'data', 'bankAccount'],
  amount: ['api', 'fetch', 'client', 'application', 'query', 'data', 'amount'],
  term: ['api', 'fetch', 'client', 'application', 'query', 'data', 'term'],
}), injectStyles(styles))(PennyTransfer);
