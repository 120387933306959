import * as R from './routesList';
import { Children, cloneElement, isValidElement, useCallback, useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { connectActions } from '../connect';
import { matchPath, withRouter } from 'react-router-dom';
import { pick } from 'lodash';
import { sha256 } from 'js-sha256';
import getApiResponseCreator from '../../common/lib/store/getApiResponse';
import sendGTMEvent from '../../common/lib/sendGTMEvent';

type Props = {
  children: JSX.Element;
  location: Record<string, any>;
  staticContext: Record<string, any>;
  updatePath: (...args: Array<any>) => any;
};

function getTransactionType(loansCount, agreementType) {
  if (loansCount === undefined) {
    return '';
  }

  const typePrefix = loansCount > 1 ? 'old' : 'new';

  switch (agreementType) {
    case 'MAIN':
      return `${typePrefix}_main`;

    case 'NEW_AMOUNT':
      return `${typePrefix}_additional`;

    default:
      return `${typePrefix}_other`;
  }
}

// eslint-disable-next-line consistent-return
function trackAdditionalPageChangeEvent(pagePath): void {
  switch (pagePath) {
    case R.REGISTER:
      return sendGTMEvent({
        event: 'registration_contact_consent',
      });

    case R.PROFILE:
      return sendGTMEvent({
        event: 'registration_personal_address',
      });

      // case R.EMPLOYMENT:
      //   return sendGTMEvent({
      //     event: 'registration_employment',
      //   });

    case R.APPLICATION_CREDIT_CHECK:
      return sendGTMEvent({
        event: 'credit_check',
      });
  }
}

const standardize = (value: string | number, hash = true) => {
  const result = value.toString().trim().toLowerCase();

  return hash ? sha256(result) : result;
};

const standardizeMobilePhone = (value: string | number) => {
  const result = value.toString().trim().toLowerCase();

  return sha256(`+48${result}`);
};

const standardizeEmail = (value: string | number) => {
  let result = value.toString().trim().toLowerCase();

  // Remove dot after the domain name
  result = result.replace(/@(.+)\./, '@$1');

  return sha256(result);
};

async function trackPageChangeGTMEvent(pagePath, {
  getApiResponse,
  getState,
}: Record<string, any>) {
  if (typeof window === 'undefined') {
    return;
  }

  const baseEvent = {
    event: 'page_change',
    pagePath,
  };

  trackAdditionalPageChangeEvent(pagePath);

  if (!getState().authentication.get('isLoggedIn')) {
    sendGTMEvent(baseEvent);

    return;
  }

  const client = await getApiResponse(['fetch', 'client'], {
    forceFetch: true,
  });
  const loans = await getApiResponse(['fetch', 'client', 'loans'], {
    forceFetch: true,
  });
  const latestLoan = await getApiResponse(['fetch', 'client', 'loans'], {
    dynamicKeys: ['latest'],
    actionName: 'fetchClientLoansByLoanid',
    forceFetch: true,
  });
  const application = await getApiResponse(['fetch', 'client', 'application'], {
    forceFetch: true,
  });
  const event = { ...baseEvent,
    client: client?.id ? {
      age: client.age,
      clientNumber: client.number,
      clientStatus: client.status,
      dateOfBirth: client.dateOfBirth,
      id: client.id,
      identifiedBy: client.identifiedBy,
      registeredBy: client.registeredBy,
      htn: standardizeMobilePhone(client.mobilePhone.mobilePhone),
      hma: standardizeEmail(client.email.email),
      address: {
        hfn: standardize(client.firstName),
        hln: standardize(client.lastName),
        city: standardize(client.declaredAddress.location1, false),
        region: '',
        'postal-code': client.declaredAddress.postalCode,
        'country-code': standardize(client.citizenship, false),
      },
      loansCount: loans.clientLoanCount,
      returningClient: !client.newClient,
    } : undefined,
    application: application?.id ? { ...pick(application, ['id', 'amount', 'term', 'type']),
    } : undefined,
    latest_loan: latestLoan?.loanNumber ? {
      id: latestLoan.loanNumber,
      transactionType: getTransactionType(loans.clientLoanCount, latestLoan.agreementType),
      ...pick(latestLoan, ['interestAmount', 'openAmount', 'overDueDays', 'agreementType']),
    } : undefined,
  };

  sendGTMEvent(event);
}

export default function createSwitch(dispatch: (...args: Array<any>) => any, getState: (...args: Array<any>) => any) {
  const getApiResponse: (...args: Array<any>) => any = getApiResponseCreator(getState, dispatch);

  const Switch = (props: Props) => {
    const {
      children,
      staticContext,
      location,
      updatePath,
    } = props;
    const [currentChild, setCurrentChild] = useState(null);
    const nextChild = useRef(null);
    const locationRef = useRef(null);
    const validateGuards = useCallback(async (_guards: ((...args: Array<any>) => any)[]) => {
      const guards = [..._guards];
      let redirect;

      while (!redirect) {
        const guard = guards.shift();

        if (!guard) {
          break;
        }

        redirect = await Promise.resolve(guard({
          dispatch,
          getApiResponse,
          getState,
        }));
      }

      if (redirect) {
        updatePath(redirect);
      } else {
        const child = nextChild.current;

        nextChild.current = null;
        setCurrentChild(cloneElement(child, {
          location: locationRef.current,
        }));
      }
    }, [location]);

    useEffect(() => {
      if (locationRef.current?.pathname !== location.pathname) {
        trackPageChangeGTMEvent(location.pathname, {
          getApiResponse,
          getState,
        });
        locationRef.current = location;
        let match;

        Children.forEach(children, element => {
          if (!match && isValidElement(element)) {
            const {
              path: pathProp,
              exact = true,
              strict,
              sensitive,
              from,
            } = element.props;
            const path = pathProp || from;

            nextChild.current = element;
            match = matchPath(location.pathname, {
              path,
              exact,
              strict,
              sensitive,
            });

            if (match) {
              if (staticContext) {
                staticContext.guards = element.props.guards || []; // eslint-disable-line no-param-reassign
              }

              validateGuards(element.props.guards || []);
            }
          }
        });
      }
    }, [location]);

    return currentChild;
  };

  return compose<any>(withRouter, connectActions({
    updatePath: ['router', 'updatePath'],
  }))(Switch);
}
