
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import styles from './BodyText.jss';

type Props = {
  classes: Record<string, any>;
  // Own props
  className: string;
  children: React.ReactChildren;
  html?: boolean;
  listCheckmarks?: boolean;
  white?: boolean;
  brown?: boolean;
  listCheckmarksColor?: 'blue' | 'yellow' | 'gray';
};

const BodyText = (props: Props): JSX.Element => {
  const {
    classes,
    className,
    children,
    html,
    white,
    brown,
    listCheckmarks,
    listCheckmarksColor = 'gray',
  } = props;
  const containerProps = html ? {
    dangerouslySetInnerHTML: {
      __html: children,
    },
  } : {};
  const conditionalClasses = {
    [(listCheckmarksColor as string)]: listCheckmarks,
    listCheckmarks,
    white,
    brown,
  };
  const containerClassName = classnames(classes.root, className, conditionalClasses);

  return (
    <div className={containerClassName} {...containerProps}>
      {html ? null : children}
    </div>
  );
};

BodyText.styleRoot = 'BodyText';
export default injectStyles(styles)(BodyText);
