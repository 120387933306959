import iban from '../lib/faker/iban';
import identityCardNumber from '../lib/faker/identityCardNumber';
import mobilePhone from '../lib/faker/mobilePhone';
import personalId from '../lib/faker/personalId';

export type FormName = 'registration' | 'profile' | 'employment';
export default function generateRandomData(formName: FormName) {
  const generatedEmail = faker.internet.email();

  switch (formName) {
    case 'registration':
      return {
        firstName: faker.name.firstName(),
        lastName: faker.name.lastName(),
        personalId: personalId(),
        mobilePhone: mobilePhone(),
        email: generatedEmail,
        confirmEmail: generatedEmail,
        password: 'Parole123!',
        acceptBigConsent: true,
        acceptFiPepDeclarationRead: true,
        acceptBikConsent: true,
        acceptBikInquiriesCheck: true,
        acceptSwoConsent: true,
        acceptNews: true,
        acceptDataSharing: true,
        acceptInternalDataSharing: false,
        identityCardNumber: identityCardNumber(),
        streetAndHouseFlatNumber: `${faker.address.streetName()} ${faker.helpers.replaceSymbolWithNumber(
          '2##',
        )}/${faker.helpers.replaceSymbolWithNumber('1#')}`,
        city: faker.address.city(),
        postalCode: faker.address.zipCode(),
      };

    case 'profile':
      return {
        identityCardNumber: identityCardNumber(),
        streetAndHouseFlatNumber: `${faker.address.streetName()} ${faker.helpers.replaceSymbolWithNumber(
          '2##',
        )}/${faker.helpers.replaceSymbolWithNumber('1#')}`,
        city: faker.address.city(),
        postalCode: faker.address.zipCode(),
      };

    case 'employment':
      return {
        employmentStatus: 'FULL_TIME',
        employerIndustry: 'ADMINISTRATION',
        employerName: '4finance',
        employmentStartYear: '2018',
        employmentStartMonth: '1',
        totalIncome: '10000',
        totalExpense: '5000',
        numberOfInhabitants: '1',
      };

    default:
      return {};
  }
}
