// Some API errors are multi-line and TextFields
// don't recalculate height properly, so they need to
// be re-focused and blurred.
// TODO: Fix inside `4finance-components(-pl)`

import { useCallback, useRef } from 'react';

export default function useTextFieldLayoutFix() {
  const ref = useRef<null | HTMLInputElement>(null);
  const onRef = useCallback((el: HTMLInputElement) => {
    ref.current = el;
  }, []);
  const onAfterChange = useCallback(() => {
    ref.current?.focus?.();
    ref.current?.blur?.();
  }, []);

  return {
    onRef,
    onAfterChange,
  };
}
