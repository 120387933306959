import { Map } from 'immutable';
import type { ImmutableMap } from '../../../../common/lib/types';

type CalculatorState = {
  amount: number | null;
  monthlyPayment: number | null;
  term: number | null;
};

function getDynamicPath(prefix: string[], data: ImmutableMap | CalculatorState) {
  let amount;
  let term;

  if (Map.isMap(data)) {
    const isApplicationOpen = data.get(['status']) === 'OPEN';
    const dt: ImmutableMap = (data as any);

    amount = isApplicationOpen ? dt.get('amount') : parseInt(localStorage.getItem('amount') || '', 10);
    term = isApplicationOpen ? dt.get('term') : parseInt(localStorage.getItem('term') || '', 10);
  } else {
    const dt: CalculatorState = (data as any) || {};

    amount = dt.amount;
    term = dt.term;
  }

  return [...prefix, amount?.toString?.() || '', term?.toString?.() || '', 'default', 'data'].filter(Boolean);
}

export function getCurrentOfferPath({
  context,
}: Record<string, any>, {
  api,
  calculator,
  authentication,
}: Record<string, any>): string[] {
  const isLoggedIn = authentication.get('isLoggedIn');
  const loggedInPrefix = ['api', 'fetch', 'client', 'application', 'offer'];

  if (isLoggedIn) {
    const application = api.getIn(['fetch', 'client', 'application', 'query', 'data']);

    switch (context) {
      case 'calculator':
        return getDynamicPath(loggedInPrefix, calculator.default);

      case 'application':
      {
        return getDynamicPath(loggedInPrefix, application);
      }

      case 'proposal':
      {
        const proposal = application.get('proposal');

        return getDynamicPath(loggedInPrefix, proposal);
      }

      default:
        throw new Error(`Unknown context \`${context}\`.`);
    }
  } else {
    return getDynamicPath(['api', 'fetch', 'application', 'firstLoanOffer'], calculator.default);
  }
}
export default function getCurrentSchedulePath(props: Record<string, any>, state: Record<string, any>) {
  return [...getCurrentOfferPath(props, state), 'preliminarySchedule'];
}
