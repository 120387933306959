import { memoize } from 'lodash';
import { useCallback, useState } from 'react';
import classnames from 'classnames';
import FooterGroup from './FooterGroup';
import injectStyles, { Container } from '4finance-components-pl';
import QA_CLASSES from '../../../lib/qa';
import styles from './Footer.jss';
import type { ContentFooter } from '../../../lib/content/types';

type Props = {
  classes: Record<string, any>;
  content: ContentFooter;
};

const Footer = (props: Props): JSX.Element => {
  const [currentOpenGroupIndex, setCurrentOpenGroupIndex] = useState(0);
  const {
    content,
    classes,
  } = props;
  const onClick = useCallback(memoize((index: number) => {
    return () => setCurrentOpenGroupIndex(index);
  }), []);

  return (
    <>
      <div className={classes.outerContainer}>
        <Container>
          <div className={classes.innerContainer}>
            {content.fields.groups.map((g, index) => <FooterGroup {...g.fields} key={index} onClick={onClick(index)} open={index === currentOpenGroupIndex} />)}
          </div>
        </Container>
      </div>
      <div className={classnames(classes.legal, QA_CLASSES.FOOTER_COPYRIGHT)}>
        {content.fields.legal}
      </div>
    </>
  );
};

Footer.styleRoot = 'Footer';
export default injectStyles(styles)(Footer);
