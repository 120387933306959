import * as R from '../../../../routes/routesList';

import { Background } from '../../../../shared/components/layout';
import { compose } from 'redux';
import { connectState } from '../../../../connect';
import { SecondaryHeading } from '../../../../shared/components/typography';
import { useTranslator } from '../../../../../localizations';
import Button from '../../../../shared/components/Button';
import injectStyles from '4finance-components-pl';
import styles from './AdditionalAmount.jss';

type Props = {
  classes: Record<string, any>;
  creditLimit: number;
};

const AdditionalAmount = (props: Props): JSX.Element => {
  const {
    classes,
    creditLimit,
  } = props;
  const {
    msg,
    numberToCurrency,
  } = useTranslator();

  return (
    <Background className={classes.root} src={'/assets/img/hand-with-coin.svg'} size={'auto 100%'} position={'center right'}>
      <SecondaryHeading>
        {msg('dashboard.loan.active.additional_amount.heading')}
      </SecondaryHeading>
      <SecondaryHeading className={classes.body}>
        {msg('dashboard.loan.active.additional_amount.body', {
          creditLimit: numberToCurrency(creditLimit),
        })}
      </SecondaryHeading>
      <Button secondary className={classes.button} to={R.APPLICATION_ADDITIONAL_AMOUNT}>
        {msg('dashboard.loan.active.additional_amount.button')}
      </Button>
    </Background>
  );
};

AdditionalAmount.styleRoot = 'AdditionalAmount';
export default compose<any>(connectState({
  creditLimit: ['api', 'fetch', 'client', 'query', 'data', 'creditLimit'],
}), injectStyles(styles))(AdditionalAmount);
