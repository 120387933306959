import { useCallback, useState } from 'react';
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import LegalTermsModal from './LegalTermsModal';
import QA_CLASSES from '@browser/lib/qa';
import styles from './LegalTerms.jss';
import useContentContext from '@browser/lib/content/useContentContext';

type Props = {
  classes: { root: string };
  // Own props
  amount: number;
  term: number | null | undefined;
  className?: string;
};

const LegalTerms = (props: Props): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    classes,
    amount,
    term,
    className = '',
  } = props;
  const {
    calculator: content,
  } = useContentContext();
  const {
    infoButtonContent = '',
  } = content?.fields || {};
  const onClose = useCallback(() => setIsModalOpen(false), []);
  const onOpen = useCallback(() => setIsModalOpen(true), []);

  return (
    <>
      <button type="button" className={classnames(classes.root, QA_CLASSES.LEGAL_TERMS_LINK, className)} onClick={onOpen}>
        {infoButtonContent}
      </button>
      <LegalTermsModal open={isModalOpen} onClose={onClose} amount={amount} term={term} />
    </>
  );
};

LegalTerms.styleRoot = 'LegalTerms';
export default injectStyles(styles)(LegalTerms);
