import * as R from '@routes/routesList';
import { Bank, ClientData } from '@browser/lib/banks/types';
import { BodyText } from '@shared/components/typography';
import { compose } from 'redux';
import { connectActions, connectPrefetch, connectState } from '@browser/connect';
import { Map } from 'immutable';
import { RouteAction } from '@common/types';
import { RouterAction } from 'connected-react-router';
import { Section } from '@shared/components/layout';
import { Url } from 'url';
import { useCallback, useEffect, useState } from 'react';
import { useTranslator } from '@localizations';
import BankOption from './BankOption';
import Button from '@shared/components/Button';
import ButtonGroup from '@shared/components/ButtonGroup';
import classnames from 'classnames';
import getBankName from '@browser/lib/banks/getBankName';
import injectStyles from '4finance-components-pl';
import Loading from '@shared/components/Loading';
import Message from '@dynamic/components/Message';
import QA_CLASSES from '@browser/lib/qa';
import styles from './BankIdentification.jss';

type BanksMap = Map<ClientData, string | Url>;

interface Props {
  classes: { listContainer: string; list: string; separator: string; loading: string; message: string };
  updatePath: RouteAction;
  banks: BanksMap;
  currentBank: string;
  goBack: RouterAction;
  selectBankForIdentification: (...args: Array<any>) => any;
  setBank: (...args: Array<any>) => any;
  clientStatus: string;
  clientBankAccount: string;
  amount: number;
  term: number;
}

const sortBanks = (bankList: Bank<ClientData>[]) =>
  bankList.sort((a: { value: string }, b: { value: string }) => a.value.localeCompare(b.value));

const mapClientBanks = (banks: BanksMap): Bank<ClientData>[] =>
  banks?.toJS().map((clientBank: ClientData) => {
    const clientBankName = getBankName(clientBank);

    return {
      data: clientBank,
      value: clientBankName,
      method: 'pennyTransfer',
    };
  });

const BankIdentification = (props: Props): JSX.Element => {
  const { banks, classes, updatePath, currentBank, goBack, selectBankForIdentification, setBank, clientStatus, clientBankAccount, amount, term } =
    props;
  const { msg } = useTranslator();
  const [bankList, setBankList] = useState(mapClientBanks(banks) as Bank<ClientData>[]);

  useEffect(() => {
    setBankList(mapClientBanks(banks));
  }, [banks]);

  useEffect(() => {
    setBank(null);

    if (!banks) {
      return;
    }
    const clientBanks = mapClientBanks(banks);
    const clientBanksSorted = sortBanks(clientBanks);

    setBankList(clientBanksSorted);
  }, [banks]);

  const isIdentified = clientStatus === 'IDENTIFIED';

  useEffect(() => {
    if (isIdentified && !!clientBankAccount) {
      updatePath(R.DASHBOARD);
    }
  }, []);

  const getMessage = useCallback(() => {
    if (term === 1) {
      return 'registration.penny_transfer_identified.message_singular';
    }

    if (term > 1 && term <= 4) {
      return 'registration.penny_transfer_identified.message_plural';
    }

    return 'registration.penny_transfer_identified.message_plural_over_4';
  }, [term]);

  return (
    <>
      {isIdentified && (
        <Message
          body={msg(getMessage(), { amount, term })}
          className={classnames(classes.message, QA_CLASSES.ACCOUNT_NUMBER_MESSAGE)}
        />
      )}
      <Section gray title={msg('registration.bank_identification.title')} separator>
        <BodyText>{msg('registration.bank_identification.subtitle')}</BodyText>
        {bankList ? (
          <div className={classes.listContainer}>
            <div className={classes.list}>
              {bankList.map((b) => (
                <BankOption {...b} key={b.value} />
              ))}
            </div>
            <div className={classes.separator} />
            <BankOption
              value={'other'}
              text={msg('registration.bank_identification.other')}
              className={QA_CLASSES.NOT_LISTED}
            />
          </div>
        ) : (
          <Loading className={classes.loading} />
        )}
        <ButtonGroup>
          <Button onClick={goBack} secondary className={QA_CLASSES.ACCOUNT_PREVIOUS_STEP}>
            {msg('actions.back')}
          </Button>
          <Button
            disabled={!currentBank}
            onClick={selectBankForIdentification}
            className={QA_CLASSES.ACCOUNT_NEXT_STEP}
          >
            {msg('actions.next')}
          </Button>
        </ButtonGroup>
      </Section>
    </>
  );
};

BankIdentification.styleRoot = 'BankIdentification';
export default compose<any>(
  connectPrefetch([['api', 'fetchClientBanks']]),
  connectActions({
    goBack: ['router', 'goBack'],
    selectBankForIdentification: ['registration', 'selectBankForIdentification'],
    setBank: ['registration', 'setBank'],
    updatePath: ['router', 'updatePath'],
  }),
  connectState({
    banks: ['api', 'fetch', 'client', 'banks', 'query', 'data', 'templates'],
    clientStatus: ['api', 'fetch', 'client', 'query', 'data', 'status'],
    clientBankAccount: ['api', 'fetch', 'client', 'query', 'data', 'bankAccount'],
    amount: ['api', 'fetch', 'client', 'application', 'query', 'data', 'amount'],
    term: ['api', 'fetch', 'client', 'application', 'query', 'data', 'term'],
    currentBank: ['registration', 'bank'],
  }),
  injectStyles(styles),
)(BankIdentification);
