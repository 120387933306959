import * as R from '../../../browser/routes/routesList';

export default function requireBankSelected({
  dispatch,
  getState,
}) {
  const bank = getState().registration.get('bank');

  if (bank) {
    return null;
  }

  dispatch({
    type: 'GUARD_REQUIRE_BANK_SELECTED',
  });

  return R.APPLICATION_BANK_IDENTIFICATION;
}
