import * as actions from './actions';
import { Record } from 'immutable';

interface IDashboardState {
  selectedPayment: string,
}
export class DashboardState
  extends Record({
    selectedPayment: 'currentInstallment',
  }) {
  static fromJS(value: IDashboardState): DashboardState {
    return new DashboardState(value);
  }
}

const initialState = new DashboardState();

export default function reducer(state: DashboardState = initialState, action: actions.DashboardActions): DashboardState {
  if (!(state instanceof DashboardState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case actions.SET_SELECTED_PAYMENT:
      return state.set('selectedPayment', action.payload) as DashboardState;
  }

  return state;
}
