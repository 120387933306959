import { StylesheetParams } from '@common/types';
import fonts from './fonts.jss';
import injectStyles from '4finance-components-pl';
import normalize from './normalize.jss';
import reset from './reset.jss';

const styles = (globals: StylesheetParams) => ({
  '@global': { ...reset,
    ...fonts,
    ...normalize(globals),
  },
});

const AppStyles = () => null;

AppStyles.styleRoot = 'AppStyles';
export default injectStyles(styles)(AppStyles);
