import * as C from '../../../common/lib/constants';
import * as R from '../../routes/routesList';

import { compose } from 'redux';
import { Route, withRouter } from 'react-router';
import { SecondaryHeading } from '../../shared/components/typography';
import { useLocation } from 'react-router-dom';
import { useTranslator } from '../../../localizations';
import ChangeAddress from './components/ChangeAddress';
import ChangeByBankTransfer from './components/ChangeByBankTransfer';
import ChangeByBankTransferConfirm from './components/ChangeByBankTransferConfirm';
import ChangeConsents from './components/ChangeConsents';
import ChangeEmailOrPhone from './components/ChangeEmailOrPhone';
import ChangeEmailOrPhoneConfirm from './components/ChangeEmailOrPhone/ChangeEmailOrPhoneConfirm';
import ChangeIdentityCardNumber from './components/ChangeIdentityCardNumber';
import ChangePassword from './components/ChangePassword';
import ChangeSuccessModal from './components/ChangeSuccessModal';
import classnames from 'classnames';
import ClientSettings from './components/ClientSettings';
import DashboardSection from '../components/DashboardSection';
import injectStyles from '4finance-components-pl';
import Money from '../../svg/uEA0F-money_zl.svg';
import Profile from '../registration/components/Profile';
import styles from './Settings.jss';

type Props = {
  classes: Record<string, any>;
};

const Settings = (props: Props): JSX.Element => {
  const {
    classes,
  } = props;
  const {
    msg,
  } = useTranslator();
  const {
    pathname, search,
  } = useLocation();
  const sectionTitle = '';
  const headingTitle = msg('dashboard.settings.promo.title');
  const headingSubtitle = msg('dashboard.settings.promo.subtitle');

  const ModalRoutes = () => (
    <>
      <Route path={R.CHANGE_NAME} exact component={() => <ChangeByBankTransfer formType={C.NAME} confirmPath={R.CONFIRM_NAME} />} />
      <Route path={R.CONFIRM_NAME} exact component={() => <ChangeByBankTransferConfirm formType={C.NAME} />} />
      <Route path={R.CHANGE_PHONE} exact component={() => <ChangeEmailOrPhone formType={C.PHONE} />} />
      <Route path={R.CHANGE_PHONE_CONFIRM} exact component={() => <ChangeEmailOrPhoneConfirm type={C.PHONE} />} />
      <Route path={R.CHANGE_PHONE_SUCCESS} exact component={() => <ChangeSuccessModal type={C.PHONE} />} />
      <Route path={R.CHANGE_EMAIL} exact component={() => <ChangeEmailOrPhone formType={C.EMAIL} />} />
      <Route path={R.CHANGE_EMAIL_CONFIRM} exact component={() => <ChangeEmailOrPhoneConfirm type={C.EMAIL} />} />
      <Route path={R.CHANGE_EMAIL_SUCCESS} exact component={() => <ChangeSuccessModal type={C.EMAIL} />} />
      <Route path={R.CHANGE_PASSWORD} exact component={() => <ChangePassword />} />
      <Route path={R.CHANGE_PASSWORD_SUCCESS} exact component={() => <ChangeSuccessModal type={C.PASSWORD} />} />
      <Route path={R.CHANGE_IDENTITY_CARD_NUMBER} exact component={() => <ChangeIdentityCardNumber />} />
      <Route path={R.CHANGE_ID_SUCCESS} exact component={() => <ChangeSuccessModal type={C.ID} />} />
      <Route path={R.CHANGE_BANK_ACCOUNT_NUMBER} exact component={() => <ChangeByBankTransfer formType={C.BANK_ACCOUNT} confirmPath={R.CONFIRM_BANK_ACCOUNT} />} />
      <Route path={R.CONFIRM_BANK_ACCOUNT} exact component={() => <ChangeByBankTransferConfirm formType={C.BANK_ACCOUNT} />} />
      <Route path={R.CHANGE_CONSENTS} exact component={ChangeConsents} />
      <Route path={R.CHANGE_ADDRESS} exact component={ChangeAddress} />
      <Route path={R.CHANGE_ADDRESS_SUCCESS} exact component={() => <ChangeSuccessModal type={C.ADDRESS} />} />
    </>
  );

  return (
    search.includes('from_profile') ? (
      <>
        <Profile />
        <ModalRoutes />
      </>
    ) : (
      <DashboardSection title={sectionTitle}>
        <div className={classnames(classes.column, 'white')}>
          {!(pathname.includes('bank') || pathname.includes('name')) && <ClientSettings />}
          <ModalRoutes />
        </div>
        <div className={classnames(classes.column, 'yellow')}>
          <SecondaryHeading bigger lighter>
            {headingTitle}
          </SecondaryHeading>
          <Money className={classes.icon} />
          <SecondaryHeading lighter>
            {headingSubtitle}
          </SecondaryHeading>
        </div>
      </DashboardSection>
    )

  );
};

Settings.styleRoot = 'Settings';
export default compose<any>(withRouter, injectStyles(styles))(Settings);
