export default () => ({
  root: {
    padding: '8px 0',
    display: 'flex',
    alignItems: 'center',
  },
  // RadioInput overrides
  optionText: {
    paddingLeft: '24px',
    fontSize: '14px',
  },
  base: {
    height: '20px',
    width: '20px',
  },
  base__selected: {
    borderWidth: '6px',
  },
});
