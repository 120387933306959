import * as DashboardSection from '@dynamic/components/DashboardSection';

import { BodyText, SecondaryHeading } from '@shared/components/typography';
import { memo } from 'react';
import { pick } from 'lodash';
import { useTranslator } from '@localizations';
import injectStyles from '4finance-components-pl';
import Separator from '@shared/components/Separator';
import styles from './DashboardBlockedForeigner.jss';

type Props = {
  classes: Record<string, any>;
  mainTitle: string;
  bodyText: string;
};
const DashboardBlockedForeigner = (props: Props): JSX.Element => {
  const {
    classes,
    mainTitle,
    bodyText,
  } = props;
  const { msg } = useTranslator();

  const calculatorClasses = {
    container: classes.calculatorContainer,
    ...pick(classes, ['termContainer', 'offerContainer', 'sliderContainer']),
  };

  return (
    <>
      <DashboardSection.Block className={classes.leftPanel}>
        <div className={classes.backgroundWrapper}>
          <SecondaryHeading bigger brown lighter>
            {mainTitle}
          </SecondaryHeading>
          <Separator className={classes.separator} />
          <BodyText html brown>
            {bodyText}
          </BodyText>
        </div>
      </DashboardSection.Block>
      <DashboardSection.Block yellow>
        <div className={classes.blocked}>
          <SecondaryHeading bigger brown lighter className={classes.headingForeigner}>
            {msg('dashboard.foreigner_blocked.heading')}
          </SecondaryHeading>

          <BodyText>
            {msg('dashboard.foreigner_blocked.text')}
          </BodyText>
        </div>
      </DashboardSection.Block>

    </>
  );
};

DashboardBlockedForeigner.styleRoot = 'DashboardBlockedForeigner';
export default memo(
  injectStyles(styles)(DashboardBlockedForeigner),
);
