export default (({
  colors,
}) => ({
  root: {
    backgroundColor: colors.primary,
    '& > div': {
      color: colors.white,
      display: 'flex',
      alignItems: 'center',
      minHeight: '48px',
      justifyContent: 'space-between',
      '& button': {
        color: colors.white,
      },
    },
  },
}));
