
import classnames from 'classnames';
import CloseButton from '../CloseButton';
import injectStyles, { Tooltip } from '4finance-components-pl';
import styles from './Disclaimer.jss';
import useDevice from '../../../lib/hooks/useDevice';

type Props = {
  classes: Record<string, any>;
  content: string;
  isOpen: boolean;
  title: string;
  className: string;
  toggle: (...args: Array<any>) => any;
};

const Disclaimer = (props: Props) => {
  const {
    classes,
    content,
    className,
    isOpen,
    title,
    toggle,
  } = props;
  const device = useDevice();
  const atMostTablet = device && device.atMost('tablet');

  return atMostTablet ? (
    <>
      <button type="button" onClick={toggle} className={classnames(classes.toggleButton, className)}>
        <div dangerouslySetInnerHTML={{
          __html: title,
        }}
        />
      </button>
      <div className={classnames(classes.tooltipDescription, isOpen && classes.open)}>
        <div className={classes.tooltipTop}>
          <div dangerouslySetInnerHTML={{
            __html: title,
          }}
          />
          <CloseButton onClick={toggle} />
        </div>
        <div
          className={classes.tooltipBottom}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    </>
  ) : (
    <Tooltip
      classes={classes}
      tooltip={content}
      tooltipElement={(
        <div
          className={className}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
)}
    />
  );
};

Disclaimer.styleRoot = 'Disclaimer';
export default injectStyles(styles)(Disclaimer);
