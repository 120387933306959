
import { BodyText } from '../../../../shared/components/typography';
import { useTranslator } from '../../../../../localizations';
import injectStyles from '4finance-components-pl';
import styles from './Help.jss';

type Props = {
  classes: Record<string, any>;
};

const Help = (props: Props): JSX.Element => {
  const {
    classes,
  } = props;
  const {
    msg,
  } = useTranslator();

  return (
    <BodyText className={classes.root}>
      <div className={classes.textLine}>
        <b>{msg('dashboard.help.title')}</b>
      </div>
      <div className={classes.textLine}>
        {msg('dashboard.help.phone')}
        <br />
        {msg('dashboard.help.phone_number')}
      </div>
      <div className={classes.textLine}>
        {msg('dashboard.help.available')}
        <br />
        {msg('dashboard.help.opening_hours')}
      </div>
    </BodyText>
  );
};

Help.styleRoot = 'Help';
export default injectStyles(styles)(Help);
