export default (({
  mediaQueries,
}) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div:first-child': {
      flex: '0 0 62%',
    },
    [mediaQueries.breakpointTablet]: {
      display: 'block',
    },
  },
  calculatorContainer: {
    position: 'relative',
    top: '-64px',
    [mediaQueries.breakpointTablet]: {
      display: 'none',
    },
  },
}));
