import * as R from '../routesList';

import { Login, PasswordRecovery } from '../../dynamic/authentication';
import { Route } from 'react-router-dom';
import MainLayoutDynamic from '../../shared/layouts/MainLayoutDynamic';

const Router = () => (
  <MainLayoutDynamic navShadow>
    <Route path={R.LOGIN} exact component={Login} />
    <Route path={R.PASSWORD_RECOVERY} exact component={PasswordRecovery} />
  </MainLayoutDynamic>
);

export default Router;
