import * as R from '../../browser/routes/routesList';

export default async function forbidBlacklistedUser({
  dispatch,
  getApiResponse,
}) {
  const {
    onBlacklist,
  } = await getApiResponse(['fetch', 'client']);

  if (!onBlacklist) {
    return null;
  }

  dispatch({
    type: 'GUARD_FORBID_BLACKLISTED_USER_FAILED',
  });

  return R.DASHBOARD_NOT_AVAILABLE;
}
