export const ERROR = 'too_small';
export default function greaterThan(number: number, allowZero = false) {
  return (value: string) => {
    if (allowZero && Number(value) === 0) {
      return null;
    }

    if (Number(value) <= number) {
      return ERROR;
    }

    return null;
  };
}
