import { PHONE_CODES } from '../lib/faker/mobilePhone';

const ERROR = 'invalid_phone_format';
const REGEX = new RegExp(`^(${PHONE_CODES.join('|')})\\d{1}\\s?\\d{3}\\s?\\d{3}$`);

export default function mobilePhone() {
  return (value: string | null | undefined): string | null | undefined => {
    if (value) {
      if (!REGEX.test(value)) {
        return ERROR;
      }

      return null;
    }

    return null;
  };
}
