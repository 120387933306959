export default ({ colors, mediaQueries }) => ({
  leftPanel: {
    padding: 0,
    backgroundColor: colors.secondary,
    [mediaQueries.breakpointTablet]: {
      height: '400px',
    },
  },
  backgroundWrapper: {
    padding: '32px',
    backgroundPosition: 'bottom center',
    backgroundSize: '150%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: "url('/assets/img/background_dashboard_03_2022.png')",
    height: '100%',
    backgroundColor: '#f2f2f2',
    [mediaQueries.breakpointLaptopSmall]: {
      backgroundSize: 'auto 250px',
      height: '160px',
      backgroundImage: 'none',
    },
  },
  separator: {
    width: '64px',
    marginLeft: '-16px',
  },
  detailsHeading: {
    marginBottom: '24px',
  },
  // StaticCalculator overrides
  calculatorContainer: {
    width: '100% !important',
  },
  // Proposal classes
  proposalOfferContainer: {
    padding: '24px',
    backgroundColor: colors.lightGray,
    marginTop: '24px',
    marginBottom: '16px',
  },
  proposalReject: {
    marginTop: '16px',
  },
  blocked: {
    minHeight: '600px',
  },
});
