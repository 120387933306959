
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import styles from './Warning.jss';

type Props = {
  classes: Record<string, any>;
  children: React.ReactChildren;
  // Own props
  className?: string;
};

const Warning = (props: Props): JSX.Element => {
  const {
    classes,
    children,
    className,
  } = props;

  return (
    <div className={classnames(classes.root, className)}>
      {children}
    </div>
  );
};

Warning.styleRoot = 'Warning';
export default injectStyles(styles)(Warning);
