import { createContext, useContext } from 'react';

type Context = string;
const defaultContext = '';
const SSRPathnameContext = createContext<Context>(defaultContext);

export const SSRPathnameProvider = SSRPathnameContext.Provider;

const useSSRPathname = () => useContext(SSRPathnameContext);

export default useSSRPathname;
