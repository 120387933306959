
import { compose } from 'redux';
import { connectActions, connectState } from '../../../../connect';
import { connectSubmit, Form } from '4finance-onion-form-pl';
import { NewPassword, Password } from '../../fields';
import { useEffect, useRef, useState } from 'react';
import { useTranslator } from '../../../../../localizations';
import Button from '../../../../shared/components/Button';
import getCaptchaKey from '@browser/lib/getCaptchaKey';
import injectStyles from '4finance-components-pl';
import Modal from '../../../../shared/components/Modal';
import QA_CLASSES from '../../../../lib/qa';
import Reaptcha from 'reaptcha';
import styles from '../Change.jss';

type Props = {
  classes: Record<string, any>;
  sendChangePassword: (...args: Array<any>) => any;
  setSettingsRecaptchaResponseToken: (...args: Array<any>) => any;
  setFieldApiError: (...args: Array<any>) => any;
  email: string;
  goBack: (...args: Array<any>) => any;
  clearForm: (...args: Array<any>) => any;
  newPassword: string;
  oldPassword: string;
};
const Submit = connectSubmit(Button);

const ChangePassword = (props: Props): JSX.Element => {
  const {
    email,
    newPassword,
    oldPassword,
    setFieldApiError,
    classes,
    sendChangePassword,
    setSettingsRecaptchaResponseToken,
    goBack,
    clearForm,
  } = props;
  const {
    msg,
  } = useTranslator();
  const isSame = oldPassword && newPassword && newPassword === oldPassword;
  const inputStyle = {
    display: 'none',
  };

  const [isCaptchaRendered, setIsCaptchaRendered] = useState(false);
  const captcha = useRef();

  const title = msg('dashboard.settings.change_password.title');
  const description = msg('dashboard.settings.change_password.description');
  const button = msg('dashboard.settings.change_password.button');

  useEffect(() => {
    if (isSame) {
      setFieldApiError('clientSettingsChangePassword', 'newPassword', 'password_is_same_as_old');
    }
  }, [isSame]);
  useEffect(() => {
    return () => clearForm('clientSettingsChangePassword');
  }, []);

  const onSubmit = (): void => {
    executeCaptcha();
  };

  const executeCaptcha = () => captcha.current?.execute();

  const onCaptchaRender = () => setIsCaptchaRendered(true);

  const onCaptchaVerify = (responseToken: string) => {
    setSettingsRecaptchaResponseToken(responseToken);
    sendChangePassword();
    captcha.current?.reset();
  };

  return (
    <Modal title={title} open onClose={goBack}>
      <div className={classes.formWrapper}>
        <div className={classes.description}>{description}</div>
        <Form name={'clientSettingsChangePassword'} onSubmit={onSubmit}>
          <input type={'text'} name={'username'} value={email || ''} hidden style={inputStyle} autoComplete={'username email'} />
          <Password className={QA_CLASSES.OLD_PASSWORD} clearApiErrorOnChange />
          <NewPassword className={QA_CLASSES.NEW_PASSWORD} clearApiErrorOnChange />
          <Submit className={classes.button} disabled={isSame || !isCaptchaRendered}>
            {button}
          </Submit>
        </Form>
        <div className={classes.recaptchaWrapper}>
          <Reaptcha
            ref={captcha}
            sitekey={getCaptchaKey()}
            onVerify={onCaptchaVerify}
            onRender={onCaptchaRender}
            size="invisible"
            badge="inline"
          />
        </div>
      </div>
    </Modal>
  );
};

ChangePassword.styleRoot = 'ChangePassword';
export default compose<any>(
  connectState({
    email: ['api', 'fetch', 'client', 'query', 'data', 'email', 'email'],
    oldPassword: ['onionForm', 'fields', 'clientSettingsChangePassword', 'oldPassword', 'value'],
    newPassword: ['onionForm', 'fields', 'clientSettingsChangePassword', 'newPassword', 'value'],
  }),
  connectActions({
    clearForm: ['onionForm', 'clearForm'],
    sendChangePassword: ['settings', 'sendChangePassword'],
    setSettingsRecaptchaResponseToken: ['settings', 'setSettingsRecaptchaResponseToken'],
    setFieldApiError: ['onionForm', 'setFieldApiError'],
    goBack: ['router', 'goBack'],
  }),
  injectStyles(styles),
)(ChangePassword);
