import { AUTHENTICATION_REQUEST_LOGOUT } from '../../authentication/actions';
import { combineReducers } from 'redux';
import config from '4finance-configuration-pl';
import logSlowReducers from './logSlowReducers';
import recycle from 'redux-recycle';
import reviveStateFromPersistence from '../persistence/reviveStateFromPersistence';

const PERSISTENT_REDUCERS = ['device', 'router', 'translate', 'apiUrls'];

export default function createStoreReducers(appReducers, persistenceStore) {
  const combinedReducers = config.debug.logSlowReducers ? combineReducers(logSlowReducers(appReducers, 4)) : combineReducers(appReducers);

  return recycle(combinedReducers, [`${AUTHENTICATION_REQUEST_LOGOUT}_SUCCESS`], state => {
    const newState = PERSISTENT_REDUCERS.reduce((acc, key) => ({ ...acc,
      [key]: state[key],
    }), {});

    return reviveStateFromPersistence(newState, persistenceStore);
  });
}
