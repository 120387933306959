export default () => ({
  root: {
    display: 'flex',
    marginTop: '24px',
  },
  icon: {
    flex: '0 0 72px',
    marginRight: '16px',
  },
  text: {
    '& b': {
      display: 'block',
      marginBottom: '4px',
    },
    '& *': {
      fontSize: '14px',
    },
  },
  hyperlink: {
    marginTop: '8px',
  },
});
