export const tidyNumbersOnly = (value: string): string => value.replace(/[^0-9]/gi, '');
export const tidyCurrency = (value: string): string => value.replace(/[^0-9,.]/gi, '');
export const tidyStreetAndNumber = (value: string): string => value.replace(/[^a-z0-9ąęćśżźłóń/\s-]/gi, '');
export const tidyLettersAndSpaceOnly = (value: string): string => value.replace(/[^a-ząęćśżźłóń\s]/gi, '');
export const tidyLettersSpaceAndHyphenOnly = (value: string): string => value.replace(/[^a-ząęćśżźłóń\s-]/gi, '');
export const tidyPersonalId = (value: string): string => value.replace(/[^0-9]/gi, '').slice(0, 11);
export const tidyIdentityCardNumber = (value: string): string => value.replace(/[^a-z0-9\s]/gi, '').slice(0, 10);
export const tidyMobilePhone = (value: string): string => value.replace(/[^0-9]/g, '');
export const tidyPostalCode = (value: string): string => value.replace(/[^0-9-]/g, '').slice(0, 6);

export default {
  tidyNumbersOnly,
  tidyCurrency,
  tidyStreetAndNumber,
  tidyLettersAndSpaceOnly,
  tidyLettersSpaceAndHyphenOnly,
  tidyPersonalId,
  tidyIdentityCardNumber,
  tidyMobilePhone,
  tidyPostalCode,
};
