import * as R from '../../browser/routes/routesList';
import { actions as api } from '../api';
import { Base64 } from 'js-base64';
import { DispatchType } from '@common/types';
import { pick } from 'lodash';
import { replace, RouterAction } from 'connected-react-router';
import { RootState } from '@browser/configureStoreWithHistory';
import getAnalyticsCookies from '@browser/lib/getAnalyticsCookies';
import sendGTMEvent from '../lib/sendGTMEvent';

export const AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT';
export const AUTHENTICATION_REQUEST_LOGOUT = 'AUTHENTICATION_REQUEST_LOGOUT';
export const AUTHENTICATION_SET_LOGIN_STATUS = 'AUTHENTICATION_SET_LOGIN_STATUS';
export const AUTHENTICATION_SET_LOGIN_ERROR = 'AUTHENTICATION_SET_LOGIN_ERROR';
export const AUTHENTICATION_CLEAR_LOGIN_ERROR = 'AUTHENTICATION_CLEAR_LOGIN_ERROR';
export const PROCESS_LOGIN = 'PROCESS_LOGIN';
export const SUBMIT_LOGIN = 'SUBMIT_LOGIN';
export const SEND_ANALYTICS_COOKIES = 'SEND_ANALYTICS_COOKIES';
const ANALYTICS_COOKIES: Record<string, string> = {
  ga: '_ga',
  wt: 'WT_FPC',
};

interface RequestLogin {
  type: typeof PROCESS_LOGIN
  payload: Promise<Response>
}

interface SubmitLogin {
  type: typeof SUBMIT_LOGIN
  payload: Promise<void>
}

interface RequestLogout {
  type: typeof AUTHENTICATION_REQUEST_LOGOUT
  payload: Promise<Response>
}

interface Logout {
  type: typeof AUTHENTICATION_LOGOUT
  payload: Promise<any>
}

interface SetLoginStatus {
  type: typeof AUTHENTICATION_SET_LOGIN_STATUS
  payload: boolean
}

interface SetLoginError {
  type: typeof AUTHENTICATION_SET_LOGIN_ERROR
  payload: string
}

interface ClearLoginError {
  type: typeof AUTHENTICATION_CLEAR_LOGIN_ERROR,
}

interface SendAnalyticsCookies {
  type: typeof SEND_ANALYTICS_COOKIES
  payload: Promise<any>
}

export type AuthAction = RequestLogin | SubmitLogin | RequestLogout | SetLoginStatus | SetLoginError | ClearLoginError

export function setLoginStatus(value: boolean): SetLoginStatus {
  return {
    type: AUTHENTICATION_SET_LOGIN_STATUS,
    payload: value,
  };
}
export function setLoginError(value: string): SetLoginError {
  return {
    type: AUTHENTICATION_SET_LOGIN_ERROR,
    payload: value,
  };
}

export function clearLoginError(): SetLoginError {
  return {
    type: AUTHENTICATION_CLEAR_LOGIN_ERROR,
  };
}
export function sendLoginRequest({
  username,
  password,
  responseToken,
}:{
  username: string;
  password: string;
  responseToken: string;
}): Promise<Response> {
  const basicToken = Base64.encode(`${username}:${password}`);
  // Make request to local node server, therefore
  // we could make request to webapi from server side

  return fetch(`/token/basic?responseToken=${responseToken}`, {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: `Basic ${basicToken}`,
      'Content-Type': 'application/json',
    },
  });
}
export function trackLoginGTMEvent() {
  return async ({
    getApiResponse,
  }: {getApiResponse: (arg: string[]) => Record<string, any>}): Promise<void> => {
    const client = await getApiResponse(['fetch', 'client']);
    const loans = await getApiResponse(['fetch', 'client', 'loans']);
    const latestLoan = await getApiResponse(['fetch', 'client', 'loans', 'latest']);

    sendGTMEvent({
      ...pick(client, ['id', 'dateOfBirth', 'number', 'status', 'age', 'identifiedBy', 'registeredBy']),
      dateOfBirth: new Date(client.dateOfBirth).getFullYear(),
      ...pick(loans, ['clientLoanCount', 'clientVoidedLoanCount']),
      ...(latestLoan ? pick(latestLoan, ['principalAmount', 'interestAmount', 'penaltyAmount', 'commissionAmount', 'openAmount', 'overDueDays', 'agreementType']) : {}),
    });
  };
}
export function requestLogin({
  username,
  password,
  isRegistration,
  responseToken,
}: {
  username: string;
  password: string;
  responseToken: string;
  isRegistration?: boolean;
}):
  (a: {dispatch: DispatchType<RequestLogin | SetLoginStatus>}) => RequestLogin {
  return ({
    dispatch,
  }) => {
    const getPromise = async () => {
      // Make request to local node server, therefore
      // we could make request to webapi from server side
      const response = await sendLoginRequest({
        username,
        password,
        responseToken,
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(setLoginStatus(!!data.token));

        if (!isRegistration) {
          dispatch(sendAnalyticsCookies());
        }

        dispatch(trackLoginGTMEvent());

        return data;
      }

      throw new Error(`${response.status.toString()},${data.errors[0].message}`);
    };

    return {
      type: PROCESS_LOGIN,
      payload: getPromise(),
    };
  };
}
export function submitLogin({
  username,
  password,
  redirect,
  responseToken,
}: {
  username: string;
  password: string;
  responseToken: string;
  redirect?: string;
}): (a: {dispatch: DispatchType<RequestLogin | SetLoginError | RouterAction>}) => SubmitLogin {
  return ({ dispatch }) => {
    const getPromise = async () => {
      const response = await dispatch(requestLogin({
        username,
        password,
        responseToken,
      }));

      const { error, payload } = response;

      if (error) {
        const [code, message] = payload?.message.split(',') || [];

        if (code === '403' && message === 'Security') {
          dispatch(setLoginError('403_security'));
        } else {
          dispatch(setLoginError(code || 'unknown'));
        }
      } else {
        dispatch(replace(redirect || R.DASHBOARD));
      }
    };

    return {
      type: SUBMIT_LOGIN,
      payload: getPromise(),
    };
  };
}
export function requestLogout(): () => RequestLogout {
  return () => {
    const getPromise = async () => {
      const response = await fetch('/logout', {
        method: 'post',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        return response;
      }

      throw new Error(response.status.toString());
    };

    return {
      // by this action name in createReducers we will reset state
      type: AUTHENTICATION_REQUEST_LOGOUT,
      payload: getPromise(),
    };
  };
}
export function logout(): (a: {dispatch: DispatchType<RequestLogout | SetLoginStatus | RouterAction>}) => Logout {
  return ({ dispatch }) => {
    async function getPromise() {
      const {
        error,
      } = await dispatch(requestLogout());

      if (error) {
        throw Error('Logout failed');
      }

      dispatch(setLoginStatus(false));
      dispatch(replace(R.ROOT));
    }

    return {
      type: AUTHENTICATION_LOGOUT,
      payload: getPromise(),
    };
  };
}
export function sendAnalyticsCookies(): (a: {
  dispatch: DispatchType<RequestLogin | SetLoginStatus>,
  persistenceStore: RootState
}) => SendAnalyticsCookies {
  return ({
    dispatch,
    persistenceStore,
  }) => {
    const getPromise = async (name: string) => {
      const value = getAnalyticsCookies(persistenceStore);

      if (value) {
        await dispatch(api.sendClientCookies({
          name,
          value,
        }));
      }
    };

    return {
      type: SEND_ANALYTICS_COOKIES,
      payload: Promise.all(Object.keys(ANALYTICS_COOKIES).map(name => getPromise(name))),
    };
  };
}
