
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import QA_CLASSES from '../../../../lib/qa';
import styles from './Step.jss';

type Props = {
  classes: Record<string, any>;
  title: string;
  number: number;
  active: boolean;
};

const Step = (props: Props): JSX.Element => {
  const {
    classes,
    title,
    number,
    active,
  } = props;

  return (
    <div className={classnames(classes.root, QA_CLASSES.STEP, {
      active,
    })}
    >
      <div className={classes.arrows}>
        <div />
        <div />
      </div>
      <span className={classes.text}>
        {
        /* eslint-disable-next-line react/jsx-one-expression-per-line */
      }
        <span>
          {number}
          . &nbsp;
        </span>
        {title}
      </span>
    </div>
  );
};

Step.styleRoot = 'Step';
export default injectStyles(styles)(Step);
