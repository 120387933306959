// Something like Angular services, but code is explicit.
// Function returned from action gets dependencies, getState, and dispatch.
// Actions is where we should place statefull workflows.
export default function injectDependencies({
  dynamic = {},
  statics,
  withDispatch = {},
  withStateAndDispatch = {},
  headers = {},
  cookies = {},
}) {
  return ({
    dispatch,
    getState,
  }) => next => action => {
    if (typeof action !== 'function') return next(action);
    const dependencies = { ...statics,
    };
    const isBrowser = process.env.IS_BROWSER;
    const host = headers.originHost || null;
    // On page refresh node renders page with all webapi requests, therefore we need to pass client cookie to those requests
    const cookie = !isBrowser ? cookies : {};
    const cookiesStr = Object.keys(cookie).map(name => `${name}=${cookie[name]}`).join('; ');
    const customHeaders = {
      host,
      ...(!isBrowser && {
        cookie: cookiesStr,
      }),
    };

    Object.keys(dynamic).forEach(key => {
      dependencies[key] = dynamic[key](getState(), customHeaders);
    });
    Object.keys(withDispatch).forEach(key => {
      dependencies[key] = withDispatch[key](dispatch);
    });
    Object.keys(withStateAndDispatch).forEach(key => {
      dependencies[key] = withStateAndDispatch[key](getState, dispatch);
    });

    return dispatch(action({ ...dependencies,
      getState,
      dispatch,
    }));
  };
}
