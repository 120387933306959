import { PureComponent } from 'react';
import ReactDOM from 'react-dom';

type Props = {
  children?: any;
  disabled?: boolean;
  top?: number;
};
type State = {
  edge: boolean;
  offset: number;
  width: string;
  height: string;
};
export default class Sticky extends PureComponent<Props, State> {
  static defaultProps = {
    disabled: false,
    top: 0,
  };
  // eslint-disable-next-line react/sort-comp
  settled = true;

  constructor(props: Props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.state = {
      edge: false,
      offset: 0,
      width: 'auto',
      height: 'auto',
    };
  }

  componentDidMount() {
    if (this.props.disabled) {
      return;
    }

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    if (this.props.disabled) {
      return;
    }

    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  handleScroll() {
    if (!this.settled) {
      return;
    }

    const {
      top,
    } = this.props;
    const screenHeight = window.innerHeight;
    const outerBoundingRect = this.getBoundingRectByRef('outer');

    if (outerBoundingRect.height >= screenHeight) {
      if (screenHeight >= outerBoundingRect.bottom && outerBoundingRect.bottom >= 0) {
        this.stickTo('bottom', 0);
      } else {
        this.unstick();
      }

      return;
    }

    if (outerBoundingRect.top <= top) {
      this.stickTo('top', top);
    } else {
      this.unstick();
    }
  }

  handleResize() {
    this.unstick();
  }

  getBoundingRectByRef(refName: string) {
    // eslint-disable-next-line react/no-string-refs
    const ref = this.refs[refName];

    // eslint-disable-next-line react/no-find-dom-node
    return ReactDOM.findDOMNode(ref).getBoundingClientRect();
  }

  stickTo(edge: string, offset: number) {
    const innerBoundingRect = this.getBoundingRectByRef('inner');

    this.settled = false;
    this.setState({
      edge,
      offset,
      height: innerBoundingRect.height,
      width: innerBoundingRect.width,
    }, () => {
      this.settled = true;
    });
  }

  unstick() {
    this.settled = false;
    this.setState({
      edge: false,
      offset: 0,
      width: 'auto',
      height: 'auto',
    }, () => {
      this.settled = true;
    });
  }

  render() {
    const {
      children,
    } = this.props;
    const {
      edge,
      offset,
      width,
      height,
    } = this.state;
    const style = edge ? {
      position: 'fixed',
      top: 'auto',
      bottom: 'auto',
      [edge.toString()]: offset,
    } : {
      position: 'relative',
    };

    return (
      <div
        className="sticky-outer"
        style={{
          width,
          height,
        }}
      // eslint-disable-next-line react/no-string-refs
        ref="outer"
      >
        <div
          className="sticky-inner"
          style={{ ...style,
            width,
          }}
          // eslint-disable-next-line react/no-string-refs
          ref="inner"
        >
          {children}
        </div>
      </div>
    );
  }
}
