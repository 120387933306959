
import { compose } from 'redux';
import { connectState } from '../../../connect';
import { getCurrentOfferPath } from './getCurrentSchedulePath';
import { useCallback, useState } from 'react';
import { useTranslator } from '../../../../localizations';
import injectStyles from '4finance-components-pl';
import ScheduleModal from './ScheduleModal';
import styles from './AcceptInformationFormConsent.jss';

type Props = {
  classes: Record<string, any>;
  amount: number;
  term: number;
  // Own props
  text: string;
  context?: 'calculator' | 'application' | 'proposal';
};

const AcceptInformationFormConsent = (props: Props): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    classes,
    text,
    context,
    amount,
    term,
  } = props;
  const {
    msg,
  } = useTranslator();
  const parts = text.replace('%AMOUNT%', `${amount}`).replace('%TERM%', `${term}`).split('%SCHEDULE%');
  const onClose = useCallback(() => setIsModalOpen(false), []);
  const onClick = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsModalOpen(true);
  }, []);

  return (
    <>
      <div>
        <span dangerouslySetInnerHTML={{
          __html: parts[0],
        }}
        />
        <button type="button" className={classes.schedule} onClick={onClick}>
          {msg('schedule.consent')}
        </button>
        <span dangerouslySetInnerHTML={{
          __html: parts[1],
        }}
        />
      </div>
      <ScheduleModal open={isModalOpen} onClose={onClose} context={context} />
    </>
  );
};

AcceptInformationFormConsent.styleRoot = 'AcceptInformationFormConsent';
export default compose<any>(connectState((props, state) => {
  const offerPath = getCurrentOfferPath(props, state);

  return {
    amount: [...offerPath, 'totalPrincipal'],
    term: [...offerPath, 'term'],
  };
}), injectStyles(styles))(AcceptInformationFormConsent);
