import * as actions from './actions';
import { Record } from 'immutable';

interface IInitialState {
  // The bank chosen in `/application/bank-identification`
  bank: string | null;
  bankAccount: string | null;
  // Hide `mobilePhone` and `email` fields on `/profile` if the client is a returning client
  isJustRegistered: boolean;
  // Store recaptcha token to be sent to webapi
  recaptchaResponseToken: string;
  registrationData: Record<string, any>
}

export class InitialState extends Record({
  bank: null,
  bankAccount: null,
  isJustRegistered: false,
  recaptchaResponseToken: '',
  registrationData: {},
}) {
  static fromJS(value: IInitialState): InitialState {
    return new InitialState(value);
  }
}

const initialState = new InitialState();

export default function reducer(state = initialState, action): InitialState {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case actions.SELECT_IDENTIFICATION_BANK:
      return state.set('bank', action.bank);

    case actions.SET_BANK_ACCOUNT:
      return state.set('bankAccount', action.bankAccount);

    case actions.SET_IS_JUST_REGISTERED:
      return state.set('isJustRegistered', action.payload);

    case actions.REGISTRATION_SET_RECAPTCHA_RESPONSE_TOKEN:
      return state.set('recaptchaResponseToken', action.payload);

    case actions.SET_REGISTRATION_DATA:
      return state.set('registrationData', action.payload);

    case actions.DELETE_REGISTRATION_DATA:
      return state.set('registrationData', {});
  }

  return state;
}
