import { useCallback, useState } from 'react';
import Footer from '../components/Footer';
import LoginModal from './LoginModal';
import StaticNavigation from './StaticNavigation';
import Sticky from '../components/Sticky';
import useContentContext from '../../lib/content/useContentContext';

export type Props = {
  // Throws errors with ChildrenArray<*>,
  // couldn't find a working solution.
  children?: any;
  // Own props
  stickyFooter?: boolean;
  // Nav props
  navShadow?: boolean;
  linksHidden?: boolean;
  inverseLoginColor?: boolean;
  noTopPadding?: boolean;
  whiteNav?: boolean;
  logoWhiteStripes?: boolean;
  logoUrl?: string;
  isLoggedIn?: boolean;
  isAdditionalAmountAvailable?: boolean;
};
export const propNames = ['navShadow', 'linksHidden', 'inverseLoginColor', 'noTopPadding', 'whiteNav', 'logoWhiteStripes', 'logoUrl', 'isLoggedIn', 'isAdditionalAmountAvailable'];

const MainLayoutStatic = (props: Props): JSX.Element => {
  const {
    footer,
    navigation,
  } = useContentContext();
  const {
    children,
    stickyFooter,
    ...navProps
  } = props;
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const renderFooter = useCallback(() => {
    if (!footer) {
      return null;
    }

    if (stickyFooter) {
      return (
        <Sticky>
          <Footer content={footer} />
        </Sticky>
      );
    }

    return <Footer content={footer} />;
  }, [footer]);
  const openLogin = useCallback(() => {
    setLoginModalOpen(true);
  }, []);
  const closeLogin = useCallback(() => {
    setLoginModalOpen(false);
  }, []);

  return (
    <div>
      {navigation ? (
        <StaticNavigation {...navProps} content={navigation} onOpenLogin={openLogin}>
          {children}
          {renderFooter()}
        </StaticNavigation>
      ) : (
        <>
          {children}
          {renderFooter()}
        </>
      )}
      <LoginModal onClose={closeLogin} open={loginModalOpen} />
    </div>
  );
};

MainLayoutStatic.defaultProps = {
  navShadow: false,
  inverseLoginColor: false,
  noTopPadding: false,
  whiteNav: false,
  isLoggedIn: false,
};
export default MainLayoutStatic;
