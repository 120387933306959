
import { SecondaryHeading } from '../../../../shared/components/typography';
import injectStyles from '4finance-components-pl';
import QA_CLASSES from '../../../../lib/qa';
import styles from './PaymentHeader.jss';

type Props = {
  classes: Record<string, any>;
  // Own props
  title: string;
  amount: string;
};

const PaymentHeader = (props: Props): JSX.Element => {
  const {
    title,
    amount,
    classes,
  } = props;
  const [whole, fraction] = amount.split('.');

  return (
    <div className={classes.root}>
      <SecondaryHeading bigger lighter className={classes.heading}>
        {title}
      </SecondaryHeading>
      <div className={classes.amount}>
        <span className={QA_CLASSES.INTEGER_PART}>
          {whole}
        </span>
        <span className={QA_CLASSES.DECIMAL_PART}>
          {`.${fraction}`}
        </span>
      </div>
    </div>
  );
};

PaymentHeader.styleRoot = 'PaymentHeader';
export default injectStyles(styles)(PaymentHeader);
