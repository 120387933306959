
import { FieldContainer } from '4finance-components-pl';
import { pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import customStyles from './customStyles';
import DropdownIndicator from './DropdownIndicator';
import extendedTheme from '../../../shared/components/extendedTheme';
import Select, { createFilter } from 'react-select';
import type { TranslateMsg } from '../../../../common/lib/types';

const selectTheme = defaultTheme => ({ ...defaultTheme,
  colors: { ...defaultTheme.colors,
    primary: extendedTheme.getIn(['globals', 'colors', 'primary']),
    primary25: extendedTheme.getIn(['globals', 'colors', 'lightBlue']),
    primary50: extendedTheme.getIn(['globals', 'colors', 'lightBlue']),
  },
  border: 'none',
  borderRadius: 0,
});

type Option = {
  value: any;
  label: string;
};
type Props = {
  // Props provided by `connectField` and `translate`
  onChange: (arg0: {
    value: string;
  }) => void;
  error: string;
  hint: string;
  label: string;
  success: boolean;
  value: string;
  msg: TranslateMsg;
  name: string;
  onionFormName: string;
  // Own props
  clearApiErrorOnChange?: boolean;
  options: string[];
  translateOptions?: boolean;
  defaultValue: string;
  disabled?: boolean;
  className?: string;
  FieldContainerProps?: Partial<{
    width: 'xSmall' | 'small' | 'medium' | 'large' | 'half' | 'full';
    classes: Record<string, any>;
    topMargin: boolean;
    bottomMargin: boolean;
  }>;
};

const SelectField = (props: Props) => {
  const {
    disabled,
    value,
    error,
    msg,
    name,
    translateOptions = true,
  } = props;
  const onChange = useCallback((option: (Option | null | undefined) | null) => {
    if (!option) {
      return;
    }

    props.onChange({
      value: option.value,
    });
  }, []);
  const options = useMemo(() => {
    return props.options.map(o => ({
      value: o,
      label: translateOptions ? msg(`form.${name}.options.${o}`) : o,
    }));
  }, [props.options]);
  const filterOption = createFilter({
    matchFrom: 'start',
  });
  const moveDropdownIndicator = Boolean(value) || error;
  const selectValue = options[props.options.indexOf(value)];
  const defaultValue = props.defaultValue ? options[props.options.indexOf(props.defaultValue)] : {
    value: '',
    label: '',
  };

  return (
    <>
      <FieldContainer {...pick(props, ['label', 'hint', 'value', 'disabled', 'className'])} {...props.FieldContainerProps || {}} success={Boolean(value)}>
        <Select
          placeholder={''}
          noOptionsMessage={() => msg ? msg(`form.${name}.no_option_found`) : ''} // eslint-disable-line no-confusing-arrow
          options={options}
          value={selectValue}
          filterOption={filterOption}
          styles={customStyles}
          theme={selectTheme}
          components={{
            DropdownIndicator,
          }}
          defaultValue={defaultValue}
          onChange={(onChange as any)}
          moveDropdownIndicator={moveDropdownIndicator}
          isDisabled={disabled}
        />
      </FieldContainer>
    </>
  );
};

export default SelectField;
