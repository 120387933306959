import * as R from '../../../browser/routes/routesList';

export default function forbidMissingBank({
  dispatch,
  getState,
}) {
  const bank = getState().settings.get('bank');

  if (bank) {
    return null;
  }

  dispatch({
    type: 'GUARD_FORBID_MISSING_BANK',
  });

  return R.CHANGE_BANK_ACCOUNT_NUMBER;
}
