export default ({ colors, mediaQueries, sizes }) => ({
  toggleButton: {
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    fontSize: '34px',
  },
  tooltip: {
    minWidth: '400px',
    padding: '20px',
    textAlign: 'left',
    background: colors.white,
    color: colors.gray,
    right: '-150px',
  },
  tooltipLeft: {
    '&::after': {
      borderColor: `transparent transparent transparent ${colors.white}`,
    },
  },
  tooltipRight: {
    '&::after': {
      borderColor: `transparent ${colors.white} transparent transparent`,
    },
  },
  tooltipRightBottom: {
    '&::after': {
      borderColor: `transparent transparent ${colors.white} transparent`,
    },
  },
  tooltipCenterBottom: {
    '&::after': {
      borderColor: `transparent transparent ${colors.white} transparent`,
    },
  },
  tooltipLeftBottom: {
    '&::after': {
      borderColor: `transparent transparent ${colors.white} transparent`,
    },
  },
  tooltipRightTop: {
    '&::after': {
      borderColor: `${colors.white} transparent transparent transparent`,
    },
  },
  tooltipCenterTop: {
    '&::after': {
      borderColor: `${colors.white} transparent transparent transparent`,
    },
  },
  tooltipLeftTop: {
    '&::after': {
      borderColor: `${colors.white} transparent transparent transparent`,
    },
  },
  tooltipContent: {
    maxWidth: '100vw',
  },
  tooltipDescription: {
    paddingTop: '60px',
    display: 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 100,
    background: colors.white,
    color: colors.black,
  },
  open: {
    display: 'block',
  },
  tooltipTop: {
    [mediaQueries.breakpointTablet]: {
      background: colors.background,
      padding: `${sizes.gutterWidth}px`,
      justifyContent: 'space-between',
      fontSize: '16px',
      lineHeight: '25px',
      fontWeight: 500,
      position: 'fixed',
      width: '100%',
      display: 'flex',
    },
  },
  tooltipBottom: {
    textAlign: 'justify',
    whiteSpace: 'normal',
    [mediaQueries.breakpointTablet]: {
      maxHeight: '100vh',
      overflow: 'scroll',
      padding: `70px ${sizes.gutterWidth}px ${sizes.gutterWidth}px`,
    },
  },
});
