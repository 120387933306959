import * as actions from './actions';
import { Record, Set } from 'immutable';

interface IInitialState {
  bank: string | null,
  // Blue bars displayed on top of
  // the page after successful change.
  messages: SetConstructor,
  recaptchaResponseToken: string,
}
export class InitialState extends Record({
  bank: null,
  messages: new Set(),
  recaptchaResponseToken: '',
}) {
  static fromJS(value: IInitialState): InitialState {
    return new InitialState(value);
  }
}

const initialState = new InitialState();

export default function reducer(state = initialState, action): InitialState {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case actions.SELECT_BANK:
      return state.set('bank', action.bank);

    case actions.SET_SETTINGS_RECAPTCHA_RESPONSE_TOKEN:
      return state.set('recaptchaResponseToken', action.payload);

    case actions.ADD_MESSAGE:
      return state.set('messages', state.messages.add(action.message));

    case actions.REMOVE_MESSAGE:
      return state.set('messages', state.messages.delete(action.message));
  }

  return state;
}
