import Helmet from 'react-helmet';

type Props = {
  metaTags: Record<string, any>;
  base?: Record<string, any>;
  defaultTitle?: string;
  htmlAttributes?: Record<string, any>;
  link?: string[];
  script?: Record<string, any>[];
  titleTemplate?: string;
};

const MetaTag = (props: Props) => {
  const {
    metaTags,
  } = props;

  const description = () => {
    const content = metaTags.metaDescription;

    if (!content) return {};

    return {
      name: 'keywords',
      content,
    };
  };

  const keywords = () => {
    const content = metaTags.metaKeywords;

    if (!content) return {};

    return {
      name: 'keywords',
      content,
    };
  };

  const meta = [description(), keywords()];

  return <Helmet {...props} meta={meta} title={metaTags.title} />;
};

export default MetaTag;
