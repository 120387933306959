
import { getFieldError, isRequired, email as isValidEmail, password as isValidPassword, maxLength, minLength } from '4finance-validation-pl';
import { sendLoginRequest } from '@common/authentication/actions';
import { useCallback, useRef, useState } from 'react';
import Button from '../../components/Button';
import getCaptchaKey from '@browser/lib/getCaptchaKey';
import injectStyles, { TextField } from '4finance-components-pl';
import QA_CLASSES from '../../../lib/qa';
import Reaptcha from 'reaptcha';
import styles from './LoginForm.jss';
import useContentContext from '../../../lib/content/useContentContext';

function getErrorMessage(error: string, errors: Record<string, any>) {
  if (error === null) {
    return null;
  }

  return errors[error];
}

function getEmailError(value: string, errors: Record<string, any>) {
  const error = getFieldError(value, [isRequired(), maxLength(90), isValidEmail()]);

  return getErrorMessage(error, errors);
}

function getPasswordError(value: string, errors: Record<string, any>) {
  const error = getFieldError(value, [isRequired(), minLength(7), maxLength(30), isValidPassword({
    strong: false,
  })]);

  return getErrorMessage(error, errors);
}

type ErrorsRef = Record<string, string | null>;
type ValuesRef = {
  email: string;
  password: string;
};
type Props = {
  classes: Record<string, any>;
};

const LoginForm = (props: Props): JSX.Element => {
  const {
    classes,
  } = props;
  const errors = useRef<ErrorsRef>({
    email: null,
    password: null,
    api: null,
  });
  const values = useRef<ValuesRef>({
    password: '',
    email: '',
  });
  const {
    login: translations,
  }: {
    login: any;
  } = useContentContext();
  const {
    email: emailLabel,
    password: passwordLabel,
    button: submitLabel,
    error_message: errorMessage,
    errors: errorMessages,
  } = translations;
  const [pending, setPending] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRecaptchaRendered, setIsRecaptchaRendered] = useState<boolean>(false);
  const captcha = useRef(null);
  const onChangeEmail = useCallback(({
    value,
  }) => {
    setEmail(value);
    errors.current.email = getEmailError(value, errorMessages);
    values.current.email = value;
  }, []);
  const onChangePassword = useCallback(({
    value,
  }) => {
    if (errors.current.api) {
      errors.current.api = null;
    }

    setPassword(value);
    errors.current.password = getPasswordError(value, errorMessages);
    values.current.password = value;
  }, []);

  const onSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    captcha.current.execute();
  };

  const onCaptchaExpire = () => captcha.current.reset();

  const onCaptchaRender = () => setIsRecaptchaRendered(true);

  const onCaptchaVerify = useCallback(async (responseToken: string) => {
    setPending(true);
    // eslint-disable-next-line no-shadow
    const {
      email: valuesEmail,
      password: valuesPassword,
    } = values.current;

    const response = await sendLoginRequest({
      username: valuesEmail,
      password: valuesPassword,
      responseToken,
    });

    if (response.ok) {
      window.location.replace('/dashboard');

      return;
    }

    errors.current.api = response.status === 401 ? errorMessage : response.statusText;
    captcha.current.reset();
    setPending(false);
  }, [setPending]);

  return (
    <form onSubmit={onSubmit} className={QA_CLASSES.MODAL_LOGIN_FORM}>
      <TextField value={email} onChange={onChangeEmail} label={emailLabel} error={errors.current.email} width={'full'} type={'email'} name={'username'} bordered />
      <TextField value={password} onChange={onChangePassword} label={passwordLabel} error={errors.current.password} width={'full'} type={'password'} name={'password'} bordered />
      {errors.current.api && (
      <div className={classes.error}>
        {errors.current.api}
      </div>
      )}
      <Button
        type={'submit'}
        className={QA_CLASSES.LOGIN_SUBMIT_BUTTON}
        classes={{
          button: classes.button,
        }}
        disabled={errors.current.password || errors.current.email || pending || !isRecaptchaRendered}
        fullWidth
      >
        {submitLabel}
      </Button>

      <div className={classes.recaptchaWrapper}>
        <Reaptcha
          ref={captcha}
          sitekey={getCaptchaKey()}
          onVerify={onCaptchaVerify}
          onRender={onCaptchaRender}
          onExpire={onCaptchaExpire}
          size="invisible"
          badge="inline"
        />
      </div>
    </form>
  );
};

LoginForm.styleRoot = 'LoginForm';
export default injectStyles(styles)(LoginForm);
