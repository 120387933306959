export default (({
  mediaQueries,
}) => ({
  number: {
    marginTop: '12px',
    '& b': {
      fontWeight: '600',
      marginLeft: '4px',
      [mediaQueries.breakpointSmall]: {
        display: 'block',
        margin: 0,
      },
    },
    '&:first-child': {
      marginRight: '16px',
    },
  },
  numbersContainer: {
    display: 'flex',
  },
}));
