export default {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => {
    const rightMargin = state.selectProps.moveDropdownIndicator ? '25px' : '5px';

    return { ...provided,
      'margin-right': rightMargin,
      svg: {
        height: '16px',
        width: '7px',
      },
    };
  },
  valueContainer: provided => ({ ...provided,
    'margin-top': '9px',
  }),
  control: provided => ({ ...provided,
    border: 'none',
    'box-shadow': 'none',
    'margin-top': '4px',
    'margin-left': '5px',
    ':hover': {
      border: 'none',
      'box-shadow': 'none',
    },
  }),
  menu: provided => ({ ...provided,
    'z-index': 11,
  }),
};
