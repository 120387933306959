import fetch from 'isomorphic-fetch';

export type Interval = {
  min: number;
  max: number;
  step: number;
  defaultValue: number;
};
export type ApplicationConstraints = {
  amountInterval: Interval;
  monthlyPaymentInterval: Interval;
  termInterval: Interval;
  termLimits?: Record<string, any>[]
};
export default async function fetchConstraints(): Promise<ApplicationConstraints> {
  const response = await fetch('/webapi/application/constraints');

  return response.json();
}
