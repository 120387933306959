export default () => ({
  root: {
    fontSize: '28px',
    cursor: 'pointer',
    opacity: 1,
    border: 'none',
    backgroundColor: 'transparent',
    transition: 'opacity 0.3s',
    '&:hover': {
      opacity: 0.65,
    },
  },
});
