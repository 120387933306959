import * as R from '../../../../routes/routesList';

import { compose } from 'redux';
import { useTranslator } from '../../../../../localizations';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import injectStyles, { Container } from '4finance-components-pl';
import QA_CLASSES from '../../../../lib/qa';
import Step from './Step';
import styles from './ProgressBar.jss';

type Props = {
  classes: Record<string, any>;
  location: Location;
};
const STEPS = [
  {
    pathnames: [R.REGISTER],
    title: 'registration.steps.register',
  },
  {
    pathnames: [R.PROFILE,
      // R.EMPLOYMENT
    ],
    title: 'registration.steps.profile',
  },
  {
    pathnames: [
      R.APPLICATION_CONFIRM,
      R.APPLICATION_BANK_IDENTIFICATION,
      R.APPLICATION_BANK_IDENTIFICATION_PENNY_TRANSFER,
      R.DECISION,
      R.APPLICATION_PENDING,
      R.APPLICATION_FAILED,
      R.APPLICATION_IDENTIFICATION_FAILED,
      R.APPLICATION_CREDIT_CHECK,
    ],
    title: 'registration.steps.confirm',
  },
];

const ProgressBar = (props: Props): JSX.Element => {
  const { classes, location } = props;
  const { msg } = useTranslator();
  const currentStep = STEPS.findIndex((s) => s.pathnames.includes(location.pathname));

  return (
    <div className={classnames(classes.root, QA_CLASSES.PROGRESS_BAR)}>
      <div className={classnames(classes.side, 'left')} />
      <div className={classnames(classes.side, 'right')} />
      <Container className={classes.container}>
        {STEPS.map((s, index) => (
          <Step key={index} title={msg(s.title)} number={index + 1} active={currentStep >= index} />
        ))}
      </Container>
    </div>
  );
};

ProgressBar.styleRoot = 'ProgressBar';
export default compose<any>(withRouter, injectStyles(styles))(ProgressBar);
