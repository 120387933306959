import { isRequired } from '4finance-validation-pl';

const ERRORS = {
  errorFeminine: 'required_feminine',
  errorMasculine: 'required_masculine',
  errorPlural: 'required_plural',
  errorSwo: 'required_swo',
  error: 'required',
};
const isFalsy = isRequired();

export default function isRequiredAltMessage(param = 'error') {
  return (value: string) => {
    return isFalsy(value) ? ERRORS[param] : null;
  };
}
