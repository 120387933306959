import validator from 'validator';

const ERROR = 'invalid_email';

export default function email() {
  return (value: string | null | undefined): string | null => {
    if (value && !validator.isEmail(value)) {
      return ERROR;
    }

    return null;
  };
}
