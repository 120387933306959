import * as DashboardSection from '../../../components/DashboardSection';

import { BodyText } from '../../../../shared/components/typography';
import { compose } from 'redux';
import { connectPrefetch } from '../../../../connect';
import { useLoanContext } from '../LoanContext';
import { useTranslator } from '../../../../../localizations';
import injectStyles from '4finance-components-pl';
import ManualTransfer from '../ManualTransfer';
import PaymentHeader from '../PaymentHeader';
import styles from './Extension.jss';

type Props = {
  classes: Record<string, any>;
};

const Extension = (props: Props): JSX.Element => {
  const {
    classes,
  } = props;
  const {
    msg,
  } = useTranslator();
  const {
    loanNumber,
    loanExtensionFees,
    nextInstallment,
    dueDate,
  } = useLoanContext();
  const {
    fee,
    newLastInstalmentDueDate,
    newNextInstalmentDueDate,
  } = loanExtensionFees[0];

  return (
    <>
      <DashboardSection.Block>
        <BodyText>
          {msg('dashboard.extension.heading')}
        </BodyText>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td />
              <td>{msg('dashboard.extension.due_date')}</td>
              <td>{msg('dashboard.extension.new_due_date')}</td>
            </tr>
            <tr>
              <td>{msg('dashboard.extension.installment')}</td>
              <td>{nextInstallment.dueDate}</td>
              <td>{newNextInstalmentDueDate}</td>
            </tr>
            <tr>
              <td>{msg('dashboard.extension.last_installment')}</td>
              <td>{newLastInstalmentDueDate}</td>
              <td>{dueDate}</td>
            </tr>
          </tbody>
        </table>
      </DashboardSection.Block>
      <DashboardSection.Block yellow>
        <PaymentHeader title={msg('dashboard.extension.payment_title')} amount={fee} />
        <ManualTransfer
          amount={fee}
          title={msg('dashboard.payment.manual.extension_title', {
            loanNumber,
          })}
        />
      </DashboardSection.Block>
    </>
  );
};

Extension.styleRoot = 'Extension';
export default compose<any>(connectPrefetch([['api', 'fetchClientBanks']]), injectStyles(styles))(Extension);
