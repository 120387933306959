export default (({
  colors,
  mediaQueries,
}) => ({
  formContainer: {
    maxWidth: '720px',
    margin: '0 auto',
  },
  container: {
    margin: '32px auto',
    backgroundColor: colors.white,
  },
  header: {
    backgroundColor: colors.secondary,
    padding: '24px 32px',
    [mediaQueries.breakpointSmall]: {
      padding: '16px',
    },
  },
  offer: {
    backgroundColor: colors.mediumLightGray,
    padding: '24px 32px',
    [mediaQueries.breakpointSmall]: {
      padding: '16px',
    },
  },
  consents: {
    padding: '24px 32px',
    '& label > div:first-child': {
      boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.2)',
    },
    [mediaQueries.breakpointSmall]: {
      padding: '16px',
    },
  },
}));
