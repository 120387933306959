
import { MainHeading } from '../../typography';
import classnames from 'classnames';
import injectStyles, { Container } from '4finance-components-pl';
import Separator from '../../Separator';
import styles from './Section.jss';

type Props = {
  classes: {
    outerContainer: string;
    container: string;
    centeredInnerContainer: string
  };
  children: React.ReactChildren;
  // Own props
  yellow?: boolean;
  gray?: boolean;
  blue?: boolean;
  title?: string | JSX.Element;
  centered?: boolean;
  noTopPadding?: boolean;
  flex: boolean;
  separator?: boolean;
  className?: string;
  headingClass?: string;
};

const Section = (props: Props): JSX.Element => {
  const {
    classes,
    yellow,
    gray,
    blue,
    children,
    title,
    centered,
    noTopPadding,
    flex,
    separator,
    headingClass = '',
    className = '',
  } = props;

  return (
    <div className={classnames(classes.outerContainer, className, {
      yellow,
      gray,
      blue,
    })}
    >
      <Container className={classnames(classes.container, {
        noTopPadding,
        flex,
      })}
      >
        {title && (
        <>
          <MainHeading centered={centered} className={headingClass}>
            {title}
          </MainHeading>
          {separator && <Separator centered={centered} />}
        </>
        )}
        {centered ? (
          <div className={classes.centeredInnerContainer}>
            {children}
          </div>
        ) : children}
      </Container>
    </div>
  );
};

Section.styleRoot = 'Section';
export default injectStyles(styles)(Section);
