export default (({
  colors,
}) => ({
  root: {
    backgroundColor: colors.white,
    padding: '16px 16px 16px 24px',
    borderLeft: `4px solid ${colors.red}`,
    color: colors.red,
  },
}));
