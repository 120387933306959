export default (({
  colors,
  mediaQueries,
}) => ({
  root: {
    height: '46px',
    position: 'relative',
    [mediaQueries.breakpointSmall]: {
      height: '36px',
    },
  },
  side: {
    position: 'absolute',
    width: '50%',
    height: '100%',
    top: 0,
    '&.left': {
      left: 0,
      backgroundColor: colors.primary,
    },
    '&.right': {
      right: 0,
      backgroundColor: colors.secondary,
    },
  },
  container: {
    display: 'flex',
    padding: 0,
    height: '100%',
    position: 'relative',
  },
}));
