import * as R from '../../browser/routes/routesList';

export default async function requireAdditionalAmountPossible({
  dispatch,
  getApiResponse,
}) {
  const {
    additionalAmount,
  } = await getApiResponse(['fetch', 'client']);

  if (additionalAmount) {
    return null;
  }

  dispatch({
    type: 'GUARD_REQUIRE_ADDITIONAL_AMOUNT_POSSIBLE_FAILED',
  });

  return R.DASHBOARD;
}
