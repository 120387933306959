import type { ContentImage } from '../../types';

export default function getImageUrl(image: ContentImage | null, supportsWebP: boolean) {
  const fileFormat: string = supportsWebP ? '?fm=webp' : '';

  if (!image) {
    return null;
  }

  const url = image.fields.file.url;

  return `${url}${fileFormat}`;
}
