export const ERROR_TOO_SHORT = 'too_short';
export const ERROR_FORMAT = 'letters_spaces_and_hyphen_only';
export const ERROR_REQUIRED = 'required_feminine';

// eslint-disable-next-line no-useless-escape
const REGEX = new RegExp('[A-ZĄŚĘĆŹŻŁÓŃ][-\s]{0,1}[A-ZĄŚĘĆŹŻŁÓŃ]+$', 'i');

// validation that allows for the customer to enter a city name consisting of either
// one word or multiple words separated by a single space or a hyphen
export default function city() {
  return (value: string): string | null => {
    if (!value) {
      return ERROR_REQUIRED;
    }

    const trimmedValue: string = value.trim();
    const lettersOnlyValue = trimmedValue.replaceAll('-', '').replaceAll(' ', '');

    if (lettersOnlyValue.length < 2) {
      return ERROR_TOO_SHORT;
    }

    if (trimmedValue && !REGEX.test(trimmedValue)) {
      return ERROR_FORMAT;
    }

    return null;
  };
}
