import * as R from '../../../../routes/routesList';

import { BodyText, Hyperlink } from '../../../../shared/components/typography';
import { compose } from 'redux';
import { connectState } from '../../../../connect';
import { useTranslator } from '../../../../../localizations';
import injectStyles from '4finance-components-pl';
import styles from './CrossCheckErrors.jss';
import type { ImmutableMap } from '../../../../../common/lib/types';

type Props = {
  classes: Record<string, any>;
  formFields: ImmutableMap | null | undefined;
};

const CrossCheckErrors = (props: Props): JSX.Element | null => {
  const {
    classes,
    formFields,
  } = props;
  const {
    msg,
  } = useTranslator();
  const fields = formFields?.toJS?.() || {};
  const errorFields = Object.keys(fields).filter(n => fields[n]?.apiError === 'exists_in_other_product');

  return errorFields.length > 0 ? (
    <BodyText className={classes.root}>
      <div className={classes.title}>
        {msg('registration.confirm.cross_check_errors.title')}
      </div>
      <ul>
        {errorFields.map(f => (
          <li key={f}>
            {msg(`form.${f}.label`)}
            <Hyperlink to={R.PROFILE}>
              {msg('actions.edit')}
            </Hyperlink>
          </li>
        ))}
      </ul>
    </BodyText>
  ) : null;
};

CrossCheckErrors.styleRoot = 'CrossCheckErrors';
export default compose<any>(connectState({
  formFields: ['onionForm', 'fields', 'confirmApplication'],
}), injectStyles(styles))(CrossCheckErrors);
