import type { ImmutableMap } from '../../../../../common/lib/types';

type Installment = {
  dueDate: string;
  amount: number;
};
export default function getNextInstallment(loan: ImmutableMap, schedule: ImmutableMap): Installment {
  // Instalment paid recently, next is not yet open
  if (typeof loan.get('nearestPaymentDate') === 'undefined') {
    const nextInstallment = schedule.get('items').filter(i => typeof i.get('status') === 'undefined').first();

    return {
      dueDate: nextInstallment.get('dueDate'),
      amount: nextInstallment.get('totalAmount'),
    };
  }

  // Next instalment is still open, can be accessed directly on latest loan
  return {
    dueDate: loan.get('nearestPaymentDate'),
    amount: loan.get('payableAmount'),
  };
}
