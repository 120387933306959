export default ({ colors, mediaQueries }) => ({
  root: {
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    [mediaQueries.breakpointLarge]: {
      display: 'none',
    },
  },
  field: {
    padding: '0 8px',
    '& > div:first-child': {
      fontSize: '12px',
    },
    '& > div:last-child': {
      fontSize: '14px',
    },
    '&.bold': {
      fontWeight: 600,
    },
    [mediaQueries.breakpointSmall]: {
      '& > div:first-child': {
        fontSize: '11px',
      },
      '& > div:last-child': {
        fontSize: '12px',
      },
    },
  },
  edit: {
    backgroundColor: colors.secondary,
    width: '40px',
    flex: '0 0 40px',
    height: '40px',
    border: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.2)',
    padding: '3px 0 0 0',
    '& svg': {
      height: '20px',
      width: '20px',
    },
    '&.active': {
      backgroundColor: colors.primary,
      color: colors.white,
      fontSize: '16px',
      fontWeight: '600',
      '&.disabled': {
        backgroundColor: colors.lightGray,
        '&:hover::before': {
          opacity: '0 !important',
        },
      },
    },
    '&::before': {
      content: '""',
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      transition: 'opacity 0.3s',
    },
    '&:hover::before': {
      opacity: 1,
    },
  },
  staticCalculatorButton: {
    display: 'none !important',
  },
  staticCalculatorContainer: {
    [mediaQueries.breakpointLarge]: {
      display: 'none',
    },
  },
});
