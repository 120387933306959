import * as Fields from '../../fields';
import * as R from '../../../../routes/routesList';

import { BodyText } from '../../../../shared/components/typography';
import { compose } from 'redux';
import { connectActions, connectPrefetch, connectState } from '../../../../connect';
import { connectSubmit, Form } from '4finance-onion-form-pl';
import { Section } from '../../../../shared/components/layout';
import { useCallback, useEffect } from 'react';
import { useTranslator } from '../../../../../localizations';
import Button from '../../../../shared/components/Button';
import ContainerWithCalculator from '../ContainerWithCalculator';
import injectStyles from '4finance-components-pl';
import RandomFill from '../RandomFill';
import styles from './Profile.jss';
import useTextFieldLayoutFix from '../../../../lib/hooks/useTextFieldLayoutFix';

const Submit = connectSubmit(Button);

type Props = {
  classes: Record<string, any>;
  amount: number;
  term: number;
  fullName: string | null | undefined;
  phone: string | null | undefined;
  formPhone: string | null | undefined;
  formPhoneError: string | null | undefined;
  email: string | null | undefined;
  formEmail: string | null | undefined;
  formEmailError: string | null | undefined;
  updateProfile: (...args: Array<any>) => any;
  fetchClientApplicationOffer: (...args: Array<any>) => any;
  isJustRegistered: boolean;
  sendChangeEmailRequest: (...args: Array<any>) => any;
  sendChangePhoneRequest: (...args: Array<any>) => any;
};

const Profile = (props: Props): JSX.Element => {
  const {
    amount,
    term,
    classes,
    fullName,
    updateProfile,
    isJustRegistered,
    fetchClientApplicationOffer,
  } = props;
  const {
    msg,
  } = useTranslator();

  useEffect(() => {
    if (amount && term) {
      fetchClientApplicationOffer(amount, term, 'default');
    }
  }, [amount, term, fetchClientApplicationOffer]);

  const emailFix = useTextFieldLayoutFix();
  const phoneFix = useTextFieldLayoutFix();
  const idCardFix = useTextFieldLayoutFix();
  const SectionTitle = (
    <>
      {msg('registration.profile.title')}
      &nbsp;
      <b>{fullName}</b>
    </>
  );
  const onSubmit = useCallback(async ({
    values,
  }) => {
    await updateProfile(values);
    idCardFix.onAfterChange();
  }, [updateProfile]);
  const onChangeEmail = useCallback(async () => {
    await props.sendChangeEmailRequest('profile', `${R.CHANGE_EMAIL_CONFIRM}?from_profile=1`);
    emailFix.onAfterChange();
  }, [props.sendChangeEmailRequest]);
  const onChangePhone = useCallback(async () => {
    await props.sendChangePhoneRequest('profile', `${R.CHANGE_PHONE_CONFIRM}?from_profile=1`);
    phoneFix.onAfterChange();
  }, [props.sendChangePhoneRequest]);
  const actionText = msg('actions.change');
  const emailActionText = props.email !== props.formEmail && !props.formEmailError ? actionText : '';
  const phoneActionText = props.phone !== props.formPhone && !props.formPhoneError ? actionText : '';
  const actionButtonOverrides = {
    actionButton: classes.actionButton,
    deleteButton: classes.deleteButton,
  };

  return (
    <Section title={SectionTitle} gray separator>
      <ContainerWithCalculator>
        <Form name={'profile'} onSubmit={onSubmit}>
          <RandomFill />

          {!isJustRegistered ? (
            <div className={classes.editableFields}>
              <Fields.Email bottomMargin={false} actionButtonTextDefault={emailActionText} actionButtonTextSuccess={emailActionText} onActionButtonPress={onChangeEmail} classes={actionButtonOverrides} clearApiErrorOnChange inputRef={emailFix.onRef} />
              <Fields.MobilePhone topMargin={false} actionButtonTextDefault={phoneActionText} actionButtonTextSuccess={phoneActionText} onActionButtonPress={onChangePhone} classes={actionButtonOverrides} clearApiErrorOnChange inputRef={phoneFix.onRef} />
            </div>
          ) : ''}

          <Fields.IdentityCardNumber inputRef={idCardFix.onRef} clearApiErrorOnChange />

          <BodyText className={classes.addressHeading}>
            <b>{msg('registration.profile.address')}</b>
          </BodyText>

          <Fields.StreetAndNumber bottomMargin={false} />
          <Fields.PostalCode bottomMargin={false} topMargin={false} />
          <Fields.City topMargin={false} />
          <Submit className={classes.submit} disabled={phoneActionText || emailActionText}>
            {msg('actions.next')}
          </Submit>
        </Form>
      </ContainerWithCalculator>
    </Section>
  );
};

Profile.styleRoot = 'Profile';
export default compose<any>(
  connectPrefetch([
    ['api', 'fetchClient'],
    ['api', 'fetchClientApplication'],
    ['api', 'fetchClientIdentityDocument'],
    ['registration', 'populateProfileForm'],
  ]),
  connectActions({
    updateProfile: ['registration', 'updateProfile'],
    sendChangeEmailRequest: ['settings', 'sendChangeEmailRequest'],
    sendChangePhoneRequest: ['settings', 'sendChangePhoneRequest'],
    fetchClientApplicationOffer: ['api', 'fetchClientApplicationOffer'],
  }),
  connectState({
    amount: ['calculator', 'default', 'amount'],
    term: ['calculator', 'default', 'term'],
    email: ['api', 'fetch', 'client', 'query', 'data', 'email', 'email'],
    formEmail: ['onionForm', 'fields', 'profile', 'email', 'value'],
    formEmailError: ['onionForm', 'fields', 'profile', 'email', 'error'],
    phone: ['api', 'fetch', 'client', 'query', 'data', 'mobilePhone', 'mobilePhone'],
    formPhone: ['onionForm', 'fields', 'profile', 'mobilePhone', 'value'],
    formPhoneError: ['onionForm', 'fields', 'profile', 'mobilePhone', 'error'],
    fullName: ['api', 'fetch', 'client', 'query', 'data', 'fullName'],
    isJustRegistered: ['registration', 'isJustRegistered'],
  }),
  injectStyles(styles),
)(Profile);
