export default ({ colors }) => ({
  button: {
    width: '100%',
  },
  error: {
    margin: '16px 0',
    color: colors.red,
    fontSize: '11px',
  },

  recaptchaWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
});
