import { actions as api } from '../../api';

export function fetchClientApplicationOffer() {
  return async ({
    dispatch,
  }: Record<string, any>) => {
    const {
      payload: application,
    } = await dispatch(api.fetchClientApplication());
    const {
      amount,
      term,
    } = application;

    dispatch(api.fetchClientApplicationOffer(amount, term));
  };
}
