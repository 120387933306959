
import { pick } from 'lodash';
import classnames from 'classnames';
import ContextAwareLink from '../../ContextAwareLink';
import injectStyles from '4finance-components-pl';
import styles from './Hyperlink.jss';
import type { Props as ContextAwareLinkProps } from '../../ContextAwareLink';

type Props = Partial<ContextAwareLinkProps> & {
  classes: Record<string, any>;
  children: React.ReactChildren;
  // Own props
  className?: string;
  onClick?: (...args: Array<any>) => any;
};

const Hyperlink = (props: Props): JSX.Element => {
  const {
    classes,
    className,
    onClick,
    children,
  } = props;
  const headingClassName = classnames(classes.root, className);
  const linkProps = pick(props, ['to', 'external', 'destinationRouteType']);

  linkProps.to = linkProps.to || null;

  return (
    <ContextAwareLink {...linkProps}>
      <button type="button" className={headingClassName} onClick={onClick}>
        {children}
      </button>
    </ContextAwareLink>
  );
};

Hyperlink.styleRoot = 'Hyperlink';
export default injectStyles(styles)(Hyperlink);
