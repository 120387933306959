export default (() => ({
  root: {
    textAlign: 'center',
    minHeight: '20px',
    margin: '10px auto',
    width: '100%',
  },
  innerContainer: {
    width: '55px',
    height: '50px',
    margin: '0 auto',
    position: 'relative',
    top: '-25px',
    '& span': {
      position: 'absolute',
      display: 'block',
      bottom: 0,
      height: '5px',
      backgroundColor: '#e1e1e1',
      width: '9px',
      animation: 'wave 1.5s infinite ease-in-out',
      '&:nth-child(2)': {
        left: '11px',
        animationDelay: '0.2s',
      },
      '&:nth-child(3)': {
        left: '22px',
        animationDelay: '0.4s',
      },
      '&:nth-child(4)': {
        left: '33px',
        animationDelay: '0.6s',
      },
      '&:nth-child(5)': {
        left: '44px',
        animationDelay: '0.8s',
      },
    },
  },
  '@global': {
    '@keyframes wave': {
      '0%': {
        height: '5px',
        transform: 'translateY(0)',
        backgroundColor: '#e1e1e1',
      },
      '25%': {
        height: '30px',
        transform: 'translateY(15px)',
        backgroundColor: '#aaaaaa',
      },
      '50%': {
        height: '5px',
        transform: 'translateY(0)',
        backgroundColor: '#666666',
      },
      '100%': {
        height: '5px',
        transform: 'translateY(0)',
        backgroundColor: '#e1e1e1',
      },
    },
  },
}));
