function passwordBackCompatible() {
  return value => {
    if (value && value.match(/\s/i)) {
      return 'password_contains_space';
    }

    if (value && !value.match(/\d+/i)) {
      return 'password_needs_number';
    }

    if (value && !value.match(/[a-zA-Z]+/i)) {
      return 'password_needs_letter';
    }

    return null;
  };
}

export default passwordBackCompatible;
