import { modalContent } from '../../../shared/components/Modal/Modal.jss';

export default ({ colors, mediaQueries }) => ({
  separator: {
    display: 'none',
    [mediaQueries.breakpointTablet]: {
      display: 'block',
      width: '100%',
      height: '1px',
      backgroundColor: colors.lightGrayLine,
    },
  },
  title: {
    display: 'none',
    [mediaQueries.breakpointTablet]: {
      display: 'block',
      position: 'relative',
      width: '100%',
      height: 'auto',
      color: colors.darkGray,
      marginBottom: '20px',
      fontSize: '16px',
      textAlign: 'left',
    },
  },
  text: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    marginTop: '16px',
    marginBottom: '35px',
    fontSize: '32px',
    color: colors.darkGray,
    textAlign: 'center',
    fontWeight: 100,
    [mediaQueries.breakpointTablet]: {
      fontSize: '23px',
    },
  },
  icon: {
    width: '90px',
    height: '90px',
    [mediaQueries.breakpointTablet]: {
      marginTop: '40px',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '320px',
    textAlign: 'center',
  },
  button: {
    margin: '0',
  },
  modalContentWrapper: { ...modalContent },
});
