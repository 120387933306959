import * as R from '../../../browser/routes/routesList';
import * as routerFlow from '../routerFlow';
import { actions as api } from '../../api';
import { push as updatePath } from 'connected-react-router';

export const SELECT_IDENTIFICATION_BANK = 'SELECT_IDENTIFICATION_BANK';
export const SET_BANK_ACCOUNT = 'SET_BANK_ACCOUNT';
export function selectBankForIdentification() {
  return async ({ getState, dispatch }: Record<string, any>): Promise<void> => {
    try {
      dispatch(updatePath(await routerFlow.getNextRouteAfterApplicationBankIdentification(getState)));
    } catch (e) {
      throw new Error('Unable to get next route for bank identification.');
    }
  };
}

export function setBankAccount(bankAccount: string | null): Record<string, any> {
  return {
    type: SET_BANK_ACCOUNT,
    bankAccount,
  };
}
export type IdentificationBank = {
  name: string;
  accountNumber?: string;
  clientIdentificationNumber?: string;
  receiver?: string;
  homePageUrl?: string;
  identification?: string;
};
export function setBank(bank: IdentificationBank | null): Record<string, any> {
  return {
    type: SELECT_IDENTIFICATION_BANK,
    bank,
  };
}
