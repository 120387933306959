
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import styles from './Background.jss';
import type { ContentImage } from '@browser/lib/content/types';

type Props = {
  classes: Record<string, any>;
  children?: React.ReactChildren;
  // Own props
  className?: string;
  breakpoint?: string;
  // Style props

  /* eslint-disable react/no-unused-prop-types */
  height: string;
  position?: string;
  size?: string;
  isPopover?: boolean;
  darken?: number;
  src: string | ContentImage;
  mobileSrc?: string | ContentImage;
  /* eslint-enable react/no-unused-prop-types */

};

const Background = (props: Props): JSX.Element => {
  const {
    classes,
    children,
    className,
    breakpoint = 'breakpointTablet',
  } = props;

  return (
    <div className={classnames(classes.root, classes[breakpoint], className)}>
      {children || null}
    </div>
  );
};

Background.styleRoot = 'Background';
export default injectStyles(styles)(Background);
