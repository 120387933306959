export default ({ colors, mediaQueries }) => ({
  heading: {
    fontSize: '50px',
    lineHeight: 1,
    fontWeight: '300',
    '&.white': {
      color: colors.white,
    },
    '& b': {
      fontWeight: '700',
    },
    [mediaQueries.breakpointTablet]: {
      fontSize: '34px',
    },
  },
});
