
import classnames from 'classnames';
import injectStyles, { Heading } from '4finance-components-pl';
import styles from './MainHeading.jss';

type Props = {
  classes: Record<string, any>;
  children: React.ReactChildren;
  centered?: boolean;
  className?: string;
  white?: boolean;
};

const MainHeading = (props: Props): JSX.Element => {
  const {
    classes,
    children,
    centered,
    className,
    white,
  } = props;
  const headingClassName = classnames(classes.heading, {
    [className || '']: className,
    white,
  });

  return (
    <Heading kind={'h1'} className={headingClassName} noMargin centered={centered}>
      {children}
    </Heading>
  );
};

MainHeading.styleRoot = 'MainHeading';
export default injectStyles(styles)(MainHeading);
