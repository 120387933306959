
import { BodyText } from '../../../../shared/components/typography';
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import styles from './DetailsRow.jss';

type Props = {
  classes: Record<string, any>;
  // Own props
  title: string;
  value: string | number;
  bold?: boolean;
  className?: string;
  valueClassName?: string;
};

const DetailsRow = (props: Props): JSX.Element => {
  const {
    classes,
    title,
    value,
    bold,
    className,
    valueClassName,
  } = props;

  return (
    <BodyText className={classnames(classes.root, className, {
      bold,
    })}
    >
      <span>
        {title}
      </span>
      <span className={valueClassName}>
        {value}
      </span>
    </BodyText>
  );
};

DetailsRow.styleRoot = 'DetailsRow';
export default injectStyles(styles)(DetailsRow);
