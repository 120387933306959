import * as DashboardSection from '../../../components/DashboardSection';

import { compose } from 'redux';
import { connectActions, connectPrefetch, connectState } from '../../../../connect';
import { SecondaryHeading } from '../../../../shared/components/typography';
import { useCallback, useEffect } from 'react';
import { useLoanContext } from '../LoanContext';
import { useTranslator } from '../../../../../localizations';
import injectStyles from '4finance-components-pl';
import ManualTransfer from '../ManualTransfer';
import PaymentHeader from '../PaymentHeader';
import PaymentOption from '../PaymentOption';
import styles from './Payment.jss';

type Props = {
  classes: Record<string, any>;
  selectedPayment: string;
  setSelectedPayment: (arg0: 'fullRepayment' | 'currentInstallment') => void;
};

const Payment = (props: Props): JSX.Element => {
  const {
    classes,
    selectedPayment,
    setSelectedPayment,
  } = props;
  const {
    msg,
  } = useTranslator();
  const loanContext = useLoanContext();
  const {
    payNowAmount,
    payableAmount,
  } = loanContext;
  const displayedAmount = selectedPayment === 'fullRepayment' ? payNowAmount : payableAmount;
  const onChangeSelectedPayment = useCallback(({
    value,
  }) => {
    setSelectedPayment(value);
  }, [setSelectedPayment]);

  useEffect(() => {
    if (loanContext.isDebtCollection && selectedPayment !== 'fullRepayment') {
      setSelectedPayment('fullRepayment');
    }
  }, [selectedPayment, loanContext, setSelectedPayment]);

  return (
    <>
      <DashboardSection.Block>
        <SecondaryHeading bigger lighter className={classes.heading}>
          {msg('dashboard.payment.title')}
        </SecondaryHeading>
        {!loanContext.isDebtCollection && (
        <PaymentOption
          onChange={onChangeSelectedPayment}
          value={'currentInstallment'}
          current={selectedPayment}
          title={msg('dashboard.payment.installmentAmount')}
          displayedAmount={payableAmount}
          footnote={msg('dashboard.payment.dueDate', {
            date: loanContext.nextInstallment.dueDate,
          })}
        />
        )}
        <PaymentOption onChange={onChangeSelectedPayment} value={'fullRepayment'} current={selectedPayment} title={msg('dashboard.payment.fullAmount')} displayedAmount={payNowAmount} />
      </DashboardSection.Block>
      <DashboardSection.Block yellow>
        <PaymentHeader title={msg('dashboard.loan.active.monthlyPayment')} amount={displayedAmount} />
        <ManualTransfer amount={displayedAmount} isFullRepayment={selectedPayment === 'fullRepayment'} />
      </DashboardSection.Block>
    </>
  );
};

Payment.styleRoot = 'Payment';
export default compose<any>(connectPrefetch([['api', 'fetchClientBanks']]), connectActions({
  setSelectedPayment: ['dashboard', 'setSelectedPayment'],
}), connectState({
  selectedPayment: ['dashboard', 'selectedPayment'],
}), injectStyles(styles))(Payment);
