export default ({ colors }) => ({
  marginBottom: {
    marginBottom: '16px',
  },
  image: {
    padding: 0,
  },
  field: {
    border: `1px solid ${colors.gray}`,
    padding: '12px 16px',
    fontWeight: 600,
    backgroundColor: colors.white,
    '& > div:first-child': {
      fontWeight: 500,
      fontSize: '12px',
    },
    '&:last-child': {
      borderTop: 'none',
    },
    '&:first-child': {
      borderTop: `1px solid ${colors.gray}`,
    },
  },
});
