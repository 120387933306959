export default ({ colors, mediaQueries }) => ({
  root: {
    padding: '16px 0',
    borderBottom: `1px solid ${colors.lightGray}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    display: 'flex',
    lineHeight: 1,
    flex: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&.selected': {
      fontWeight: 600,
    },
  },
  displayedValue: {
    fontWeight: 600,
  },
  footnote: {
    marginLeft: '40px',
    color: colors.gray,
    fontSize: '14px',
    [mediaQueries.breakpointSmall]: {
      fontSize: '12px',
    },
  },
  // RadioInput overrides
  optionText: {
    display: 'none',
  },
  wrapper: {
    padding: '12px',
    margin: '0 16px 0 0',
  },
  base: {
    height: '24px',
    width: '24px',
  },
  base__selected: {
    borderWidth: '8px',
  },
});
