import { ClientData } from './types';
import { PickByValue } from 'utility-types';

/* eslint-disable quote-props */
const bankNameFixes = {
  Alior: 'Alior Bank',
  Aliorbank: 'Alior Bank',
  'Bank Pekao S.A.': 'Bank Pekao',
  'Polska Kasa Opieki (PEKAO...': 'Bank Pekao',
  'BOS Bank': 'BOŚ Bank',
  BPH: 'Bank BPH',
  'BPS-E25': 'Bank BPS',
  BZWBK: 'BZ WBK',
  'Citibank Handlowy': 'Citi Handlowy',
  Deutschebank: 'Deutsche bank',
  'Deutsche Bank': 'Deutsche bank',
  Eurobank: 'Euro bank',
  Velo: 'Velo Bank',
  'Velo Bank': 'Velo Bank',
  Getin: 'Getin Bank',
  'Getin Noble': 'Getin Bank',
  'Getin Noble Bank': 'Getin Bank',
  IdeaBank: 'Idea Bank',
  NestBank: 'Nest Bank',
  'ING Bank': 'ING Bank Śląski',
  'mBank S.A.': 'mBank',
  Meritum: 'Meritum Bank',
  MeritumBank: 'Meritum Bank',
  'Millennium Bank': 'Bank Millennium',
  'PBS Bank': 'Bank BPS',
  'PKO Bank Polski - Nowy serwis': 'PKO Bank Polski',
  'PKO - Nowy serwis': 'PKO Bank Polski',
};
/* eslint-enable */

type BankNameKey = keyof typeof bankNameFixes;

export default function getBankName(bank: ClientData, nameProperty: 'bankName' | 'name' = 'bankName'): string {
  const name: BankNameKey = bank[nameProperty as keyof PickByValue<ClientData, BankNameKey>];

  return bankNameFixes[name] || name;
}
