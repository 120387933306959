import { useEffect, useState } from 'react';

export default function useAuthentication(): boolean {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    (async () => {
      const {
        ok,
      } = await fetch('/webapi/client', {
        credentials: 'include',
      });

      setIsLoggedIn(ok);
    })();
  }, []);

  return isLoggedIn;
}
