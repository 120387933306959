import * as actions from './actions';
import { Record } from 'immutable';

interface IAuthState {
  isLoggedIn: boolean,
  loginError: string | null,
}
export class AuthState extends Record({
  isLoggedIn: false,
  loginError: null,
}) {
  static fromJS(value: IAuthState): AuthState {
    return new AuthState(value);
  }
}

const initialState = new AuthState();

export default function reducer(state = initialState, action: actions.AuthAction): AuthState {
  if (!(state instanceof AuthState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case actions.AUTHENTICATION_SET_LOGIN_STATUS:
      return state.set('isLoggedIn', action.payload);

    case actions.AUTHENTICATION_SET_LOGIN_ERROR:
      return state.set('loginError', action.payload);

    case actions.AUTHENTICATION_CLEAR_LOGIN_ERROR:
      return state.set('loginError', null);
  }

  return state;
}
