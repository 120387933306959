
import { routes as authenticationRoutes } from './Authentication';
import { routes as dashboardRoutes } from './Dashboard';
import { Helmet } from 'react-helmet';
import { Loader } from '4finance-components-pl';
import { routes as registrationRoutes } from './Registration';
import { ROOT } from './routesList';
import { Route } from 'react-router';
import { Section } from '@shared/components/layout';
import { useTranslator } from '@localizations';
import App from '../dynamic/app/App';
import createSwitch from './createSwitch';
import DocumentsModal from '@browser/dynamic/components/DocumentsModal';
import Homepage from '../dynamic/home/Homepage';
import loadable from '@loadable/component';

const fallback = (
  <Section gray>
    <Loader />
  </Section>
);
const AuthenticationRouter = loadable(() => import('./Authentication/Router'), {
  fallback,
});
const DashboardRouter = loadable(() => import('./Dashboard/Router'), {
  fallback,
});
const RegistrationRouter = loadable(() => import('./Registration/Router'), {
  fallback,
});

export default ((dispatch: (...args: Array<any>) => any, getState: (...args: Array<any>) => any) => {
  const Switch = createSwitch(dispatch, getState);
  const {
    msg,
  } = useTranslator();

  return () => {
    return (
      <App>
        <Helmet defaultTitle={msg('default_title') as string} />
        <Switch>
          <Route path={ROOT} exact component={Homepage} />
          {Object.entries(authenticationRoutes).map(([path, routeGuards]) => <Route path={path} component={AuthenticationRouter} guards={routeGuards} key={path} />)}
          {Object.entries(dashboardRoutes).map(([path, routeGuards]) => <Route path={path} component={DashboardRouter} guards={routeGuards} key={path} />)}
          {Object.entries(registrationRoutes).map(([path, routeGuards]) => <Route path={path} component={RegistrationRouter} guards={routeGuards} key={path} />)}
          <DocumentsModal />
        </Switch>
      </App>
    );
  };
});
