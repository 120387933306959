const QA_CLASSES = {
  PREFIX: 'qa-',
  HEADER_MENU: 'qa-header-menu',
  FOOTER_COPYRIGHT: 'qa-footer-copyright',
  LOGO: 'qa-logo',
  MENU_ICON_MOBILE: 'qa-menu-icon-mobile',
  HEADER: 'qa-header',
  PROPOSITION_CALCULATOR: 'qa-proposition-calculator',
  PROPOSITION_CALCULATOR_DESKTOP: 'qa-proposition-calculator-desktop',
  PROPOSITION_CALCULATOR_MOBILE: 'qa-proposition-calculator-mobile',
  CONTROLS_CONTAINER: 'qa-controls-container',
  AMOUNT_INPUT: 'qa-amount-input',
  AMOUNT_SLIDER: 'qa-amount-slider',
  AMOUNT_HANDLER: 'qa-amount-handler',
  MONTHLY_PAYMENT_INPUT: 'qa-monthly-payment-input',
  MONTHLY_PAYMENT_SLIDER: 'qa-monthly-payment-slider',
  MONTHLY_PAYMENT_HANDLER: 'qa-monthly-payment-handler',
  CALCULATOR_FOOTER: 'qa-calculator-footer',
  APPLY_BUTTON: 'qa-apply-button',
  LEGAL_TERMS_LINK: 'qa-legal-terms-link',
  GET_LOAN: 'qa-get-loan',
  OFFER: 'qa-offer',
  OFFERED_TOTAL_PRINCIPAL: 'qa-offered-total-principal',
  OFFERED_TERM: 'qa-offered-term',
  OFFERED_TOTAL_COST_OF_CREDIT: 'qa-offered-total-cost-of-credit',
  OFFERED_TOTAL_REPAYABLE_AMOUNT: 'qa-offered-total-repayable-amount',
  OFFERED_RRSO: 'qa-offered-rrso',
  OFFERED_MONTHLY_PAYMENT: 'qa-offered-monthly-payment',
  FIRST_INSTALLMENT_DATE: 'qa-first-installment-date',
  MODAL_LOGIN_FORM: 'qa-modal-login-form',
  LOGIN_SUBMIT_BUTTON: 'qa-login-submit-button',
  CLOSE_BUTTON: 'qa-close-button',
  PROGRESS_BAR: 'qa-progress-bar',
  STEP: 'qa-step',
  CHECK_ALL_CONSENTS: 'qa-check-all-consents',
  RANDOM_FILL_BUTTON: 'qa-random-fill-button',
  REGISTRATION_NEXT_STEP: 'qa-registration-next-step',
  EDIT_BUTTON: 'qa-edit-button',
  EMPLOYMENT_STATUS: 'qa-employment-status',
  EMPLOYER_INDUSTRY: 'qa-employer-industry',
  EMPLOYER_NAME: 'qa-employer-name',
  EMPLOYMENT_START_YEAR: 'qa-employment-start-year',
  EMPLOYMENT_START_MONTH: 'qa-employment-start-month',
  TOTAL_INCOME: 'qa-total-income',
  TOTAL_EXPENSE: 'qa-total-expense',
  NUMBER_OF_INHABITANTS: 'qa-number-of-inhabitants',
  ACCOUNT_NEXT_STEP: 'qa-account-next-step',
  ACCOUNT_PREVIOUS_STEP: 'qa-account-next-step',
  NOT_LISTED: 'qa-not-listed',
  BANK_OPTION: 'qa-bank-option',
  RECEIVER: 'qa-receiver',
  BANK_ACCOUNT_NUMBER: 'qa-bank-account-number',
  IDENTIFICATION_TRANSFER_NUMBER: 'qa-identification-transfer-number',
  DB_LOAN_NUMBER: 'qa-db-loan-number',
  DB_CLIENT_NUMBER: 'qa-db-client-number',
  PANEL_DETAILS: 'qa-panel-details',
  SCHEDULE_LINK: 'qa-schedule-link',
  REPAYABLE_AMOUNT: 'qa-repayable-amount',
  PRINCIPAL_AMOUNT: 'qa-principal-amount',
  INITIAL_COMMISSION_AMOUNT: 'qa-initial-commission-amount',
  INTEREST_AMOUNT: 'qa-interest-amount',
  INTEGER_PART: 'qa-integer-part',
  DECIMAL_PART: 'qa-decimal-part',
  NEXT_INSTALLMENT: 'qa-next-installment',
  LOAN_SCHEDULE_TABLE: 'qa-loan-schedule-table',
  AMOUNTS_FIELD: 'qa-amounts-field',
  PAYMENT_NEXT_STEP: 'qa-payment-next-step',
  LOAN_SCHEDULE_TABLE_CLOSE: 'qa-loan-schedule-table-close',
  NOTIFICATION: 'qa-notification',
  COOKIES_NOTIFICATION_BUTTON: 'qa-cookies-notification-button',
  SCHEDULE_DUE_DATE: 'qa-schedule-due-date',
  SCHEDULE_ITEM_CAPITAL: 'qa-schedule-item-capital',
  SCHEDULE_INTEREST_AMOUNT: 'qa-schedule-interest-amount',
  SCHEDULE_TOTAL_AMOUNT_LEFT: 'qa-schedule-total-amount-left',
  SCHEDULE_INITIAL_COMMISSION: 'qa-schedule-initial-commission',
  ALERT_WARNING: 'qa-alert-warning',
  LATE_WARNING: 'qa-late-warning',
  LATE_OR_DEBT_INFO: 'qa-late-or-debt-info',
  PENALTY_WARNING: 'qa-penalty-warning',
  TRANSFER_PENDING: 'qa-transfer-pending',
  ACCOUNT_NUMBER_MESSAGE: 'qa-account-number-message',
  PENALTY_BREAKDOWN: 'qa-penalty-breakdown',
  CLIENT_ADDRESS_EDIT_BUTTON: 'qa-client-address-edit-button',
  CLIENT_EMAIL_EDIT_BUTTON: 'qa-client-email-edit-button',
  CLIENT_EMAIL_CONFIRM_BUTTON: 'qa-client-email-confirm-button',
  CLIENT_IDENTITY_CARD_NUMBER_EDIT_BUTTON: 'qa-client-id-document-number-edit-button',
  CLIENT_PASSWORD_EDIT_BUTTON: 'qa-client-password-edit-button',
  CLIENT_PHONE_EDIT_BUTTON: 'qa-client-phone-number-edit-button',
  CLIENT_PHONE_CONFIRM_BUTTON: 'qa-client-phone-number-confirm-button',
  CLIENT_PHONE_EDIT_INPUT: 'qa-client-phone-number-edit-input',
  CLIENT_BANK_ACCOUNT_NUMBER_EDIT_BUTTON: 'qa-client-bank-account-number-edit-button',
  CLIENT_CONSENTS_EDIT_BUTTON: 'qa-client-consents-edit-button',
  CLIENT_ADDRESS_EDIT_VALUE: 'qa-client-address-edit-value',
  CLIENT_IDENTITY_CARD_NUMBER_EDIT_VALUE: 'qa-client-id-document-number-edit-value',
  CLIENT_EMAIL_EDIT_VALUE: 'qa-client-email-edit-value',
  CLIENT_EMAIL_EDIT_INPUT: 'qa-client-email-edit-input',
  CLIENT_PHONE_EDIT_VALUE: 'qa-client-phone-number-edit-value',
  CLIENT_BANK_ACCOUNT_NUMBER_EDIT_VALUE: 'qa-client-bank-account-number-edit-value',
  CLIENT_CHANGE_SUCCESS_TITLE: 'qa-client-change-success-title',
  CLIENT_CHANGE_SUCCESS_TEXT: 'qa-client-change-success-text',
  CLIENT_CHANGE_SUCCESS_BUTTON: 'qa-client-change-success-button',
  BANK_ACCOUNT_NUMBER_FIELD: 'qa-bank-account-number-field',
  BANK_NAME_FIELD: 'qa-bank-name-field',
  BANK_NAME: 'qa-bank-name',
  CORRECT_BANK_ACCOUNT_NUMBER_BUTTON: 'qa-correct-bank-account-number-button',
  CHANGE_BANK_ACCOUNT_NUMBER_BUTTON: 'qa-change-bank-account-number-button',
  CLIENT_IDENTIFICATION_NUMBER: 'qa-client-identification-number',
  ADDRESS: 'qa-address',
  OLD_PASSWORD: 'qa-old-password',
  NEW_PASSWORD: 'qa-new-password',
  LOAN_DENIED_HEADING: 'qa-loan-denied-heading',
  LOAN_DENIED_TEXT: 'qa-loan-denied-text',
  ALERT_PASSWORD_CHANGED: 'qa-alert-password-changed',
  ALERT_EMAIL_CHANGED: 'qa-alert-email-changed',
  ALERT_PHONE_CHANGED: 'qa-alert-phone-changed',
  ALERT_MESSAGE: 'qa-alert-message',
  CHANGE_BY_BANK_TRANSFER_CLOSE: 'change-by-bank-transfer-close',
};

export default QA_CLASSES;
