import { actions as api } from '../../api';
import config from '4finance-configuration-pl';

export function fetchApplicableLoanSchedule(context: 'calculator' | 'application' | 'proposal') {
  // eslint-disable-next-line consistent-return
  return async ({
    dispatch,
    getApiResponse,
    getState,
  // eslint-disable-next-line consistent-return
  }: Record<string, any>) => {
    const {
      authentication,
      calculator,
    } = getState();
    const isLoggedIn = authentication.get('isLoggedIn');
    const {
      default: {
        amount: calculatorAmount,
        term: calculatorTerm,
      },
    } = calculator.toJS();

    if (!isLoggedIn) {
      return dispatch(calculator.fetchApplicationFirstLoanOffer(calculatorAmount, calculatorTerm));
    }

    if (context === 'calculator') {
      return dispatch(api.fetchClientApplicationOffer(calculatorAmount, calculatorTerm));
    }

    if (context === 'application') {
      await api.fetchClientApplication();
      const state = getState().api;
      const isApplicationOpen = state.getIn(['client', 'application', 'query', 'data', 'status']) === 'OPEN';
      const amount = isApplicationOpen ? state.getIn(['client', 'application', 'query', 'data', 'amount']) : calculatorAmount;
      const term = isApplicationOpen ? state.getIn(['client', 'application', 'query', 'data', 'term']) : calculatorTerm;

      return dispatch(api.fetchClientApplicationOffer(amount, term));
    }

    if (context === 'proposal') {
      const {
        proposal: { amount, term },
      } = await getApiResponse(['client', 'application']);

      return dispatch(api.fetchClientApplicationOffer(amount, term));
    }
  };
}
