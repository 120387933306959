export const ROOT_EXTERNAL = 'https://www.zaplo.pl';
// Dynamic routes
export const ROOT = '/';
export const CHANGE_BANK_ACCOUNT = '/change-bank-account';
export const CLIENT_SETTINGS = '/client-settings';
export const CHANGE_BANK_ACCOUNT_NUMBER = '/client-settings/change-bank-account';
export const CONFIRM_BANK_ACCOUNT = '/client-settings/confirm-bank-account';
export const CHANGE_CONSENTS = '/client-settings/change-consents';
export const CHANGE_EMAIL = '/client-settings/change-email';
export const CHANGE_EMAIL_CONFIRM = '/client-settings/change-email/confirmation';
export const CHANGE_EMAIL_SUCCESS = '/client-settings/change-email/success';
export const CHANGE_PASSWORD = '/client-settings/change-password';
export const CHANGE_PASSWORD_SUCCESS = '/client-settings/change-password/success';
export const CHANGE_PHONE = '/client-settings/change-phone-number';
export const CHANGE_PHONE_CONFIRM = '/client-settings/change-phone-number/confirmation';
export const CHANGE_PHONE_SUCCESS = '/client-settings/change-phone/success';
export const CHANGE_NAME = '/client-settings/change-name';
export const CONFIRM_NAME = '/client-settings/confirm-change-name';
export const CHANGE_ADDRESS = '/client-settings/change-address';
export const CHANGE_ADDRESS_SUCCESS = '/client-settings/change-address/success';
export const CHANGE_IDENTITY_CARD_NUMBER = '/client-settings/change-id';
export const CHANGE_ID_SUCCESS = '/client-settings/change-id-success';
export const DASHBOARD = '/dashboard';
export const PAYMENT = '/payment';
export const LOGIN = '/login';
export const REGISTER = '/wniosek-o-pozyczke';
export const AWAITING_LOGIN = '/logging-in';
export const PROFILE = '/profile';
export const EMPLOYMENT = '/employment';
export const AGREEMENTS = '/agreements';
export const APPLICATION_CONFIRM = '/application/confirm';
export const APPLICATION_BANK_IDENTIFICATION = '/application/bank-identification';
export const APPLICATION_CREDIT_CHECK = '/application/credit-check';
export const APPLICATION_CONFIRM_BANK_ACCOUNT = '/application/confirm-bank-account';
export const APPLICATION_BANK_IDENTIFICATION_PENNY_TRANSFER = '/application/bank-identification/penny-transfer';
export const APPLICATION_INCOME_DOCUMENT = '/application/income-document';
export const APPLICATION_IDENTIFICATION_FAILED = '/application/identification/failed';
export const APPLICATION_FAILED = '/application/failed';
export const APPLICATION_PENDING = '/application/pending';
export const APPLICATION_ADDITIONAL_AMOUNT = '/application/additional-amount';
export const DECISION = '/decision';
export const EXTENSION = '/extension';
export const SETTINGS_ROUTES = [
  CHANGE_BANK_ACCOUNT_NUMBER,
  CONFIRM_BANK_ACCOUNT,
  CHANGE_CONSENTS,
  CHANGE_EMAIL,
  CHANGE_EMAIL_CONFIRM,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PHONE,
  CHANGE_PHONE_CONFIRM,
  CHANGE_PHONE_SUCCESS,
  CHANGE_NAME,
  CHANGE_ADDRESS,
  CHANGE_ADDRESS_SUCCESS,
  CHANGE_IDENTITY_CARD_NUMBER,
  CHANGE_ID_SUCCESS,
  CLIENT_SETTINGS,
  CONFIRM_NAME,
];
export const DYNAMIC_ROUTES = [
  ...SETTINGS_ROUTES,
  AGREEMENTS,
  DASHBOARD,
  PAYMENT,
  LOGIN,
  REGISTER,
  AWAITING_LOGIN,
  PROFILE,
  EMPLOYMENT,
  APPLICATION_CONFIRM,
  APPLICATION_BANK_IDENTIFICATION,
  APPLICATION_CREDIT_CHECK,
  APPLICATION_CONFIRM_BANK_ACCOUNT,
  APPLICATION_BANK_IDENTIFICATION_PENNY_TRANSFER,
  APPLICATION_INCOME_DOCUMENT,
  APPLICATION_IDENTIFICATION_FAILED,
  APPLICATION_FAILED,
  APPLICATION_PENDING,
  APPLICATION_ADDITIONAL_AMOUNT,
  DECISION,
  EXTENSION,
  ROOT,
];
export const CONTACT = 'https://www.zaplo.pl/kontakt';
export const DASHBOARD_NOT_AVAILABLE = '/dashboard/not-available';
export const LOGOUT = '/logout';
export const LOGOUT_UNAUTHORIZED = '/logout/unauthorized';
export const PASSWORD_RECOVERY = '/password-recovery';
