export default (({
  colors,
  mediaQueries,
}) => ({
  outerContainer: {
    maxWidth: '970px',
    paddingTop: '24px !important',
  },
  container: {
    position: 'relative',
    display: 'flex',
    border: `1px solid ${colors.inputBorder}`,
    [mediaQueries.breakpointLaptopSmall]: {
      flexDirection: 'column',
    },
  },
}));
