export default (({
  colors,
}) => ({
  // Only here for overrides
  container: {},
  sliderContainer: {},
  termContainer: {},
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  offerContainer: {
    backgroundColor: colors.lightGray,
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&.transparent': {
      backgroundColor: 'transparent',
    },
  },
  button: {
    marginTop: '20px',
  },
}));
