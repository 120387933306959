import * as R from '../../browser/routes/routesList';

export default function requireAuth({
  getState,
  dispatch,
}) {
  const {
    authentication: {
      isLoggedIn,
    },
  } = getState();

  if (isLoggedIn) {
    return null;
  }

  dispatch({
    type: 'GUARD_REQUIRE_AUTH_FAILED',
  });

  if (typeof window !== 'undefined') {
    window.location.replace(R.LOGOUT_UNAUTHORIZED);
  }

  return R.LOGOUT_UNAUTHORIZED;
}
