export type Bank = {
  name: string;
  identification: string;
  identificationCode: number[];
};
const bankList: Bank[] = [{
  name: 'Bank BGZ',
  identification: 'pl-bgz',
  identificationCode: [203, 200, 160, 227],
}, {
  name: 'Bank BPH',
  identification: 'pl-bph',
  identificationCode: [106, 171],
}, {
  name: 'Bank Pekao',
  identification: 'pl-pekao',
  identificationCode: [124, 189],
}, {
  name: 'Bank Pocztowy',
  identification: 'pl-pocztowy',
  identificationCode: [132],
}, {
  name: 'BNP Paribas',
  identification: 'pl-bnpparibas',
  identificationCode: [235, 243],
}, {
  name: 'BOS Bank',
  identification: 'pl-bosbank',
  identificationCode: [154],
}, {
  name: 'BPS-E25',
  identification: 'pl-bpse25',
  identificationCode: [193],
}, {
  name: 'BZ WBK',
  identification: 'pl-bzwbk',
  identificationCode: [109, 150],
}, {
  name: 'Citi Handlowy',
  identification: 'pl-citibank',
  identificationCode: [103],
}, {
  name: 'Credit Agricole',
  identification: 'pl-ca',
  identificationCode: [194],
}, {
  name: 'Deutschebank',
  identification: 'pl-deutsche',
  identificationCode: [191, 188],
}, {
  name: 'Eurobank',
  identification: 'pl-eurobank',
  identificationCode: [147],
}, {
  name: 'Getin Bank',
  identification: 'pl-getinbank',
  identificationCode: [248, 146, 156],
}, {
  name: 'Velo Bank',
  identification: 'pl-velobank',
  identificationCode: [248, 146, 156],
}, {
  name: 'ING Bank Śląski',
  identification: 'pl-ingbank',
  identificationCode: [105],
}, {
  name: 'mBank',
  identification: 'pl-mbank',
  identificationCode: [114, 215],
}, {
  name: 'Millennium',
  identification: 'pl-millennium',
  identificationCode: [116],
}, {
  name: 'Plus Bank',
  identification: 'pl-plusbank24',
  identificationCode: [168],
}, {
  name: 'Skok',
  identification: 'pl-skok',
  identificationCode: [],
}, {
  name: 'Toyota Bank',
  identification: 'pl-toyota',
  identificationCode: [216],
}, {
  name: 'Volkswagen Bank',
  identification: 'pl-vwbank',
  identificationCode: [213],
}];

export default bankList;
