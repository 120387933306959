
import { BodyText } from '../../../../shared/components/typography';
import { compose } from 'redux';
import { connectSelectors } from '../../../../connect';
import { useLoanContext } from '../LoanContext';
import { useTranslator } from '../../../../../localizations';
import injectStyles from '4finance-components-pl';
import styles from './ManualTransfer.jss';
import type { ImmutableMap } from '../../../../../common/lib/types';

type Props = {
  classes: Record<string, any>;
  clientBank: ImmutableMap | null | undefined;
  amount: string;
  title?: string;
  isFullRepayment?: boolean,
};

const ManualTransfer = (props: Props): JSX.Element => {
  const {
    classes,
    amount,
    clientBank,
    title,
    isFullRepayment,
  } = props;
  const {
    msg,
  } = useTranslator();
  const {
    loanNumber,
  } = useLoanContext();

  if (!clientBank) {
    return null;
  }

  const receiver = clientBank.get('receiver');
  const account = clientBank.get('accountNumber');

  return (
    <div className={classes.root}>
      <BodyText html>
        {msg('dashboard.payment.manual.header')}
      </BodyText>
      <BodyText>
        <b>{msg('dashboard.payment.manual.receiver')}</b>
        <br />
        {receiver}
      </BodyText>
      <BodyText>
        <b>{msg('dashboard.payment.manual.account')}</b>
        <br />
        {account}
      </BodyText>
      <BodyText>
        <b>{msg('dashboard.payment.manual.title')}</b>
        <br />
        {title || msg(`dashboard.payment.manual.repayment${isFullRepayment ? '_full' : ''}`, {
          loanNumber,
        })}
      </BodyText>
      <BodyText>
        <b>{msg('dashboard.payment.manual.amount')}</b>
        <br />
        {amount}
      </BodyText>
    </div>
  );
};

ManualTransfer.styleRoot = 'ManualTransfer';
export default compose<any>(connectSelectors({
  clientBank: ['dashboard', 'clientBank'],
  latestLoan: ['dashboard', 'latestLoan'],
}), injectStyles(styles))(ManualTransfer);
