export default ({ colors }) => ({
  formContainer: {
    margin: '60px 0',
  },
  recovery: {
    color: colors.primary,
    fontSize: '12px',
    marginTop: '24px',
    display: 'block',
  },
  error: {
    color: colors.red,
    marginBottom: '24px',
  },
});
