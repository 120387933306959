import { Action, Dispatch } from 'redux';
import { httpDelete, httpGet, httpGetHtml, httpPost, httpPut, httpRequest } from '4finance-http-pl';
import { State } from '@common/types';
import dispatchAndThrow from './dispatchAndThrow';
import dispatchPromise from './dispatchPromise';
import getApiResponse from './getApiResponse';

export type DependencyInjections = {
  statics: {
    persistenceStore: State,
  },
  dynamic: {
    httpDelete: typeof httpDelete,
    httpPost: (path: string) => Record<string, any>,
    httpPut: (path: string, body: Body) => Record<string, any>,
    httpGet: (path: string) => Record<string, any>,
    httpGetHtml: (path: string) => Record<string, any>,
    httpRequest: (path: string) => Record<string, any>,
  },
  withDispatch: {
    dispatchAndThrow: (dispatch: Dispatch, action: Action) => Dispatch<Action>,
    dispatchPromise: (action: Action) => Promise<Dispatch>,
  },
  withStateAndDispatch: {
    getApiResponse: (path: string, {
      dynamicKeys,
      forceFetch,
      actionName,
    }: {
            dynamicKeys: boolean,
            forceFetch: boolean,
            actionName: string
            }
          ) => Record<string, any>,
  },
};

export default function createDependencyInjections({
  persistenceStore,
}: { persistenceStore: State }): DependencyInjections {
  return {
    statics: {
      persistenceStore,
    },
    dynamic: {
      httpDelete,
      httpPost,
      httpPut,
      httpGet,
      httpGetHtml,
      httpRequest,
    },
    withDispatch: {
      dispatchAndThrow,
      dispatchPromise,
    },
    withStateAndDispatch: {
      getApiResponse,
    },
  };
}
