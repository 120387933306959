
import { BodyText } from '../../../../shared/components/typography';
import { Section } from '../../../../shared/components/layout';
import { useTranslator } from '../../../../../localizations';

const ApplicationPending = (): JSX.Element => {
  const {
    msg,
  } = useTranslator();

  return (
    <Section gray separator title={msg('registration.application_pending.title')}>
      <BodyText>
        {msg('registration.application_pending.heading')}
      </BodyText>
    </Section>
  );
};

export default ApplicationPending;
