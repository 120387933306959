export default (({
  colors,
}) => ({
  root: {
    maxHeight: '32px',
    marginBottom: '32px',
    backgroundColor: colors.darkGray,
    '&:hover': {
      backgroundColor: colors.gray,
    },
  },
}));
