function getFullBackgroundImage(src: string | Record<string, any>, fallback: string, darken?: number) {
  if (typeof src === 'undefined') {
    return fallback;
  }

  const url = typeof src === 'string' ? src : src.fields.file.url;
  const gradient = darken ? `linear-gradient(rgba(0, 0, 0, ${darken}), rgba(0, 0, 0, ${darken})), ` : '';

  return `${gradient} url(${url})`;
}

export default (({
  mediaQueries,
}: Record<string, any>) => ({
  root: (props: Record<string, any>) => {
    const {
      height,
      position,
      size,
      src,
      darken,
    } = props;
    const imgSrc = '/assets/img/bg-desktop.png';

    return {
      backgroundPosition: position || 'center',
      backgroundSize: size || 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: getFullBackgroundImage(src, imgSrc, darken),
      height: height || 'auto',
    };
  },
  // Dynamic styling based on props does not work with media queries, so
  // it cannot be done directly in the root class.
  ...Object.keys(mediaQueries).reduce((all, k) => ({ ...all,
    [k]: {
      [mediaQueries[k]]: {
        backgroundImage: 'url(\'/assets/img/bg-mobile.png\')',
      },
    },
  }), {}),
}));
