type Props = {
  from?: string;
  to?: string;
  forbidden?: string[];
}; // returns random capital letter in a given range;
// if no range given, returns any capital letter

export default function randomLetter(props: Props): string {
  const {
    from = 'A',
    to = 'Z',
    forbidden = [],
  } = props;
  const min: number = from.toUpperCase().charCodeAt(0);
  const max: number = to.toUpperCase().charCodeAt(0);
  const length: number = Math.abs(max - min);
  const forbiddenCharCodes = forbidden.map(el => el.toUpperCase().charCodeAt(0));
  const numbers: number[] = [...Array(length).keys()].map(num => num + min).sort(() => Math.random() - 0.5).filter(el => !forbiddenCharCodes.includes(el));

  return String.fromCharCode(numbers[0]);
}
