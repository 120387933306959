import { DYNAMIC_ROUTES } from '@routes/routesList';
import { memo, ReactElement, useContext } from 'react';
import ComponentContext from '@browser/lib/contexts/ComponentContext';

type To = { pathname: string, state: { fromDashboard: boolean; } }

export type OwnProps = {
  to: string | To;
  external?: boolean;
  className?: string;
  destinationRouteType?: 'static' | 'dynamic';
  target?: string;
};
type Props = OwnProps & {
  children: ReactElement;
};

const ContextAwareLink = (props: Props): JSX.Element => {
  const {
    to,
    external = false,
    children,
    destinationRouteType,
    target,
    ...componentProps
  } = props;
  const {
    LinkComponent,
  } = useContext(ComponentContext);
  const canDetermineDestinationRouteType = !to || external || destinationRouteType || DYNAMIC_ROUTES.includes((to as To).pathname || to as string);

  if (to && !canDetermineDestinationRouteType) {
    // eslint-disable-next-line no-console
    console.warn(`[ContextAwareLink] Unable to infer destination route type for \`${to}\`.`);
  }

  if (!to) {
    return children;
  }

  return external ? (
    <a {...componentProps} href={to as string} target={target}>
      {children}
    </a>
  ) : (
    <LinkComponent {...componentProps} to={to}>
      {children}
    </LinkComponent>
  );
};

export default memo<Props>(ContextAwareLink);
