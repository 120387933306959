import * as R from '../../browser/routes/routesList';
import { fetchLatestLoan } from '../dashboard/actions';

export default async function requireExtensionPossible({
  dispatch,
}) {
  const {
    payload: {
      payload,
    },
  } = await dispatch(fetchLatestLoan());

  if (payload?.loanExtensionFees?.length) {
    return null;
  }

  dispatch({
    type: 'GUARD_REQUIRE_EXTENSION_POSSIBLE_FAILED',
  });

  return R.DASHBOARD;
}
