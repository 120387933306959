export default (({
  mediaQueries,
}) => ({
  loginLink: {
    margin: '0 4px',
  },
  heading: {
    marginBottom: '32px',
  },
  buttonsContainer: {
    display: 'flex',
    marginRight: '-100px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [mediaQueries.breakpointLaptop]: {
      marginRight: '-224px',
    },
    [mediaQueries.breakpointLaptopSmall]: {
      marginRight: '0',
      display: 'block',
    },
  },
  submit: {
    flex: '0 0 auto',
    '& small': {
      display: 'block',
    },
    [mediaQueries.breakpointSmall]: {
      width: '100%',
    },
  },
}));
