import 'dayjs/locale/pl';
import 'regenerator-runtime/runtime';

import { ConnectedRouter, push } from 'connected-react-router';
import { DynamicChatbot } from './shared/components/Chatbot';
import { hot } from 'react-hot-loader/root';
import { ThemeProvider } from '4finance-components-pl';
import collectMonitoringData from '4finance-monitoring-pl';
import config from '4finance-configuration-pl';
import configureStoreWithHistory from './configureStoreWithHistory';
import createRoutes from './routes/createRoutes';
import extendedTheme from './shared/components/extendedTheme';

export const { store, history } = configureStoreWithHistory();

declare global {
  interface Window {
    onCntLinkClick: (link: HTMLElement) => boolean;
  }
}

window.onCntLinkClick = (link: HTMLElement): boolean => {
  if (link.getAttribute('target') !== '_blank') {
    store.dispatch(push(link.getAttribute('href')));

    return false;
  }

  return true;
};

window.onload = () => {
  collectMonitoringData();
};

const Routes = hot(createRoutes(store.dispatch, store.getState));

const App = (): JSX.Element => (
  <ThemeProvider style={extendedTheme}>
    <ConnectedRouter history={history}>
      <>
        <Routes />
        {config.chatbot.enabled && <DynamicChatbot />}
      </>
    </ConnectedRouter>
  </ThemeProvider>
);

export default App;
