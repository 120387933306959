import { camelCase } from 'lodash';
import { connectActions, connectState } from '@browser/connect';
import { connectField, SetFormFieldProperty } from '4finance-onion-form-pl';
import { isRequired } from '4finance-validation-pl';
import { Map } from 'immutable';
// eslint-disable-next-line no-duplicate-imports
import { compose } from 'redux';
import { translate } from '@localizations';
import injectStyles, { Checkbox } from '4finance-components-pl';
import ReactMarkdown from 'react-markdown';
import styles from './DeclarationCheckbox.jss';

type Declaration = {
  type: string,
  name: string,
  textLong?: string;
  version?: string | number,
  warning: boolean,
}

type Props = {
  classes: CSSStyleSheet,
  form: string,
  msg: (translationPath: string) => any,
  onChange: any,
  customHandler: any,
  openLegalTermsModal: any,
  product61D?: boolean,
  productNumber: string,
  renderButton: boolean,
  setFieldValue: any,
  setFormFieldProperty: SetFormFieldProperty;
  values: Map<string, any>,
  declaration: Declaration,
  declarationWarning: boolean,
}
const DeclarationCheckbox = ({
  form,
  setFieldValue,
  setFormFieldProperty,
  declaration,
  declaration: { name, type },
  declarationWarning,
  customHandler,
  msg,
}: Props) => {
  const CHECKBOX_CONFIG: Record<string, any> = {
    escapeHtml: false,
    disallowedTypes: ['paragraph'],
    unwrapDisallowed: true,
    transformLinkUri: null,
  };
  const CHECKBOX_STYLE: Record<string, any> = { style: { container: { marginBottom: 25 } } };

  // const renderExpandButton = (isClipped: boolean) => (
  //   <IconChevron className={classnames(classes.expandIcon, !isClipped && classes.expandIcon__rotated)} />
  // );

  const clickHandler = (e) => {
    e.value ? setFieldValue(form, type, name) : setFieldValue(form, type, null);
    setFormFieldProperty(form, 'declaration', 'warning', !e.value);
  };

  const enhancedConfig = (field: Record<string, any>): [() => any] => {
    const config: [() => any] = [];

    if (field.required) {
      config.push(isRequired);
    }

    return config;
  };

  const label = (
    <ReactMarkdown
      source={msg(`dashboard.loan.no_active.declaration.${name}`)}
      {...CHECKBOX_CONFIG}
    />
  );
  const fieldId = camelCase(type);
  const isExpandable = !!declaration?.textLong;

  const ConnectedField =
    connectField(
      fieldId,
      {
        label,
        value: null,
        isExpandable,
        bottomMargin: !isExpandable,
        clippingHeight: isExpandable ? 40 : 60,
        // renderExpandButton,
        ...CHECKBOX_STYLE,
      },
      [...enhancedConfig({ fields: declaration })],
    )(Checkbox);

  return (
    <ConnectedField
      error={declarationWarning ? msg('cwa_notification') : undefined}
      onChange={customHandler || clickHandler}
      key={fieldId}
    />
  );
};

DeclarationCheckbox.styleRoot = 'DeclarationCheckbox';

export default compose<any>(
  connectActions({
    setFieldValue: ['onionForm', 'setFieldValue'],
    setFormFieldProperty: ['onionForm', 'setFormFieldProperty'],
  }),
  connectState(({ form }) => ({
    productNumber: ['api', 'fetch', 'client', 'query', 'data', 'productNumber'],
    declarationWarning: ['onionForm', 'fields', form, 'declaration', 'warning'],
  })),
  translate,
  injectStyles(styles),
)(DeclarationCheckbox);
