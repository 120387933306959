export default (({
  colors,
}: any): any => ({
  root: {
    '&.isEditing': {
      '& $toggle': {
        backgroundColor: colors.white,
      },
      '& $editButton': {
        display: 'none',
      },
      '& $security': {
        display: 'none',
      },
      '& $offerContainer': {
        backgroundColor: colors.white,
      },
    },
  },
  container: {
    minHeight: 'auto',
  },
  toggle: {
    backgroundColor: colors.secondary,
    padding: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'background-color 0.3s',
  },
  editButton: {
    backgroundColor: colors.white,
    color: colors.darkGray,
    maxHeight: '40px',
    '&:hover': {
      backgroundColor: colors.lightGray,
    },
  },
  confirmButton: {
    height: '40px',
    width: '160px',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  security: {
    padding: '32px',
    backgroundColor: colors.white,
    display: 'flex',
    alignItems: 'flex-start',
    '& svg': {
      flex: '0 0 40px',
      marginRight: '16px',
    },
  },
  offerContainer: {
    padding: '32px',
    backgroundColor: colors.mediumLightGray,
    transition: 'background-color 0.3s',
  },
  sliderContainer: {
    backgroundColor: colors.white,
    padding: '4px 32px',
  },
  termContainer: {
    backgroundColor: colors.white,
  },
  legal: {
    textDecoration: 'none',
    width: '100%',
    paddingBottom: '24px',
    marginTop: 0,
  },
}));
