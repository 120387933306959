import CookieDough from 'cookie-dough';

export default function getAffiliateInfo() {
  const cookies = CookieDough();
  const {
    affiliateProvider,
    affiliatePartner,
    affiliateToken,
    affiliateRedirectUrl,
  } = cookies.all();

  return affiliateProvider ? {
    provider: affiliateProvider,
    partner: affiliatePartner,
    token: affiliateToken,
    redirectUrl: affiliateRedirectUrl,
  } : null;
}
