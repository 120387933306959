import randomLetter from './randomLetter';

export const LETTER_VALUES = {
  A: 10,
  B: 11,
  C: 12,
  D: 13,
  E: 14,
  F: 15,
  G: 16,
  H: 17,
  I: 18,
  J: 19,
  K: 20,
  L: 21,
  M: 22,
  N: 23,
  O: 24,
  P: 25,
  Q: 26,
  R: 27,
  S: 28,
  T: 29,
  U: 30,
  V: 31,
  W: 32,
  X: 33,
  Y: 34,
  Z: 35,
};
export const POSITION_WEIGHTS = [7, 3, 1, 0, 7, 3, 1, 7, 3];
const EXCLUDED = ['O', 'Q'];

// get allowed series for IDs starting with 'A' and 'C'
const getSeriesA = () => `A${randomLetter({
  from: 'R',
  to: 'Y',
  forbidden: EXCLUDED,
})}${randomLetter({
  forbidden: EXCLUDED,
})}`;

const getSeriesC = () => `C${randomLetter({
  forbidden: EXCLUDED,
})}${randomLetter({
  forbidden: EXCLUDED,
})}`;

export default function identityCardNumber() {
  // randomly draw a series, C Series 25% of the time
  const rand: number = Math.floor(Math.random() * 4);
  const SERIES: string = rand ? getSeriesA() : getSeriesC();
  const idNumber: string = SERIES + faker.helpers.replaceSymbolWithNumber('######');
  const asArray: Array<string> = idNumber.split('');

  asArray[3] = getCardChecksum(idNumber).toString();

  return asArray.join('');
}
export function getCardChecksum(idNumber: string): number {
  const sum = idNumber.split('').reduce((acc, char, i) => {
    if (i < 3) {
      return acc + LETTER_VALUES[char.toUpperCase()] * POSITION_WEIGHTS[i];
    }

    return acc + parseInt(char, 10) * POSITION_WEIGHTS[i];
  }, 0);

  return sum % 10;
}
