import { AnyAction, compose } from 'redux';
import { connectActions, connectPrefetch, connectState } from '@browser/connect';
import { FetchOffer, SetAmount, SetMonthlyPayment, SetTerm } from '@common/calculator/actions';
import { getOfferCurrentPath, getOfferPath } from './offerPaths';
import { Interval } from '@browser/calculator/requests/fetchMonthlyInterval';
import { Map } from 'immutable';
import { memo, useCallback, useEffect, useRef } from 'react';
import { RootState } from '@browser/configureStoreWithHistory';
import config from '4finance-configuration-pl';
import parseParams from './parseParams';
import type { ApplicationConstraints } from '../../requests/fetchConstraints';
import type { ApplicationOffer } from '../../requests/fetchOffer';

// override basic immutable Map toJS() method to preserve type safety
interface OfferMap<K, V> extends Map<K, V> {
  toJS(): ApplicationOffer;
}
interface ConstraintsMap<K, V> extends Map<K, V> {
  toJS(): ApplicationConstraints;
}

export type PassedProps = {
  onChangeMonthlyPayment: (value: number) => void;
  onChangeAmount: (value: number) => void;
  onChangeTerm: (value: number) => void;
  onAfterChangeAmount: (...args: Array<any>) => any;
  onAfterChangeTerm: (...args: Array<any>) => any;
  offer: ApplicationOffer | null;
  constraints: ApplicationConstraints | null;
  amount: number | null;
  term: number | null;
  monthlyPayment: number | null;
  isInitialLoading: boolean;
  isLoadingOffer: boolean;
};
type OwnProps = {
  /** Identifies the calculator in Redux */
  id: string;
  render: (props: PassedProps) => JSX.Element;
  location: Record<string, any>,
};
type Props = OwnProps & {
  fetchOffer: (id: string) => FetchOffer;
  setAmount: (...args: Array<any>) => SetAmount;
  setMonthlyPayment: (...args: Array<any>) => SetMonthlyPayment;
  setTerm: (...args: Array<any>) => SetTerm;
  fetchClientApplicationConstraints: () => AnyAction;
  fetchApplicationConstraints: () => AnyAction;
  monthlyPayment: number | null;
  isLoggedIn: boolean;
  amount: number | null;
  term: number | null;
  offer: OfferMap<ApplicationOffer, Interval> | null;
  constraints: ConstraintsMap<ApplicationConstraints, Interval> | null;
};

const Control = (props: Props): JSX.Element => {
  const offer = useRef<ApplicationOffer | null>(null);
  const offerHash = useRef<number | null>(null);
  const {
    id,
    render,
    fetchOffer,
    amount,
    monthlyPayment,
    term,
    setAmount,
    setTerm,
    setMonthlyPayment,
    isLoggedIn,
  } = props;

  useEffect(() => {
    fetchOffer(id);
  }, [id, fetchOffer, isLoggedIn]);

  useEffect(() => {
    const { location } = props;

    if (location && location.search) {
      const parsedParams = parseParams(location.search);

      setAmount(parsedParams.amount, id);
      setTerm(parsedParams.term, id);
    }
  }, []);

  const onChangeAmount = useCallback((value: number) => {
    setAmount(value, id);
  }, [id, setAmount]);
  const onChangeTerm = useCallback((value: number) => {
    setTerm(value, id);
  }, [id, setTerm]);
  const onAfterChangeAmount = useCallback(() => {
    fetchOffer(id);
  }, [id, fetchOffer]);
  const onAfterChangeTerm = useCallback(() => {
    fetchOffer(id);
  }, [id, fetchOffer]);
  const onChangeMonthlyPayment = useCallback((value: number) => {
    setMonthlyPayment(value, id);
    fetchOffer(id);
  }, [id, fetchOffer, setMonthlyPayment]);

  // Keep the previous successfully fetched offer, so the dependent
  // components still render when it's `null`. Did not `useEffect`,
  // since it runs after render.
  if (props.offer && props.offer.hashCode() !== offerHash.current) {
    offer.current = props.offer.toJS();
    offerHash.current = props.offer.hashCode();
  }

  const constraints = props.constraints ? props.constraints.toJS() : null;
  const isSecondParamLoaded = typeof term === 'number';
  const isInitialLoading = typeof amount !== 'number' || !isSecondParamLoaded || !offer.current || !constraints;

  const isLoadingOffer = !props.offer;
  const monthlyPaymentEnabled = constraints
    && constraints.monthlyPaymentInterval
    && constraints.monthlyPaymentInterval.min
    && constraints.monthlyPaymentInterval.min < constraints.monthlyPaymentInterval.max;

  return render({
    onChangeMonthlyPayment,
    onChangeAmount,
    onChangeTerm,
    onAfterChangeAmount,
    onAfterChangeTerm,
    offer: offer.current,
    constraints,
    isInitialLoading,
    isLoadingOffer,
    amount,
    term,
    monthlyPayment: monthlyPaymentEnabled ? monthlyPayment : offer.current?.monthlyPayment || null,
  });
};

export default memo<OwnProps>(compose<any>(connectActions({
  setAmount: ['calculator', 'setAmount'],
  setTerm: ['calculator', 'setTerm'],
  setMonthlyPayment: ['calculator', 'setMonthlyPayment'],
  fetchOffer: ['calculator', 'fetchOffer'],
  fetchClientApplicationConstraints: ['api', 'fetchClientApplicationConstraints'],
  fetchApplicationConstraints: ['api', 'fetchApplicationConstraints'],
}), connectState((props: Props, state: RootState) => {
  const {
    id = 'default',
  } = props;
  const isLoggedIn = state.authentication.get('isLoggedIn');
  const amount = state.calculator.getIn([id, 'amount']);
  const term = state.calculator.getIn([id, 'term']) || '';
  const offerBasePath = getOfferPath(isLoggedIn);
  const offerCurrentPath = getOfferCurrentPath({ amount, term, id });

  return {
    constraints: ['api', 'fetch', isLoggedIn && 'client', 'application', 'constraints', 'query', 'data'].filter(Boolean),
    amount: ['calculator', id, 'amount'],
    term: ['calculator', id, 'term'],
    monthlyPayment: ['calculator', id, 'monthlyPayment'],
    offer: offerBasePath.concat(offerCurrentPath),
    isLoggedIn: ['authentication', 'isLoggedIn'],
    location: ['router', 'location'],
  };
}), connectPrefetch([
  ({
    isLoggedIn,
    ...props
  }: Props) => (isLoggedIn ? props.fetchClientApplicationConstraints() : props.fetchApplicationConstraints())]))(Control));
