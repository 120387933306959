import * as R from '../../../../routes/routesList';

import { Apartment, City, House, PostalCode, Street } from '../../fields';
import { compose } from 'redux';
import { connectActions, connectPending, connectState } from '../../../../connect';
import { connectSubmit, Form } from '4finance-onion-form-pl';
import { UPDATE_CLIENT_DATA } from '../../../../../common/settings/actions';
import { useHistory } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useTranslator } from '../../../../../localizations';
import Button from '../../../../shared/components/Button';
import getCaptchaKey from '@browser/lib/getCaptchaKey';
import injectStyles from '4finance-components-pl';
import Modal from '../../../../shared/components/Modal';
import Reaptcha from 'reaptcha';
import styles from '../Change.jss';

const FORM_NAME = 'changeAddress';

type Props = {
  classes: Record<string, any>;
  error: string;
  sendChangeAddress: (...args: Array<any>) => any;
  setSettingsRecaptchaResponseToken: (...args: Array<any>) => any;
  pending: boolean;
};
const Submit = connectSubmit(Button);

const ChangeAddress = (props: Props): JSX.Element => {
  const {
    classes,
    sendChangeAddress,
    setSettingsRecaptchaResponseToken,
    error,
    pending,
  } = props;
  const {
    msg,
  } = useTranslator();
  const history = useHistory();

  const [isCaptchaRendered, setIsCaptchaRendered] = useState(false);
  const captcha = useRef();

  const onSubmit = (): void => {
    executeCaptcha();
  };

  const executeCaptcha = () => captcha.current?.execute();

  const onCaptchaRender = () => setIsCaptchaRendered(true);

  const onCaptchaVerify = (responseToken: string) => {
    setSettingsRecaptchaResponseToken(responseToken);
    sendChangeAddress();
    captcha.current?.reset();
  };

  const goBack = () => history.push(R.CLIENT_SETTINGS);

  const title: string = msg('dashboard.settings.change_address.change.title');
  const description: string = msg('dashboard.settings.change_address.change.description');
  const button: string = msg('dashboard.settings.change_address.change.button');
  const errorMessage: string = msg(`app.errors.${error}`);

  return (
    <div className={classes.modalContentWrapper}>
      <Modal title={title} open onClose={goBack}>
        <div className={classes.separator} />
        <div className={classes.formWrapper}>
          <Form name={FORM_NAME} onSubmit={onSubmit} autocomplete={false} resetOnMount>
            <div className={classes.description}>{description}</div>

            <Street width="full" clearApiErrorOnChange topMargin={false} bottomMargin={false} />
            <div className={classes.twoFieldsContainer}>
              <House width="xSmall" clearApiErrorOnChange topMargin={false} bottomMargin={false} borderRight />
              <Apartment width="xSmall" clearApiErrorOnChange topMargin={false} bottomMargin={false} />
            </div>
            <City width="full" clearApiErrorOnChange topMargin={false} bottomMargin={false} />
            <PostalCode width="half" clearApiErrorOnChange topMargin={false} className={classes.postalCode} />

            <Submit type="submit" fullWidth disabled={pending} loading={pending}>
              {button}
            </Submit>

            {error && <div className={classes.error}>{errorMessage}</div>}
          </Form>
          <div className={classes.recaptchaWrapper}>
            <Reaptcha
              ref={captcha}
              sitekey={getCaptchaKey()}
              onVerify={onCaptchaVerify}
              onRender={onCaptchaRender}
              size="invisible"
              badge="inline"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

ChangeAddress.styleRoot = 'ChangeAddress';
export default compose<any>(connectPending({
  pending: UPDATE_CLIENT_DATA,
}), connectState({
  error: ['onionForm', 'fields', 'clientSettings', 'address', 'error'],
}), connectActions({
  sendChangeAddress: ['settings', 'sendChangeAddress'],
  setSettingsRecaptchaResponseToken: ['settings', 'setSettingsRecaptchaResponseToken'],
}), injectStyles(styles))(ChangeAddress);
