export default (({
  colors,
}) => ({
  table: {
    borderCollapse: 'collapse',
    marginTop: '24px',
    width: '100%',
    '& tr': {
      borderTop: `1px solid ${colors.gray}`,
      '&:first-child': {
        fontWeight: 'bold',
        borderTop: 'none',
      },
    },
    '& td': {
      padding: '16px 8px',
      lineHeight: 1,
      '&:first-child': {
        width: '32%',
      },
      '&:last-child': {
        fontWeight: 600,
      },
    },
  },
}));
