export default (({
  colors,
}) => ({
  container: {
    display: 'none',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 999,
    height: '4px',
    margin: '0 auto 0 auto',
    overflow: 'hidden',
  },
  visible: {
    display: 'block',
  },
  line: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    animation: 'pageLoaderAnimation 2s infinite backwards ease-out',
    transform: 'scaleX(1)',
  },
  first: {
    backgroundColor: colors.secondary,
    animationDelay: '-1.5s',
  },
  second: {
    backgroundColor: colors.primary,
    animationDelay: '-1.0s',
  },
  third: {
    backgroundColor: colors.secondary,
    animationDelay: '-0.5s',
  },
  fourth: {
    backgroundColor: colors.primary,
    animationDelay: '-0.0s',
  },
  '@keyframes pageLoaderAnimation': {
    '0%': {
      transform: 'scaleX(0)',
      zIndex: 15,
    },
    '22%': {
      transform: 'scaleX(0)',
      zIndex: 15,
    },
    '50%': {
      transform: 'scaleX(1)',
    },
    '90%': {
      transform: 'scaleX(1)',
      zIndex: 1,
    },
    '100%': {
      transform: 'scaleX(1)',
      zIndex: 0,
    },
  },
}));
