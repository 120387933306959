export default (({
  colors,
}) => ({
  root: {
    '& form > button': {
      color: colors.primary,
      borderColor: colors.primary,
      '&:hover': {
        backgroundColor: colors.primary,
      },
      '&[disabled]': {
        color: colors.gray,
        borderColor: colors.gray,
      },
    },
  },
}));
