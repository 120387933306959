
import { BodyText, SecondaryHeading } from '../shared/components/typography';
import { compose } from 'redux';
import { connectActions } from '../connect';
import { Control, Loader, Sliders, slidersPropNames } from './components';
import { pick } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslator } from '../../localizations';
import Button from '../shared/components/Button';
import classnames from 'classnames';
import Container from './components/Container';
import injectStyles from '4finance-components-pl';
import LegalTerms from './components/LegalTerms/LegalTerms';
import Offer from './components/Offer';
import QA_CLASSES from '../lib/qa';
import Shield from '../svg/uEA0C-shield.svg';
import styles from './EditableCalculator.jss';
import type { PassedProps } from './components';

type Props = {
  classes: Record<string, any>;
  className: string;
  updateMonthlyPaymentInterval: (...args: Array<any>) => any;
};

const EditableCalculator = (props: Props): JSX.Element => {
  const {
    classes,
    className,
    updateMonthlyPaymentInterval,
  } = props;
  const {
    msg,
  } = useTranslator();
  const [isEditing, setIsEditing] = useState(false);
  const onEdit = useCallback(() => setIsEditing(true), [setIsEditing]);
  const onFinishEdit = useCallback(() => setIsEditing(false), [setIsEditing]);

  return (
    <Control render={(passedProps: PassedProps) => {
      const {
        isInitialLoading,
        isLoadingOffer,
        offer,
        amount,
        onChangeAmount,
        onAfterChangeAmount,
      } = passedProps;

      const onChangeAmountWithMonthlyIntervalUpdate = async value => {
        await updateMonthlyPaymentInterval(value);
        onChangeAmount(value);
        onAfterChangeAmount();
      };

      return (
        <Loader pending={isInitialLoading}>
          <Container className={classes.container}>
            <div className={classnames(classes.root, className, {
              isEditing,
            })}
            >
              <div className={classes.toggle}>
                <SecondaryHeading bigger>
                  {msg('registration.calculator.details')}
                </SecondaryHeading>
                {isEditing ? (
                  <Button className={classnames(classes.confirmButton, QA_CLASSES.CLOSE_BUTTON)} onClick={onFinishEdit}>
                    {msg('registration.calculator.confirm')}
                  </Button>
                ) : (
                  <Button className={classnames(classes.editButton, QA_CLASSES.EDIT_BUTTON)} onClick={onEdit}>
                    {msg('registration.calculator.edit')}
                  </Button>
                )}
              </div>
              {isEditing && <Sliders classes={pick(classes, ['sliderContainer', 'termContainer'])} {...pick(passedProps, slidersPropNames)} onChangeAmount={onChangeAmount} onAfterChangeAmount={onChangeAmountWithMonthlyIntervalUpdate} />}
              <Offer
                offer={offer}
                pending={isLoadingOffer}
                classes={{
                  container: classes.offerContainer,
                }}
                short={!isEditing}
              />
              {isEditing && <LegalTerms amount={amount} term={offer?.term} className={classes.legal} />}
              <div className={classes.security}>
                <Shield />
                <BodyText html>
                  {msg('registration.calculator.security')}
                </BodyText>
              </div>
            </div>
          </Container>
        </Loader>
      );
    }}
    />
  );
};

EditableCalculator.styleRoot = 'EditableCalculator';
export default compose<any>(connectActions({
  updateMonthlyPaymentInterval: ['calculator', 'updateMonthlyPaymentInterval'],
}), injectStyles(styles))(EditableCalculator);
