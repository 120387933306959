
import { Background } from '../../../../shared/components/layout';
import injectStyles from '4finance-components-pl';
import styles from './VivusPopover.jss';
import useDevice from '../../../../lib/hooks/useDevice';

const URL = 'https://www.vivus.pl/new?utm_source=zaplo.pl&utm_medium=site&utm_campaign=zaplo_dashboard_cloud_banner';

type Props = {
  classes: Record<string, any>;
};

const VivusPopover = (props: Props): JSX.Element => {
  const { classes } = props;
  const device = useDevice();
  const isSmallScreen = device && device.atMost('desktopLarge');
  const imgSrc = isSmallScreen ? '/assets/img/vivus-popover-mobile.png' : '/assets/img/vivus-popover.png';

  return (
    <a href={URL} className={classes.root} target={'_blank'} rel={'noopener noreferrer'}>
      <Background
        height={'100%'}
        src={imgSrc}
        isPopover
      />
    </a>
  );
};

VivusPopover.styleRoot = 'VivusPopover';

export default injectStyles(styles)(VivusPopover);
