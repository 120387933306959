import cast from 'castit';
import Persistence from '../../persistence/Persistence';

export default function reviveStateFromPersistence(appState, persistenceStore) {
  const persistence = new Persistence({}).toSeq().reduce((previous, value, key) => ({ ...previous,
    [key]: cast(persistenceStore.get(key) || value),
  }), {});

  return { ...appState,
    persistence,
  };
}
