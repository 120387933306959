import { StylesheetParams } from '@common/types';

export default ({ colors, mediaQueries, sizes }: StylesheetParams) => ({
  panel: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: '20px auto 40px',
    background: colors.white,
    padding: '45px 40px',
    borderRadius: '7px',
    maxWidth: '500px',
    maxHeight: '422px',
    border: '3px solid #fd3c4f',
    [mediaQueries.breakpointTablet]: {
      maxWidth: '278px',
      padding: `46px ${sizes.gutterWidth}px 0`,
      maxHeight: 'unset',
      marginBottom: '50px',
    },
  },

  outerContainer: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  heading: {
    fontSize: '36px',
    lineHeight: '40px',
    margin: 'auto',
    marginTop: '50px',
    maxWidth: '600px',

    [mediaQueries.breakpointTablet]: {
      fontSize: '25px',
      lineHeight: '33px',
      marginBottom: '40px',
    },
  },

  subheading: {
    fontSize: '20px',
    fontWeight: 500,
    margin: '0',
    marginBottom: '20px',
    textAlign: 'center',
    color: colors.rejectRed,

    [mediaQueries.breakpointTablet]: {
      fontSize: '16px',
      marginTop: '15px',
    },
  },

  note: {
    fontSize: '14px',
    margin: '35px 0 20px',
    textAlign: 'center',
    weight: 400,
  },

  noteButton: {
    fontSize: '14px',
    fontWeight: 'bold',
    '& > * > *, span': {
      color: colors.rejectRed,
    },
  },

  svg: {
    height: '30px',
    width: '31px',
    marginRight: '20px',
  },

  icon: {
    position: 'absolute',
    top: '-31px',
    left: '218px',
    width: '59px',

    [mediaQueries.breakpointTablet]: {
      left: '105px',
    },
  },

  detailPanel: {
    background: colors.errorLight,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    padding: '20px',
    [mediaQueries.breakpointTablet]: {
      padding: '12px',
    },
  },

  detailsText: {
    [mediaQueries.breakpointTablet]: {
      width: '150px',
    },
  },

  textBeforeButton: {
    textAlign: 'center',
    fontSize: '17px',
    fontWeight: 500,
    margin: '20px 0',
    [mediaQueries.breakpointTablet]: {
      fontSize: '14px',
    },
  },

  buttonWrapper: {
    textAlign: 'center',

    [mediaQueries.breakpointTablet]: {
      margin: '20px 0 40px 0',
    },
  },
});
