import { useCallback } from 'react';
import useTranslator from '../../useTranslator';

export const Provider = ({
  children,
}) => children;
export const reducer = () => ({});
export const actions = {
  updateMessages: () => ({
    type: '__none__',
  }),
};
export default function translate(Component) {
  return props => {
    const formatters = useTranslator();
    const msg = useCallback((...args) => {
      const [keys, ...otherArgs] = args;

      return formatters.msg([].concat(keys).map(k => `passwordRecovery.${k}`), ...otherArgs);
    }, []);
    const cnt = useCallback((...args) => (
      <span dangerouslySetInnerHTML={{
        __html: msg(...args),
      }}
      />
    ), []);

    return <Component {...props} {...formatters} msg={msg} cnt={cnt} />;
  };
}
