import { actions as apiActions, reducer as apiReducer } from '4finance-zaplopl-api';
import { PromiseType } from 'utility-types';

export function fetchClientApplication() {
  return ({ httpGet }: PromiseType<Promise<Record<string, any>>>): Record<string, any> => ({
    type: 'FETCH_CLIENT_APPLICATION',
    payload: httpGet(
      '/client/application',
      {},
      {
        Accept: 'application/json',
      },
    ),
  });
}
export function fetchApplicationMonthlyPayment(amount: number, term: number): Record<string, any> {
  return ({
    httpGet,
  }: PromiseType<Promise<Record<string, any>>>) => ({
    type: 'FETCH_APPLICATION_MONTHLY_PAYMENT',
    meta: {
      amount,
      term,
    },
    payload: httpGet('/application/monthly-payment', {
      amount,
      term,
    }, {
      Accept: 'text/plain',
    }),
  });
}
export function fetchClientApplicationMonthlyPayment(amount: number, term: number): Record<string, any> {
  return ({
    httpGet,
  }: PromiseType<Promise<Record<string, any>>>) => ({
    type: 'FETCH_CLIENT_APPLICATION_MONTHLY_PAYMENT',
    meta: {
      amount,
      term,
    },
    payload: httpGet('/client/application/monthly-payment', {
      amount,
      term,
    }, {
      Accept: 'text/plain',
    }),
  });
}
export function patchClientApplication(params) {
  return ({
    httpPut,
  }: PromiseType<Promise<Record<string, any>>>): Record<string, any> => ({
    type: 'PATCH_CLIENT_APPLICATION',
    meta: {
      params,
    },
    payload: httpPut('/client/application', params),
  });
}

export const reducer = apiReducer;
export const actions = { ...apiActions,
  fetchApplicationMonthlyPayment,
  fetchClientApplication,
  fetchClientApplicationMonthlyPayment,
  patchClientApplication,
};
export default {
  actions,
  reducer,
};
