
import { compose } from 'redux';
import { connectState } from '../../../../connect';
import Message from './Message';
import type { ImmutableSet } from '../../../../../common/lib/types';

type Props = {
  messages: ImmutableSet;
};

const Messages = (props: Props): JSX.Element => {
  const {
    messages,
  } = props;

  return messages.map(m => <Message key={m} value={m} />);
};

Messages.styleRoot = 'Messages';
export default compose<any>(connectState({
  messages: ['settings', 'messages'],
}))(Messages);
