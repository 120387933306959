import * as R from '../../../routes/routesList';
import type { ContentNavigation, ContentNavigationHyperlink } from '../../../lib/content/types';

export default function getCurrentLinks(content: ContentNavigation, mobile: boolean, isSignedIn: boolean, isAdditionalAmountAvailable: boolean | null | undefined): ContentNavigationHyperlink[] {
  const {
    hyperlinks,
    authorizedHyperlinks,
    loginButtonText,
    logoutButtonText,
  } = content.fields;
  let links = isSignedIn ? authorizedHyperlinks : hyperlinks;

  if (!isAdditionalAmountAvailable) {
    links = links.filter((l: ContentNavigationHyperlink) => l.fields.flag !== 'ADDITIONAL_AMOUNT');
  }

  if (mobile) {
    const fields = isSignedIn ? {
      url: R.LOGOUT,
      title: logoutButtonText,
      external: true,
    } : {
      url: R.LOGIN,
      title: loginButtonText,
    };

    return links.concat([{
      fields,
    }]);
  }

  return links;
}
