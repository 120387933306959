
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import styles from './Separator.jss';

type Props = {
  classes: Record<string, any>;
  className?: string;
  centered?: boolean;
};

const Separator = (props: Props): JSX.Element => {
  const {
    classes,
    className,
    centered,
  } = props;

  return <div className={classnames(classes.separator, centered && classes.centered, className)} />;
};

Separator.styleRoot = 'Separator';
export default injectStyles(styles)(Separator);
