import * as Fields from '../../fields';
import * as R from '@routes/routesList';

import { BodyText, Hyperlink } from '@shared/components/typography';
import { compose } from 'redux';
import { connectActions, connectPrefetch, connectState } from '@browser/connect';
import { connectSubmit, Form, SetFormFieldProperty } from '4finance-onion-form-pl';
import { CONSENT_COLA_IDS } from '@common/lib/constants';
import { createRef, PureComponent } from 'react';
import { Section } from '@shared/components/layout';
import AcceptInformationFormConsent from '@shared/components/AcceptInformationFormConsent';
import Button from '@shared/components/Button';
import classnames from 'classnames';
import Consents from '@dynamic/components/Consents';
import ContainerWithCalculator from '../ContainerWithCalculator';
import getCaptchaKey from '@browser/lib/getCaptchaKey';
import injectStyles from '4finance-components-pl';
import QA_CLASSES from '@browser/lib/qa';
import RandomFill from '../RandomFill/RandomFill';
import Reaptcha from 'reaptcha';
import styles from './Register.jss';
import translate from '@localizations/translate';
import VivusButton from './VivusButton';
import type { ImmutableMap } from '@common/lib/types';

const Submit = connectSubmit(Button);

type Props = {
  classes: Record<string, any>;
  msg: (...args: Array<any>) => any;
  submitRegistration: (...args: Array<any>) => any;
  setAmount: (...args: Array<any>) => any;
  setMonthlyPayment: (...args: Array<any>) => any;
  setRecaptchaResponseToken: (...args: Array<any>) => any;
  setFormFieldProperty: SetFormFieldProperty;
  countries: ImmutableMap | null | undefined;
  declarationWarning: boolean;
};
type State = {
  isCaptchaRendered: boolean;
  values: Record<string, any>;
};

class Register extends PureComponent<Props, State> {
  // eslint-disable-next-line react/sort-comp
  static styleRoot = 'Register';

  constructor(props: Props) {
    super(props);
    this.state = {
      isCaptchaRendered: false,
      values: {},
    };
  }

  captcha: any = null;

  formName = 'registration';

  componentDidMount() {
    const {
      setAmount,
      setMonthlyPayment,
    } = this.props;
    const params = new URLSearchParams(window.location.search);
    const amount = params.get('amount');
    const monthlyPayment = params.get('monthlyPayment');

    if (amount) {
      setAmount(parseFloat(amount));
    }

    if (monthlyPayment) {
      setMonthlyPayment(parseFloat(monthlyPayment));
    }

    window.history.replaceState({}, document.title, R.REGISTER);
  }

  executeCaptcha = () => this.captcha.execute();
  onCaptchaRender = () => this.setState({
    isCaptchaRendered: true,
  });
  onCaptchaVerify = async (responseToken: string) => {
    const {
      setRecaptchaResponseToken,
      submitRegistration,
    } = this.props;
    const {
      values,
    } = this.state;
    const phoneFix = this.textFieldLayoutFix();
    const personalIdFix = this.textFieldLayoutFix();

    setRecaptchaResponseToken(responseToken);
    await submitRegistration(values);
    phoneFix.onAfterChange();
    personalIdFix.onAfterChange();
    this.captcha.reset();
  };

  onCreateEmailConfirmRef = (el: HTMLInputElement) => {
    el?.addEventListener?.('paste', (e: Event) => e.preventDefault());
  };
  onSubmit = ({
    values,
  }: {
    values: Record<string, any>;
  }) => {
    const { declarationWarning, setFormFieldProperty } = this.props;

    if (!values.declaration && !declarationWarning) {
      setFormFieldProperty(this.formName, 'declaration', 'warning', true);

      return;
    }
    this.setState({
      values,
    }, () => this.executeCaptcha());
  };

  textFieldLayoutFix() {
    const ref = createRef();

    const onRef = (el: HTMLInputElement) => {
      ref.current = el;
    };

    const onAfterChange = () => {
      ref.current?.focus?.();
      ref.current?.blur?.();
    };

    return {
      onRef,
      onAfterChange,
    };
  }

  renderConsentContentWithSchedule(scheduleContext: 'application' | 'proposal') {
    return (colaId: string, text: string) => {
      if (colaId !== CONSENT_COLA_IDS.FI_PEP_DECLARATION_READ || text.indexOf('%SCHEDULE%') === -1 || text.indexOf('%AMOUNT%') === -1 || text.indexOf('%TERM%') === -1) {
        return (
          <span dangerouslySetInnerHTML={{
            __html: text,
          }}
          />
        );
      }

      return <AcceptInformationFormConsent text={text} context={scheduleContext || 'calculator'} />;
    };
  }

  render() {
    const {
      classes,
      countries,
      msg,
    } = this.props;
    const {
      isCaptchaRendered,
    } = this.state;
    const renderConsentContent = this.renderConsentContentWithSchedule();
    const phoneFix = this.textFieldLayoutFix();
    const personalIdFix = this.textFieldLayoutFix();
    const idCardFix = this.textFieldLayoutFix();

    return (
      <Section title={msg('registration.register.title')} gray separator>
        <ContainerWithCalculator>
          <BodyText className={classes.heading}>
            {msg('registration.register.heading.text1')}
            <Hyperlink to={R.LOGIN} className={classes.loginLink}>
              {msg('registration.register.heading.login_link')}
            </Hyperlink>
            {msg('registration.register.heading.text2')}
          </BodyText>

          <Form name={this.formName} onSubmit={this.onSubmit}>
            <RandomFill />
            <Fields.FirstName bottomMargin={false} />
            <Fields.LastName topMargin={false} />

            <Fields.PersonalId bottomMargin={false} inputRef={personalIdFix.onRef} />
            <Fields.BirthCountry
              options={countries?.toJS?.() || []}
              defaultValue={'Polska'}
              translateOptions={false}
              FieldContainerProps={{
                topMargin: false,
              }}
            />

            <Fields.MobilePhone inputRef={phoneFix.onRef} />

            <Fields.IdentityCardNumber inputRef={idCardFix.onRef} clearApiErrorOnChange />

            <Fields.StreetAndNumber bottomMargin={false} />
            <Fields.PostalCode bottomMargin={false} topMargin={false} />
            <Fields.City topMargin={false} />

            <Fields.Email bottomMargin={false} />
            <Fields.ConfirmEmail topMargin={false} inputRef={this.onCreateEmailConfirmRef} />

            <Fields.PasswordStrict />
            <Fields.IoBlackBox />

            <Consents setName={'Registration'} formName={'registration'} renderContent={renderConsentContent} />

            <div className={classes.buttonsContainer}>
              <Submit className={classnames(classes.submit, QA_CLASSES.REGISTRATION_NEXT_STEP)} disabled={!isCaptchaRendered}>
                <span dangerouslySetInnerHTML={{
                  __html: msg('registration.register.submit'),
                }}
                />
              </Submit>
              <Reaptcha
                ref={e => {
                  this.captcha = e;
                }}
                sitekey={getCaptchaKey()}
                onVerify={this.onCaptchaVerify}
                onRender={this.onCaptchaRender}
                size="invisible"
                badge="bottomleft"
              />
              <VivusButton />
            </div>
          </Form>
        </ContainerWithCalculator>
      </Section>
    );
  }
}

export default compose<any>(connectPrefetch([['api', 'fetchDictionaryCountries']]), connectActions({
  submitRegistration: ['registration', 'submitRegistration'],
  setAmount: ['calculator', 'setAmount'],
  setMonthlyPayment: ['calculator', 'setMonthlyPayment'],
  setRecaptchaResponseToken: ['registration', 'setRecaptchaResponseToken'],
  setFormFieldProperty: ['onionForm', 'setFormFieldProperty'],
}), connectState({
  countries: ['api', 'fetch', 'dictionary', 'countries', 'query', 'data'],
  declarationWarning: ['onionForm', 'fields', 'registration', 'declaration', 'warning'],
}), translate, injectStyles(styles))(Register);
