export const EMAIL = 'email';
export const PHONE = 'phone';
export const PASSWORD = 'password';
export const ADDRESS = 'address';
export const ID = 'identity_card_number';
export const NAME = 'name';
export const BANK_ACCOUNT = 'bank_account';
// Event names
export const RESIZE = 'resize';

export const FS_AUTHENTICATED = 'Client is authenticated';
export const FS_APPLIED_LOAN = 'Applied for a loan';

// Loan Application
export const PERSONAL_ID_RESTRICTED = 'PERSONAL_ID_RESTRICTED';

export const DEVICES = {
  phone: 480,
  tablet: 769,
  desktop: 992,
  desktopLarge: 1200,
  large: 5000,
};

export const CONSENT_COLA_IDS = {
  FI_PEP_DECLARATION_READ: 'FI_PEP_DECLARATION_READ',
  BIG_POSITIVE_POWER_OF_ATTORNEY: 'BIG_POSITIVE_POWER_OF_ATTORNEY',
  BIK_POWER_OF_ATTORNEY: 'BIK_POWER_OF_ATTORNEY',
  BIK_INQUIRIES_CHECK: 'BIK_INQUIRIES_CHECK',
  SWO_POWER_OF_ATTORNEY: 'SWO_POWER_OF_ATTORNEY',
  ACCEPT_DATA_SHARING: 'ACCEPT_DATA_SHARING',
  ACCEPT_INTERNAL_DATA_SHARING: 'ACCEPT_INTERNAL_DATA_SHARING',
  ACCEPT_NEWS: 'ACCEPT_NEWS',
  ACCEPT_MARKETING: 'ACCEPT_MARKETING',
  CWA_DECLARATION: 'CWA_DECLARATION',
};

export const TEMPORARY_CONSENTS = {
  acceptAgreement: true,
  acceptBikSharingConsent: true,
  acceptInformationFormRead: true,
};

export const CONSENT_PROPERTY_NAMES_BY_COLA_ID = {
  [CONSENT_COLA_IDS.FI_PEP_DECLARATION_READ]: 'acceptFiPepDeclarationRead',
  [CONSENT_COLA_IDS.BIG_POSITIVE_POWER_OF_ATTORNEY]: 'acceptBigConsent',
  [CONSENT_COLA_IDS.BIK_INQUIRIES_CHECK]: 'acceptBikInquiriesCheck',
  [CONSENT_COLA_IDS.BIK_POWER_OF_ATTORNEY]: 'acceptBikConsent',
  [CONSENT_COLA_IDS.SWO_POWER_OF_ATTORNEY]: 'acceptSwoConsent',
  [CONSENT_COLA_IDS.ACCEPT_DATA_SHARING]: 'acceptDataSharing',
  [CONSENT_COLA_IDS.ACCEPT_INTERNAL_DATA_SHARING]: 'acceptInternalDataSharing',
  [CONSENT_COLA_IDS.ACCEPT_NEWS]: 'acceptNews',
  [CONSENT_COLA_IDS.ACCEPT_MARKETING]: 'acceptMarketing',
  [CONSENT_COLA_IDS.CWA_DECLARATION]: 'cwaDeclaration',
};

export const CWA_DECLARATION = {
  name: 'NO_180_DAYS_DEBT',
  type: 'declaration',
  version: undefined,
  required: false,
} as const;

export const EXTENSIBLE_STATUSES = ['FULL_TIME', 'PART_TIME', 'MANDATE_CONTRACT', 'SELF_EMPLOYED'];

export const MARKETING_CONSENTS = ['ACCEPT_DATA_SHARING', 'ACCEPT_INTERNAL_DATA_SHARING', 'ACCEPT_NEWS'];

// mapping for id document errors: some errors will come with property names: identityDocument, documentNumber
// they both need to ascribed to identityDocumentNumber inputs
export const ID_CARD_NUMBER = 'identityCardNumber';
export const ID_CARD_ERROR_MAPPING = { documentNumber: ID_CARD_NUMBER, identityDocument: ID_CARD_NUMBER };
