declare global {
  interface Window {
    dataLayer: Record<string, any>;
  }
}

export type GTMEvent = {
  event?: string;
  status?: string;
  eventLabel?: string;
  eventAction?: string;
  dateOfBirth?: number;
};
export default function sendGTMEvent(e: GTMEvent): void {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(e);
  }
}
