
import { compose } from 'redux';
import { MODALS } from '../../../../routes/Modals';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import Edit from '../../../../svg/uEA0A-edit.svg';
import injectStyles from '4finance-components-pl';
import styles from './EditableRow.jss';

type Props = {
  classes: Record<string, any>;
  // Own props
  title: string;
  value: string;
  editable: boolean;
  buttonClass?: string;
  valueClass?: string;
  pathname: string;
};

const EditableRow = (props: Props): JSX.Element => {
  const {
    classes,
    title,
    value,
    buttonClass,
    valueClass,
    editable = true,
    pathname,
  } = props;
  const history = useHistory();
  const buttonClasses = classnames(classes.button, buttonClass);
  const linkTo: string | Record<string, any> = MODALS[pathname] ? {
    pathname,
    state: {
      modal: true,
      type: title,
    },
  } : pathname;

  const onButtonClick = () => history.push(linkTo);

  return (
    <div className={classnames(classes.container)}>
      <div className={classes.content}>
        <>
          <div>
            <div className={classes.title}>
              {title}
            </div>
            <div className={classnames(classes.value, valueClass)}>
              {value}
            </div>
          </div>
        </>
      </div>
      {editable ? (
        <button type="button" className={buttonClasses} onClick={onButtonClick}>
          <Edit />
        </button>
      ) : <div className={classes.flexFill} />}
    </div>
  );
};

EditableRow.styleRoot = 'EditableRow';
export default compose<any>(injectStyles(styles))(EditableRow);
