
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import QA_CLASSES from '../../../lib/qa';
import styles from './CloseButton.jss';

type Props = HTMLButtonElement & {
  classes: Record<string, any>;
  className?: string;
  onClick: (...args: Array<any>) => any;
};

const CloseButton = (props: Props): JSX.Element => {
  const {
    classes,
    className,
    onClick,
    ...buttonProps
  } = props;

  return (
    <button type="button" className={classnames(classes.root, QA_CLASSES.CLOSE_BUTTON, className)} onClick={onClick} {...buttonProps}>
      &times;
    </button>
  );
};

CloseButton.styleRoot = 'CloseButton';
export default injectStyles(styles)(CloseButton);
