
import classnames from 'classnames';
import ContextAwareLink from '../../components/ContextAwareLink';
import injectStyles from '4finance-components-pl';
import styles from './LoginButton.jss';
import useDevice from '../../../lib/hooks/useDevice';

type Props = {
  classes: Record<string, any>;
  text: string;
  yellow: boolean;
  onClick: (...args: Array<any>) => any;
};

const LoginButton = (props: Props): JSX.Element => {
  const {
    classes,
    text,
    yellow,
    onClick,
  } = props;
  const device = useDevice();
  const atMostTablet = device && device.atMost('tablet');

  return (
    <ContextAwareLink to={atMostTablet ? '/login' : null} destinationRouteType={'dynamic'}>
      <button
        type="button"
        className={classnames(classes.button, {
          yellow,
        })}
        onClick={onClick}
      >
        {text}
      </button>
    </ContextAwareLink>
  );
};

LoginButton.styleRoot = 'LoginButton';
export default injectStyles(styles)(LoginButton);
