
import DetailsRow from '../DetailsRow';
import injectStyles from '4finance-components-pl';
import styles from './ChangesRow.jss';

type Props = {
  classes: Record<string, any>;
  titles: [string, string];
  values: [string, string];
};

const ChangesRow = (props: Props): JSX.Element => {
  const {
    classes,
    titles,
    values,
  } = props;

  return (
    <div className={classes.root}>
      <DetailsRow title={titles[0]} value={values[0]} />
      <DetailsRow title={titles[1]} value={values[1]} bold />
    </div>
  );
};

ChangesRow.styleRoot = 'ChangesRow';
export default injectStyles(styles)(ChangesRow);
