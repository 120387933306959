
import Helmet from 'react-helmet';
import type { ContentMeta } from '../types';

type Props = {
  content: {
    fields: {
      meta?: ContentMeta;
    };
  } | null | undefined;
};

const Meta = (props: Props): JSX.Element => {
  const {
    content,
  } = props;
  const meta = content ? content.fields.meta : null;

  if (!meta) {
    return null;
  }

  const {
    title,
    description,
    keywords,
  } = meta.fields;

  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name={'description'} content={description} />}
      {keywords && <meta name={'keywords'} content={keywords} />}
    </Helmet>
  );
};

export default Meta;
