export default (): any => ({
  container: {
    width: '100%',
  },
  footnote: {
    fontSize: '11px',
    marginTop: '-6px',
    opacity: 0.7,
    '& span': {
      fontSize: 'inherit',
    },
  },
  lastRow: {
    '& > div span': {
      fontWeight: 600,
    },
  },
});
