import dayjs from 'dayjs';

export const MINIMUM_AGE = 19;
export const MAXIMUM_AGE = 78;
export const WEIGHTS = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
const minBirthDate = dayjs().subtract(MINIMUM_AGE, 'years').format('YYYY-MM-DD');
const maxBirthDate = dayjs().subtract(MAXIMUM_AGE, 'years').format('YYYY-MM-DD');

export default function personalId() {
  const birth = faker.date.between(minBirthDate, maxBirthDate);
  const result = dayjs(birth).format('YYMMDD') + faker.random.number({
    min: 1000,
    max: 9990,
  });

  return result + getPeselChecksum(result);
}

function getPeselChecksum(str) {
  const sum = WEIGHTS.reduce((acc, weight, i) => acc + parseInt(str[i], 10) * weight, 0);

  return (10 - (sum % 10)) % 10;
}
