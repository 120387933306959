
import { Checkbox } from '4finance-components-pl';
import { compose } from 'redux';
import { connectActions, connectState } from '../../../../connect';
import { CONSENT_PROPERTY_NAMES_BY_COLA_ID } from '../../../../../common/lib/constants';
import { useCallback } from 'react';
import QA_CLASSES from '../../../../lib/qa';
import type { ContentConsentStatement } from '../../../../lib/content/types';
import type { ImmutableMap } from '../../../../../common/lib/types';

type Props = {
  clientConsents: ImmutableMap;
  sendChangeConsent: (...args: Array<any>) => any;
  // Own props
  content: ContentConsentStatement;
};

function isAccepted(consents: Record<string, any>[], colaId: string) {
  return !!consents.find(({
    type,
    status,
  }) => type === colaId && status === 'GRANTED');
}

const Consent = (props: Props): JSX.Element => {
  const {
    content,
    clientConsents,
    sendChangeConsent,
  } = props;
  const {
    text,
    colaConsentId,
    textLong,
  } = content.fields;
  const consents = clientConsents ? clientConsents.toJS() : [];
  const consentText = textLong ? `${text} ${textLong}` : text;
  const onChange = useCallback(({
    name,
    value,
  }: Record<string, any>) => {
    sendChangeConsent(name, value);
  }, []);

  return (
    <Checkbox name={colaConsentId} value={isAccepted(consents, colaConsentId)} onChange={onChange} className={`${QA_CLASSES.PREFIX}${CONSENT_PROPERTY_NAMES_BY_COLA_ID[colaConsentId]}`} isExpandable={!!textLong} clippingHeight={40}>
      <span dangerouslySetInnerHTML={{
        __html: consentText,
      }}
      />
    </Checkbox>
  );
};

export default compose<any>(connectActions({
  sendChangeConsent: ['settings', 'sendChangeConsent'],
}), connectState({
  clientConsents: ['api', 'fetch', 'client', 'consents', 'query', 'data', 'consents'],
}))(Consent);
