export default (({ mediaQueries }) => ({
  secondaryHeading: {
    margin: '40px 0',
  },
  loader: {
    margin: '16vh auto',

    [mediaQueries.breakpointSmall]: {
      margin: '80px auto',
    },
  },
  recaptchaWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
}));
