export default ({ colors, mediaQueries }) => ({
  loading: {
    margin: '32px 0',
  },
  listContainer: {
    padding: '16px 0 32px 0',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    minHeight: '215px',
    [mediaQueries.breakpointTablet]: {
      height: '30vh',
    },
    [mediaQueries.breakpointSmall]: {
      height: 'unset',
    },
  },
  separator: {
    height: '1px',
    backgroundColor: colors.gray,
    margin: '16px 0',
  },
  message: {
    marginTop: '3px',
  },
});
