
import { compose } from 'redux';
import { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import injectStyles, { SliderWithControls } from '4finance-components-pl';
import QA_CLASSES from '../../../lib/qa';
import styles from './Sliders.jss';
import useContentContext from '../../../lib/content/useContentContext';
import type { ApplicationConstraints } from '../../requests/fetchConstraints';

export type Props = {
  classes: Record<string, any>;
  // Own props
  constraints: ApplicationConstraints;
  amount: number;
  term: number | null;
  onChangeAmount: (value: number) => void | Promise<any>;
  onChangeTerm: (value: number) => void | Promise<any>;
  onAfterChangeAmount: (value: number) => void | Promise<any>;
  onAfterChangeTerm: (value: number) => void | Promise<any>;
  transparent: boolean | null | undefined;
};

export const propNames = ['constraints', 'amount', 'term', 'monthlyPayment', 'onChangeAmount', 'onChangeTerm', 'onAfterChangeTerm', 'onChangeMonthlyPayment', 'transparent'];

const Sliders = (props: Props): JSX.Element => {
  const {
    classes,
    constraints,
    amount = 0,
    term = 0,
    onChangeAmount,
    onAfterChangeAmount,
    onChangeTerm,
    onAfterChangeTerm,
    transparent = false,
  } = props;
  const [sliderHidden, setSliderHidden] = useState(false);
  const { calculator: content } = useContentContext();
  const { principal, term: sliderTitleTerm } = content ? content.fields : {};
  const { termInterval } = constraints;

  useEffect(() => {
    const interval = getTermInterval();

    if (term && term > interval.max) {
      onChangeTerm(interval.max);
      onAfterChangeTerm ? onAfterChangeTerm(interval.max) : null;
    } else if (term && term < interval.min) {
      onChangeTerm(interval.min);
      onAfterChangeTerm ? onAfterChangeTerm(interval.min) : null;
    } else {
      onChangeTerm(term || interval.default);
      onAfterChangeTerm ? onAfterChangeTerm(interval.default) : null;
    }

    if (interval.max === interval.min) {
      setSliderHidden(true);
    } else {
      setSliderHidden(false);
    }
  }, [amount]);

  const formatter = useCallback((value: number, unit: string) => `${value} ${unit}`, []);

  const getTermSlider = () => {
    if (!sliderHidden) {
      return (
        <div className={classes.sliderContainer}>
          <SliderWithControls
            {...getTermInterval()}
            inputClass={classnames(classes.input, QA_CLASSES.MONTHLY_PAYMENT_INPUT)}
            sliderClass={QA_CLASSES.MONTHLY_PAYMENT_SLIDER}
            sliderHandlerClass={QA_CLASSES.MONTHLY_PAYMENT_HANDLER}
            formater={formatter}
            heading={sliderTitleTerm}
            value={term}
            kind={'term'}
            unit={'mies.'}
            onAfterChange={onAfterChangeTerm}
            onChange={onChangeTerm}
            onInputChange={onChangeTerm}
            shakySteps
          />
        </div>
      );
    }

    return (
      <div className={classnames(classes.termContainer, { transparent })}>
        {`Pożyczka na ${termInterval.min} miesiące`}
      </div>
    );
  };

  const getTermInterval = (): Record<string, any> => {
    const termLimits = constraints.termLimits?.filter(lim => lim.amountFrom <= amount && amount <= lim.amountTo)[0];

    return {
      min: termLimits ? termLimits.termFrom : constraints.termInterval.min,
      max: termLimits ? termLimits.termTo : constraints.termInterval.max,
      defaultValue: termLimits ? termLimits.termTo : constraints.termInterval.defaultValue,
    };
  };

  return (
    <div className={QA_CLASSES.CONTROLS_CONTAINER}>
      <div className={classes.sliderContainer}>
        <SliderWithControls
          {...constraints.amountInterval}
          inputClass={classnames(classes.input, QA_CLASSES.AMOUNT_INPUT)}
          sliderClass={QA_CLASSES.AMOUNT_SLIDER}
          sliderHandlerClass={QA_CLASSES.AMOUNT_HANDLER}
          formater={formatter}
          heading={principal}
          value={amount}
          kind={'amount'}
          unit={'zł'}
          onAfterChange={onAfterChangeAmount}
          onChange={onChangeAmount}
          onInputChange={onChangeAmount}
          shakySteps
        />
      </div>
      {getTermSlider()}
    </div>
  );
};

Sliders.styleRoot = 'Sliders';

export default compose<any>(
  injectStyles(styles),
)(Sliders);
