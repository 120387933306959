
import { pick } from 'lodash';
import classnames from 'classnames';
import ContextAwareLink from '../ContextAwareLink';
import injectStyles, { Button as FourFinanceButton } from '4finance-components-pl';
import styles from './Button.jss';
import type { Props as ContextAwareLinkProps } from '../ContextAwareLink';

type FinanceButtonProps = {
  onClick?: (...args: Array<any>) => any;
};
export type Props = FinanceButtonProps & Partial<ContextAwareLinkProps> & {
  classes: Record<string, any>;
  children: React.ReactChildren;
  // Own props
  className?: string;
  fullWidth?: boolean;
  secondary?: boolean;
};

const Button = (props: Props): JSX.Element => {
  const {
    classes,
    children,
    className,
    fullWidth,
    secondary,
    ...buttonProps
  } = props;
  const linkProps = pick(props, ['to', 'external', 'destinationRouteType', 'target']);

  linkProps.to = linkProps.to || null;

  return (
    <ContextAwareLink {...linkProps}>
      <FourFinanceButton
        className={classnames(classes.root, {
          [className || '']: className,
          fullWidth,
          secondary,
        })}
        {...buttonProps}
      >
        {children}
      </FourFinanceButton>
    </ContextAwareLink>
  );
};

Button.styleRoot = 'Button';
export default injectStyles(styles)(Button);
