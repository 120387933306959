export default ({ colors }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.gray}`,
    padding: '8px 0',
    fontSize: '12px',
    '&.bold': {
      fontWeight: 600,
      fontSize: '14px',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
});
