export default ({ colors }) => ({
  button: {
    fontSize: '14px',
    color: colors.primary,
    borderColor: colors.primary,
    borderRadius: '4px',
    backgroundColor: 'transparent',
    transition: 'color 0.3s, background-color 0.3s, border-color 0.3s',
    borderWidth: '2px',
    borderStyle: 'solid',
    fontWeight: 600,
    padding: '8px 8px 6px',
    marginLeft: '16px',
    lineHeight: 1,
    cursor: 'pointer',
    '&:hover': {
      color: `${colors.white} !important`,
      backgroundColor: `${colors.primary} !important`,
      borderColor: `${colors.primary} !important`,
    },
    '&.yellow': {
      borderColor: colors.yellow,
      color: colors.yellow,
    },
  },
});
