import * as R from '@routes/routesList';
import { ReactChildren, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import ContextAwareLink from '../../components/ContextAwareLink';
import getCurrentLinks from './getCurrentLinks';
import injectStyles, { Container, IconMenu } from '4finance-components-pl';
import LoginButton from './LoginButton';
import Logo from '../../components/Logo';
import NavigationLink from './NavigationLink';
import QA_CLASSES from '@browser/lib/qa';
import styles from './StaticNavigation.jss';
import useDevice from '@browser/lib/hooks/useDevice';
import useSSRPathname from '@browser/lib/hooks/useSSRPathname';
import type { ContentNavigation, ContentNavigationHyperlink } from '../../../lib/content/types';

type Props = {
  classes: Record<string, any>;
  children: ReactChildren;
  // Own props
  content: ContentNavigation;
  navShadow?: boolean;
  linksHidden?: boolean;
  logoWhiteStripes?: boolean;
  inverseLoginColor?: boolean;
  noTopPadding?: boolean;
  whiteNav: boolean;
  onOpenLogin: (...args: Array<any>) => any;
  logoUrl?: string;
  isLoggedIn: boolean;
  isAdditionalAmountAvailable?: boolean;
};

const StaticNavigation = (props: Props) => {
  const {
    classes,
    content,
    children,
    navShadow,
    linksHidden,
    logoWhiteStripes,
    whiteNav,
    inverseLoginColor,
    noTopPadding,
    onOpenLogin,
    logoUrl,
    isLoggedIn,
    isAdditionalAmountAvailable,
  } = props;
  const {
    loginButtonText,
    logoutButtonText,
  } = content.fields;
  const [menuOpen, setMenuOpen] = useState(false);
  const ssrPathname = useSSRPathname();
  const pathname = typeof window !== 'undefined' ? window.location.pathname : ssrPathname;
  const device = useDevice();
  const isMobile = device && device.atMost('desktop');
  const open = isMobile && menuOpen;
  const toggleMenu = useCallback(() => {
    setMenuOpen(o => !o);
  }, []);
  const renderLinks = useCallback((mobile = false) => {
    const links = getCurrentLinks(content, mobile, isLoggedIn, isAdditionalAmountAvailable);

    return links.map((h: ContentNavigationHyperlink, index: number) => <NavigationLink active={pathname.startsWith(h.fields.url)} mobile={mobile} key={index} {...(h.fields as ContentNavigationHyperlink['fields'])} />);
  }, [isLoggedIn, pathname]);

  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  return (
    <div className={classes.outerWrapper}>
      <div className={classnames(classes.wrapper, {
        open,
      })}
      >
        <header className={classnames(classes.outerContainer, QA_CLASSES.HEADER, 'nav-container', {
          shadow: navShadow,
        })}
        >
          <Container className={classnames(classes.container, {
            white: whiteNav,
          })}
          >
            <ContextAwareLink className={classnames(classes.logoContainer, QA_CLASSES.LOGO)} to={logoUrl || R.ROOT}>
              <Logo whiteStripes={logoWhiteStripes || false} />
            </ContextAwareLink>
            {!linksHidden && (!isMobile ? (
              <div className={classnames(classes.linksContainer, QA_CLASSES.HEADER_MENU)}>
                {renderLinks()}
                {isLoggedIn ? <NavigationLink url={R.LOGOUT} title={logoutButtonText} external /> : <LoginButton text={loginButtonText} yellow={inverseLoginColor} onClick={onOpenLogin} />}
              </div>
            ) : <IconMenu color={'white'} className={classnames(classes.menuIcon, QA_CLASSES.MENU_ICON_MOBILE)} onClick={toggleMenu} />)}
          </Container>
          {isMobile && !linksHidden && (
          <div className={classnames(classes.mobileMenu, 'nav-menu')}>
            {renderLinks(true)}
          </div>
          )}
        </header>
        <div className={classnames(classes.contentContainer, {
          noTopPadding,
        })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

StaticNavigation.styleRoot = 'StaticNavigation';
export default injectStyles(styles)(StaticNavigation);
