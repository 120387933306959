import config from '4finance-configuration-pl';

type GACookie = {
  name: string;
  value: string;
}

const isNotProduction = config.environment !== 'production';

export default function getAnalyticsCookies(persistenceStore: any): GACookie[] | [] {
  const analyticsCookies = [];
  const ga = persistenceStore.get('_ga');

  if (isNotProduction) {
    console.log('initial ga cookie', { ga });
  }

  if (ga) {
    analyticsCookies.push({
      name: 'ga',
      value: ga,
    });
  }

  const measurementId = config.googleMeasurementId;

  if (!measurementId) {
    return analyticsCookies;
  }

  if (isNotProduction) {
    console.log('measurementId', measurementId);
  }

  const [, secondPart] = measurementId.split('-');

  if (!secondPart) {
    return analyticsCookies;
  }

  const ga4 = persistenceStore.get(`_ga_${secondPart}`);

  if (!ga4) {
    return analyticsCookies;
  }

  if (isNotProduction) {
    console.log('ga4 cookie', { ga4 });
  }

  analyticsCookies.push({
    name: `ga_${secondPart}`,
    value: persistenceStore.get(`_ga_${secondPart}`),
  });

  return analyticsCookies;
}
