
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import styles from './Container.jss';

type Props = {
  classes: { root: string };
  // Own props
  className?: string;
  children: React.ReactChildren;
};

const Container = (props: Props): JSX.Element => {
  const {
    classes,
    children,
    className = '',
  } = props;

  return (
    <div className={classnames(classes.root, className)}>
      {children}
    </div>
  );
};

Container.styleRoot = 'Container';
export default injectStyles(styles)(Container);
