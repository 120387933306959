import * as R from '../../../../routes/routesList';

import { compose } from 'redux';
import { connectActions, connectPending, connectState } from '../../../../connect';
import { connectSubmit, Form } from '4finance-onion-form-pl';
import { IdentityCardNumber } from '../../fields';
import { UPDATE_CLIENT_DATA } from '../../../../../common/settings/actions';
import { useHistory } from 'react-router-dom';
import { useRef, useState } from 'react';
import Button from '../../../../shared/components/Button';
import classnames from 'classnames';
import getCaptchaKey from '@browser/lib/getCaptchaKey';
import injectStyles from '4finance-components-pl';
import Modal from '../../../../shared/components/Modal';
import QA_CLASSES from '../../../../lib/qa';
import Reaptcha from 'reaptcha';
import styles from '../Change.jss';
import translate from '../../../../../localizations/translate';

type Props = {
  classes: Record<string, any>;
  error: string;
  msg: (...args: Array<any>) => any;
  pending: boolean;
  sendChangeId: (...args: Array<any>) => any;
  setSettingsRecaptchaResponseToken: (...args: Array<any>) => any;
  formValue: string;
  value: string;
};
const SubmitButton = connectSubmit(Button);
const FORM_NAME = 'changeIdentityCardNumber';

const ChangeIdentityCardNumber = (props: Props) => {
  const {
    classes,
    pending,
    value,
    formValue,
    error,
    msg,
    sendChangeId,
    setSettingsRecaptchaResponseToken,
  } = props;
  const history = useHistory();
  const [isCaptchaRendered, setIsCaptchaRendered] = useState(false);
  const captcha = useRef();

  const onSubmit = (): void => {
    executeCaptcha();
  };

  const executeCaptcha = () => captcha.current?.execute();

  const onCaptchaRender = () => setIsCaptchaRendered(true);

  const onCaptchaVerify = (responseToken: string) => {
    setSettingsRecaptchaResponseToken(responseToken);
    sendChangeId();
    captcha.current?.reset();
  };

  const goBack = () => history.push(R.CLIENT_SETTINGS);

  const text = {
    title: msg('dashboard.settings.change_identity_card_number.change.title'),
    description: msg('dashboard.settings.change_identity_card_number.change.description'),
    button: msg('dashboard.settings.change_identity_card_number.change.button'),
  };
  const buttonDisabled = error || pending || value === formValue;
  const buttonClasses = classnames(classes.submitButton, QA_CLASSES.CLIENT_IDENTITY_CARD_NUMBER_EDIT_MODAL_BUTTON, {
    disabled: buttonDisabled,
  });

  return (
    <Modal title={text.title} open onClose={goBack}>
      <div className={classes.formWrapper}>
        <div className={classes.separator} />
        <div className={classes.description}>
          {text.description}
        </div>
        <Form name={FORM_NAME} onSubmit={onSubmit} autocomplete={false} resetOnUnMount>
          <div className={classes.inputWrapper}>
            <IdentityCardNumber clearApiErrorOnChange />
          </div>
          <SubmitButton type="submit" fullWidth className={buttonClasses} disabled={buttonDisabled || !isCaptchaRendered} loading={pending}>
            {text.button}
          </SubmitButton>

          {error && <div className={classes.error}>{msg(`app.errors.${error}`)}</div>}
        </Form>
        <div className={classes.recaptchaWrapper}>
          <Reaptcha
            ref={captcha}
            sitekey={getCaptchaKey()}
            onVerify={onCaptchaVerify}
            onRender={onCaptchaRender}
            size="invisible"
            badge="inline"
          />
        </div>
      </div>
    </Modal>
  );
};

ChangeIdentityCardNumber.styleRoot = 'ChangeIdentityCardNumber';
export default compose<any>(connectActions({
  sendChangeId: ['settings', 'sendChangeId'],
  setSettingsRecaptchaResponseToken: ['settings', 'setSettingsRecaptchaResponseToken'],
}), connectPending({
  pending: UPDATE_CLIENT_DATA,
}), connectState({
  error: ['onionForm', 'fields', 'changeIdentityCardNumber', 'identityCardNumber', 'error'],
  formValue: ['onionForm', 'fields', 'changeIdentityCardNumber', 'identityCardNumber', 'value'],
  value: ['api', 'fetch', 'client', 'query', 'data', 'identityDocumentId'],
}), translate, injectStyles(styles))(ChangeIdentityCardNumber);
