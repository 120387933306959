export const ERROR = 'too_long';
export const ERROR_PERSONAL_ID = 'too_long_personal_id';
export default function validation(maxLength: number, error?: string) {
  const FINAL_ERROR = error ? getErrorMessage(error) : ERROR;

  return (value: string) => {
    if (value && value.length > maxLength) {
      return FINAL_ERROR;
    }

    return null;
  };
}

function getErrorMessage(error: string) {
  let finalError: string;

  switch (error) {
    case 'personal_id':
    {
      finalError = ERROR_PERSONAL_ID;
      break;
    }
  }

  return finalError;
}
