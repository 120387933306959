import { MAXIMUM_AGE, MINIMUM_AGE, WEIGHTS } from '../lib/faker/personalId';
import dayjs from 'dayjs';

const ERROR = 'invalid_personal_id_format';
const DATE_ERROR = 'invalid_date';
const TOO_OLD_ERROR = 'too_old';
const TOO_YOUNG_ERROR = 'too_young';
const DATE_FORMAT = 'YYYY/M/D';
const STRICT_PARSE = true;
const IS_NUMBER_REGEXP = /^\d+$/;

dayjs.parseTwoDigitYear = input => {
  const year = parseInt(input, 10);
  const boundYear = dayjs().format('YY');
  const baseYear = year <= boundYear && year >= 0 ? 2000 : 1900;

  return baseYear + year;
};

function getFormattedDate(pesel: string) {
  const yearPesel = parseInt(pesel.substring(0, 2), 10);
  const monthPesel = parseInt(pesel.substring(2, 4), 10);
  const dayPesel = parseInt(pesel.substring(4, 6), 10);

  if (monthPesel > 80) {
    return `${1800 + yearPesel}/${monthPesel - 80}/${dayPesel}`;
  }

  if (monthPesel >= 60) {
    return `${2200 + yearPesel}/${monthPesel - 60}/${dayPesel}`;
  }

  if (monthPesel >= 40) {
    return `${2100 + yearPesel}/${monthPesel - 40}/${dayPesel}`;
  }

  if (monthPesel >= 20) {
    return `${2000 + yearPesel}/${monthPesel - 20}/${dayPesel}`;
  }

  return `${1900 + yearPesel}/${monthPesel}/${dayPesel}`;
}

function validateBirthDate(pesel: string) {
  const dateOfBirthValue = dayjs(getFormattedDate(pesel), DATE_FORMAT, STRICT_PARSE);

  if (!dateOfBirthValue.isValid()) {
    return DATE_ERROR;
  }

  if (dateOfBirthValue < dayjs().subtract(MAXIMUM_AGE, 'years')) {
    return TOO_OLD_ERROR;
  }

  if (dateOfBirthValue > dayjs().subtract(MINIMUM_AGE, 'years')) {
    return TOO_YOUNG_ERROR;
  }

  return null;
}

function validatePesel(pesel: string) {
  let sum = 0;
  let i = 0;

  while (i < 10) {
    sum += WEIGHTS[i] * pesel[i];
    i++;
  }

  if (sum % 10 === 0) {
    sum = 0;
  } else {
    sum = 10 - (sum % 10);
  }

  return sum === parseInt(pesel[10], 10) ? null : ERROR;
}

export default function personalId() {
  return value => {
    if (!value) {
      return ERROR;
    }

    const pesel = value.replace('-', '');

    if (pesel.length !== 11 || !IS_NUMBER_REGEXP.test(pesel)) {
      return ERROR;
    }

    const birthDateError = validateBirthDate(pesel);

    if (birthDateError) {
      return birthDateError;
    }

    return validatePesel(pesel);
  };
}
