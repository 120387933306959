import { cnt, formatters, msg } from './lib';
import IntlMessageFormat from 'intl-messageformat';
import pl from './releases/pl.json';

type MsgArgs = [paths: string, values?: Record<string, string | number>]
type CntArgs = [paths: string, values?: Record<string, any>]

type Translator = typeof formatters & {
  msg: (...args: MsgArgs) => IntlMessageFormat | null | string;
  cnt: (...args: CntArgs) => JSX.Element;
};

const translator: Translator = { ...formatters,
  msg: (...args) => msg(pl, ...args),
  cnt: (...args) => cnt(pl, ...args),
};

export default function useTranslator(): Translator {
  return translator;
}
