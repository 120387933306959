
import useTranslator from './useTranslator';

export default function translate(Component: string | React.FunctionComponent<any> | React.ComponentClass<any, any>) {
  return (props: unknown): JSX.Element => {
    const formatters = useTranslator();

    return <Component {...props} {...formatters} />;
  };
}
