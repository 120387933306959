import * as R from '@browser/routes/routesList';

export default async function forbidOpenLoanApplicationWithNoBankAccountNumber({
  dispatch,
  getApiResponse,
}: Record<string, any>): Promise<null | string | void> {
  const { bankAccountNumber } = await getApiResponse(['fetch', 'client', 'bankAccountNumber']);
  const { status } = await getApiResponse(['fetch', 'client', 'application']);

  const hasBankAccountNumber = bankAccountNumber && bankAccountNumber !== '';
  const hasOpenLoanApplication = status && status === 'OPEN';

  if (!hasBankAccountNumber && hasOpenLoanApplication) {
    dispatch({
      type: 'GUARD_FORBID_LOAN_APPLICATION_WITHOUT_BANK_ACCOUNT_FAILED)',
    });

    return R.APPLICATION_BANK_IDENTIFICATION_PENNY_TRANSFER;
  }

  return null;
}
