
import { ReactElement } from 'react';
import classnames from 'classnames';
import ContextAwareLink from '@shared/components/ContextAwareLink';
import injectStyles from '4finance-components-pl';
import styles from './NavigationLink.jss';

type Props = {
  classes: Record<string, any>;
  active: boolean;
  title: ReactElement;
  url: string;
  mobile: boolean;
  external: boolean | null | undefined;
};

const NavigationLink = (props: Props): JSX.Element => {
  const {
    classes,
    url,
    title,
    active,
    mobile,
    external,
  } = props;
  const linkClass = classnames(mobile ? classes.mobileLink : classes.link, {
    active,
  });
  const isDashboard = window.location.pathname === '/dashboard' && url !== '/logout';
  const state = { fromDashboard: isDashboard };
  const to = { pathname: url, state };

  return (
    <ContextAwareLink to={isDashboard ? to : url} className={linkClass} external={Boolean(external)}>
      {title}
    </ContextAwareLink>
  );
};

NavigationLink.styleRoot = 'NavigationLink';
export default injectStyles(styles)(NavigationLink);
