import actions from '../common/actions';
import createConnectWrappers from '4finance-connect-pl';
import selectors from '../common/selectors';

const {
  connect: connectDecorator,
  connectState: connectStateDecorator,
  connectSelectors: connectSelectorsDecorator,
  connectActions: connectActionsDecorator,
  connectPrefetch: connectPrefetchDecorator,
  connectPending: connectPendingDecorator,
} = createConnectWrappers({
  actions,
  selectors,
});

export const connectState = connectStateDecorator;
export const connectSelectors = connectSelectorsDecorator;
export const connectActions = connectActionsDecorator;
export const connectPrefetch = connectPrefetchDecorator;
export const connectPending = connectPendingDecorator;
export default connectDecorator;
