import { extendTheme, normalizeColors, onea as theme } from '4finance-components-pl';
import { fromJS } from 'immutable';
import colors from '@browser/lib/styles/colors.jss';

const extendedTheme = extendTheme(theme, fromJS({
  globals: {
    colors: normalizeColors(colors),
    borders: {
      calculator: '8px',
    },
  },
}));

export default extendedTheme;
