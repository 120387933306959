
import { BodyText } from '../../../../shared/components/typography';
import { compose } from 'redux';
import { connectActions } from '../../../../connect';
import { useCallback } from 'react';
import { useTranslator } from '../../../../../localizations';
import classnames from 'classnames';
import CloseButton from '../../../../shared/components/CloseButton';
import injectStyles, { Container } from '4finance-components-pl';
import QA_CLASSES from '../../../../lib/qa';
import styles from './Message.jss';

type Props = {
  classes: Record<string, any>;
  removeMessage: (...args: Array<any>) => any;
  value: string;
};

function getQaClass(value: string) {
  switch (value) {
    case 'dashboard.settings.change_success_messages.password':
      return QA_CLASSES.ALERT_PASSWORD_CHANGED;

    case 'dashboard.settings.change_success_messages.emailVerificationCode':
      return QA_CLASSES.ALERT_EMAIL_CHANGED;

    case 'dashboard.settings.change_success_messages.phoneVerificationCode':
      return QA_CLASSES.ALERT_PHONE_CHANGED;

    default:
      return QA_CLASSES.ALERT_MESSAGE;
  }
}

const Message = (props: Props): JSX.Element => {
  const {
    classes,
    value,
    removeMessage,
  } = props;
  const {
    msg,
  } = useTranslator();
  const onClose = useCallback(() => {
    removeMessage(value);
  }, [value]);

  return (
    <div className={classnames(classes.root, getQaClass(value))}>
      <Container>
        <BodyText>
          {msg(value)}
        </BodyText>
        <CloseButton onClick={onClose} />
      </Container>
    </div>
  );
};

Message.styleRoot = 'Messages';
export default compose<any>(connectActions({
  removeMessage: ['settings', 'removeMessage'],
}), injectStyles(styles))(Message);
