
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import styles from './ButtonGroup.jss';

type Props = {
  classes: Record<string, any>;
  children: React.ReactChildren;
  // Own props
  justifyEnd?: boolean;
};

const ButtonGroup = (props: Props): JSX.Element => {
  const {
    classes,
    children,
    justifyEnd,
  } = props;

  return (
    <div className={classnames(classes.root, {
      justifyEnd,
    })}
    >
      {children}
    </div>
  );
};

ButtonGroup.styleRoot = 'ButtonGroup';
export default injectStyles(styles)(ButtonGroup);
