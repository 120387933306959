
import { getImageUrl, isSvg } from '../utils';
import Svg from './Svg';
import type { ContentImage } from '../types';

type PassedProps = {
  className?: string;
};
type Props = PassedProps & {
  content: ContentImage;
  children?: React.ReactChildren;
  inline?: boolean;
};

const Image = (props: Props): JSX.Element => {
  const {
    content,
    children,
    inline,
    ...elementProps
  } = props;

  (elementProps as PassedProps);

  if (!content) {
    return null;
  }

  if (isSvg(content) && inline) {
    return <Svg content={content} {...elementProps} />;
  }

  const backgroundImage = `url(${getImageUrl(content)})`;

  return (
    <div
      {...elementProps}
      style={{
        backgroundImage,
      }}
    >
      {children}
    </div>
  );
};

export default Image;
