export default ({ colors, mediaQueries }) => ({
  legalText: {
    fontSize: '13px',
    padding: '0 0 14px 35px',
  },

  headingB: {
    margin: '20px 0 50px 0',
    fontSize: '38px',

    [mediaQueries.breakpointTablet]: {
      marginTop: 0,
      fontSize: '26px',
    },
  },

  expandIcon: {
    height: '11px',
    width: '11px',
    color: colors.almostBlack,
    '& *': {
      fill: colors.secondary,
    },
  },

  expandIcon__rotated: {
    transform: 'rotate(180deg)',
  },
});
