export default ({ colors, mediaQueries }) => ({
  root: {
    padding: '0 40px',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
    '& $arrows div': {
      backgroundColor: colors.secondary,
    },
    '&.active': {
      color: colors.white,
      '& $arrows div': {
        backgroundColor: colors.primary,
      },
    },
    [mediaQueries.breakpointTablet]: {
      padding: '0 32px',
    },
    [mediaQueries.breakpointSmall]: {
      padding: '0 24px',
    },
  },
  arrows: {
    position: 'absolute',
    right: '0',
    height: '100%',
    width: '100%',
    '& div': {
      height: '50%',
      borderRight: `4px solid ${colors.white}`,
      '&:first-child': {
        transform: 'skew(45deg)',
      },
      '&:last-child': {
        transform: 'skew(-45deg)',
      },
    },
  },
  text: {
    position: 'relative',
    [mediaQueries.breakpointLaptopSmall]: {
      fontSize: '12px',
      '& span': {
        display: 'none',
      },
    },
    [mediaQueries.breakpointTablet]: {
      fontSize: '11px',
    },
  },
});
