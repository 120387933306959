export default (({
  colors,
  mediaQueries,
}) => ({
  root: {
    padding: '32px',
    flex: '0 0 50%',
    backgroundColor: colors.white,
    '&.yellow': {
      backgroundColor: colors.secondary,
    },
    [mediaQueries.breakpointTablet]: {
      padding: '24px',
    },
  },
}));
