export default ({ colors }) => ({
  outerContainer: {
    width: '570px',
    backgroundColor: colors.vivusPrimary,
    margin: '-32px 0 -32px -32px',
  },
  innerContainer: {
    padding: '32px',
  },
  logo: {
    height: '45px',
    width: '270px',
    backgroundRepeat: 'no-repeat',
    marginBottom: '20px',
  },
  line1: {
    color: colors.white,
    fontSize: '30px',
    fontWeight: '700',
    letterSpacing: '1px',
    width: '320px',
  },
  line2: {
    color: colors.vivusSecondary,
    fontSize: '52px',
    fontWeight: '700',
    width: '320px',
    letterSpacing: '1px',
  },
  button: {
    backgroundColor: `${colors.vivusSecondary} !important`,
    width: '320px',
    display: 'block',
  },
  disclaimer: {
    color: '#fff',
    textAlign: 'center',
    margin: '16px 0',
  },
});
