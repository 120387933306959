import * as R from '../../browser/routes/routesList';
import config from '4finance-configuration-pl';

/**
 * Get next path after `/application/confirm`
 */
export async function getNextRouteAfterApplicationConfirm(
  getApiResponse: (...args: Array<any>) => any,
): Promise<string> {
  const { bankAccount } = await getApiResponse(['fetch', 'client']);
  const application = await getApiResponse(['fetch', 'client', 'application']);

  if (!bankAccount) {
    return R.APPLICATION_BANK_IDENTIFICATION;
  }

  if (application.amount < config.maxAmountWithoutDocumentUpload) {
    return R.APPLICATION_CREDIT_CHECK;
  }

  return R.APPLICATION_BANK_IDENTIFICATION_PENNY_TRANSFER;
}

/**
 * Get next path after `/applcation/bank-identification`
 *
 * @throws Error
 */
export async function getNextRouteAfterApplicationBankIdentification(
  getState: (...args: Array<any>) => any,
): Promise<string> {
  const state = getState();
  const selectedBank = state.registration.get('bank');

  if (!selectedBank) {
    throw new Error('Bank not selected.');
  }

  return R.APPLICATION_BANK_IDENTIFICATION_PENNY_TRANSFER;
}

/**
 * Get next path after `/application/credit-check`
 */
export async function getNextRouteAfterApplicationCreditCheck(getApiResponse: (...args: Array<any>) => any) {
  const client = await getApiResponse(['fetch', 'client']);
  const application = await getApiResponse(['fetch', 'client', 'application']);

  if (client.status !== 'IDENTIFIED' && client.markedForManualIdentification) {
    return R.APPLICATION_PENDING;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (application && application.proposal) {
    return R.DASHBOARD;
  }

  if (application.resolution === 'REJECTED' || application.resolution === 'CANCELLED') {
    return R.APPLICATION_FAILED;
  }

  return R.DASHBOARD;
}
