
import { compose } from 'redux';
import { connectPrefetch, connectSelectors } from '../../connect';
import Loading from '../../shared/components/Loading';
import type { ImmutableMap } from '../../../common/lib/types';

type Props = {
  latestLoan: ImmutableMap | null | undefined;
  latestLoanAndScheduleFetched: boolean;
};

const withLatestLoan = <C extends React.ComponentType<Props>>(Component: C): React.ReactElement<C> => {
  const Wrapper = (props: Props) => {
    const {
      latestLoanAndScheduleFetched,
    } = props;

    if (!latestLoanAndScheduleFetched) {
      return <Loading />;
    }

    return <Component {...props} />;
  };

  return compose<any>(connectSelectors({
    latestLoan: ['dashboard', 'latestLoan'],
    latestLoanAndScheduleFetched: ['dashboard', 'latestLoanAndScheduleFetched'],
  }), connectPrefetch([['dashboard', 'fetchLatestLoan'], ['dashboard', 'fetchLatestLoanSchedule']]))(Wrapper);
};

export default withLatestLoan;
