import { compose } from 'redux';
import { connectActions, connectState } from '../../../connect';
import { translate } from '../../../../localizations';
import StaticChatbot from './StaticChatbot';

const DynamicChatbot = compose<any>(connectActions({
  updatePath: ['router', 'updatePath'],
}), connectState({
  latestLoan: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
  client: ['api', 'fetch', 'client', 'query', 'data'],
}), translate)(StaticChatbot);

export default DynamicChatbot;
