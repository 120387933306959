export default ({ colors }) => ({
  root: {
    backgroundColor: colors.white,
    padding: '16px',
    marginBottom: '24px',
  },
  body: {
    margin: '8px 0',
    color: colors.primary,
    '& > span > span': {
      display: 'block',
      fontSize: '34px',
      fontWeight: 700,
    },
  },
  button: {
    boxShadow: 'none',
  },
});
