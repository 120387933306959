import { devices } from '../Device';
import { RESIZE } from '../../../common/lib/constants';
import { useCallback, useEffect, useState } from 'react';

export default function useDevice() {
  const [device, setDevice] = useState(null);
  const onResize = useCallback(() => {
    const currentDevice = devices.find(dev => dev.maxWidth > window.innerWidth);

    setDevice(dev => {
      if (dev !== currentDevice) {
        return currentDevice;
      }

      return dev;
    });
  }, []);

  useEffect(() => {
    window.addEventListener(RESIZE, onResize);

    return () => window.removeEventListener(RESIZE, onResize);
  }, []);
  useEffect(() => {
    onResize();
  }, []);

  return device;
}
