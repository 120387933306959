export default ({ mediaQueries }) => ({
  detailsHeadingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  scheduleButton: {
    lineHeight: 0.9,
    textAlign: 'right',
    [mediaQueries.breakpointSmall]: {
      fontSize: '14px',
    },
  },
  button: {
    width: '100%',
  },
  dueDate: {
    borderBottom: 'none',
  },
  warning: {
    margin: '16px 0',
  },
});
