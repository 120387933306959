
import { $Diff } from 'utility-types';
import { memo, useCallback } from 'react';
import { pick } from 'lodash';
import Button from '../shared/components/Button';
import classnames from 'classnames';
import Container from './components/Container';
import injectStyles from '4finance-components-pl';
import LegalTerms from './components/LegalTerms/LegalTerms';
import Offer from './components/Offer';
import QA_CLASSES from '../lib/qa';
import Sliders, { propNames as slidersPropNames } from './components/Sliders';
import styles from './StaticCalculator.jss';
import useContentContext from '../lib/content/useContentContext';
import type { ApplicationOffer } from './requests/fetchOffer';
import type { Props as ButtonPropsT } from '../shared/components/Button';
import type { Props as SlidersProps } from './components/Sliders';

type ButtonExcludedProps = {
  children: React.ReactChildren;
  className?: string;
  onClick: (...args: Array<any>) => any;
};
export type Props = SlidersProps & {
  classes: Record<string, any>;
  // Own props
  offer: ApplicationOffer;
  pending: boolean;
  onApply: (...args: Array<any>) => any;
  updateMonthlyPaymentInterval: (...args: Array<any>) => any;
  ButtonProps?: Partial<$Diff<ButtonPropsT, ButtonExcludedProps>>;
};
type MemoProps = $Diff<Props, {
  classes: Record<string, any>;
}>;

const StaticCalculatorTerm = (props: Props): JSX.Element | null => {
  const {
    classes,
    amount,
    term,
    offer,
    pending,
    onApply,
    ButtonProps = {},
    transparent = false,
    onAfterChangeAmount,
  } = props;
  const {
    calculator: content,
  } = useContentContext();
  const onClick = useCallback(() => {
    onApply({
      amount,
      term,
    });
  }, [amount, term]);

  return content ? (
    <Container className={classnames(classes.container, QA_CLASSES.PROPOSITION_CALCULATOR, {
      transparent,
    })}
    >
      <Sliders classes={classes} {...pick(props, slidersPropNames)} onAfterChangeAmount={onAfterChangeAmount} />
      <div className={classnames(classes.offerContainer, QA_CLASSES.CALCULATOR_FOOTER, {
        transparent,
      })}
      >
        <Offer offer={offer} pending={pending} />
        <Button className={classnames(classes.button, QA_CLASSES.APPLY_BUTTON)} disabled={pending} fullWidth onClick={onClick} {...ButtonProps}>
          {content?.fields?.buttonContent}
        </Button>
        <LegalTerms amount={amount} term={offer?.term} />
      </div>
    </Container>
  ) : null;
};

StaticCalculatorTerm.styleRoot = 'CalculatorTerm';
export default memo<MemoProps>(injectStyles(styles)(StaticCalculatorTerm));
