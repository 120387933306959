import * as R from '../../../../routes/routesList';

import { Background } from '../../../../shared/components/layout';
import { BodyText, Hyperlink } from '../../../../shared/components/typography';
import { useTranslator } from '../../../../../localizations';
import injectStyles from '4finance-components-pl';
import styles from './Extension.jss';

type Props = {
  classes: Record<string, any>;
};

const Extension = (props: Props): JSX.Element => {
  const {
    classes,
  } = props;
  const {
    msg,
  } = useTranslator();

  return (
    <div className={classes.root}>
      <Background src={'/assets/img/clock-icon.png'} className={classes.icon} size={'100% auto'} position={'top center'} />
      <div className={classes.text}>
        <BodyText>
          <b>{msg('dashboard.loan.active.extension.heading')}</b>
          {msg('dashboard.loan.active.extension.body')}
        </BodyText>
        <Hyperlink className={classes.hyperlink} to={R.EXTENSION}>
          {msg('dashboard.loan.active.extension.button')}
        </Hyperlink>
      </div>
    </div>
  );
};

Extension.styleRoot = 'Extension';
export default injectStyles(styles)(Extension);
