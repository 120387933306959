import { CONSENT_COLA_IDS } from '../../../../common/lib/constants';
import { useCallback } from 'react';
import AcceptInformationFormConsent from './AcceptInformationFormConsent';

export default function useRenderConsentContentWithSchedule(scheduleContext: 'application' | 'proposal') {
  return useCallback((colaId: string, text: string) => {
    if (colaId !== CONSENT_COLA_IDS.FI_PEP_DECLARATION_READ || text.indexOf('%SCHEDULE%') === -1 || text.indexOf('%AMOUNT%') === -1 || text.indexOf('%TERM%') === -1) {
      return (
        <span dangerouslySetInnerHTML={{
          __html: text,
        }}
        />
      );
    }

    return <AcceptInformationFormConsent text={text} context={scheduleContext || 'calculator'} />;
  }, []);
}
