import { actions as form } from '4finance-onion-form-pl';
/**
 * Helper that returns an action with API errors extracted from response as payload.
 * Supports an optional mapping object which is used to rename API properties to local properties.
 *
 * @function setFormApiError
 * @param  {String} formName - name of the form - see onion-form
 * @param  {Object} response - API response
 * @param  {Object} [mapping] - mapping object, e.g. { password: 'oldPassword' } will cause "password" be renamed to "oldPassword"
 * @return {Object} - onion-form wrapped action
 */

export default function setFormApiError(formName, payload, mapping = {}) {
  if (!payload.errors) {
    return {
      type: 'SET_FORM_API_ERROR_FAILED',
    };
  }

  return form.setMultipleFields(formName, 'apiError', payload.errors.reduce((acc, err) => ({ ...acc,
    [mapping[err.property] || err.property]: err.messageTemplate,
  }), {}));
}
