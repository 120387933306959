const ERROR = 'incorrect_format';
// eslint-disable-next-line no-useless-escape
const REGEX = /^[0-9]+(\.[0-9]{1,2})?$/;

export default function currency() {
  return (value: string | null | undefined): string | null => {
    if (value) {
      const sanitizedValue = value.replace(',', '.');

      if (value.startsWith('0') && value.length !== 1) {
        return 'must_not_start_with_zero';
      }

      if (!REGEX.test(sanitizedValue)) {
        return ERROR;
      }

      return null;
    }

    return null;
  };
}
