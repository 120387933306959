import { createSelector } from '4finance-connect-pl';

const apiSelector = state => state.api;

export const latestLoan = createSelector([apiSelector], api => api.getIn(['fetch', 'client', 'loans', 'latest', 'data']));
export const latestLoanAndScheduleFetched = createSelector([apiSelector], api => {
  const loanFetched = api.getIn(['fetch', 'client', 'loans', 'latest', 'fetched']);
  const scheduleFetched = api.getIn(['fetch', 'client', 'loans', 'paymentSchedule', 'latest', 'fetched']);

  return loanFetched && scheduleFetched;
});
export const latestLoanSchedule = createSelector([apiSelector], api => api.getIn(['fetch', 'client', 'loans', 'paymentSchedule', 'latest', 'data']));
export const clientBank = createSelector([apiSelector], api => {
  const banks = api.getIn(['fetch', 'client', 'banks', 'query', 'data', 'templates']);

  if (!banks) {
    return null;
  }

  return banks.find(b => b.get('clientBank')) || banks.find(b => b.get('masterAccount'));
});
