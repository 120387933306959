export const FONTS: Record<string, any> = {
  Poppins: [
    {
      weight: 100,
      name: 'Poppins-Thin',
    },
    {
      weight: 200,
      name: 'Poppins-ExtraLight',
    },
    {
      weight: 300,
      name: 'Poppins-Light',
    },
    {
      weight: 400,
      name: 'Poppins-Light',
    },
    {
      weight: 500,
      name: 'Poppins-Regular',
    },
    {
      weight: 600,
      name: 'Poppins-Medium',
    },
    {
      weight: 700,
      name: 'Poppins-SemiBold',
    },
    {
      weight: 800,
      name: 'Poppins-Bold',
    },
    {
      weight: 900,
      name: 'Poppins-ExtraBold',
    },
  ],
};
const fonts: Record<string, any>[] = Object.keys(FONTS).reduce((acc, fontFamily) => [...acc, ...FONTS[fontFamily].map(({
  name,
  weight,
}) => ({
  fontFamily,
  fontStyle: 'normal',
  fontWeight: weight,
  fontDisplay: 'fallback',
  src: `url('/fonts/${name}.woff2') format('woff2')`,
}))], []);

export default {
  '@font-face': fonts,
};
