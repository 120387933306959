import * as R from '../../routes/routesList';

import { MobileEditableCalculator } from '../../calculator';
import { Route, RouteComponentProps, withRouter } from 'react-router';
import ApplicationFailed from './components/ApplicationFailed';
import ApplicationPending from './components/ApplicationPending';
import BankIdentification from './components/BankIdentification';
import ConfirmApplication from './components/ConfirmApplication';
import CreditCheck from './components/CreditCheck';
// import Decision from './components/Decision';
// import Employment from './components/Employment';
import Messages from '../settings/components/Messages';
import PennyTransfer from './components/PennyTransfer';
import Profile from './components/Profile';
import ProgressBar from './components/ProgressBar';
import Register from './components/Register';

type Props = {
  location: Location;
};

const Registration = (props: Props & RouteComponentProps): JSX.Element => {
  const { location } = props;
  const isCalculatorVisible = [R.REGISTER, R.PROFILE, R.EMPLOYMENT].includes(location.pathname);

  return (
    <>
      <Messages />
      <ProgressBar />
      {isCalculatorVisible && <MobileEditableCalculator />}
      <Route exact path={R.REGISTER} component={Register} />
      <Route exact path={R.PROFILE} component={Profile} />
      {/* <Route exact path={R.EMPLOYMENT} component={Employment} /> */}
      <Route exact path={R.APPLICATION_CONFIRM} component={ConfirmApplication} />
      <Route exact path={R.APPLICATION_BANK_IDENTIFICATION} component={BankIdentification} />
      <Route exact path={R.APPLICATION_BANK_IDENTIFICATION_PENNY_TRANSFER} component={PennyTransfer} />
      <Route exact path={R.APPLICATION_CREDIT_CHECK} component={CreditCheck} />
      <Route exact path={R.APPLICATION_PENDING} component={ApplicationPending} />
      <Route exact path={R.APPLICATION_FAILED} component={ApplicationFailed} />
    </>
  );
};

export default withRouter(Registration);
