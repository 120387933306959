import { applyMiddleware, compose, StoreEnhancer } from 'redux';
import { createLogger } from 'redux-logger';
import { pendingActionsMiddleware } from '4finance-connect-pl';
import { RootState } from '@browser/configureStoreWithHistory';
import config from '4finance-configuration-pl';
import Cookie from '../Cookie';
import createActionWatcherMiddleware from './createActionWatcherMiddleware';
import createStoreDependencyInjections from './createDependencyInjections';
import injectDependencies from './injectDependencies';
import promiseMiddleware from '4finance-promise-middleware-pl';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

interface StoreData {
  actionWatchers: Record<string, any>;
  definedPlatformMiddleware: any[];
  cookies: any;
  headers: HeadersInit;
  history: History;
  initialState: RootState;
  persistenceStore: Cookie;
  platformMiddleware: any[];
}

export default function createMiddlewares({
  actionWatchers,
  cookies,
  definedPlatformMiddleware = [] as any[],
  headers,
  initialState,
  persistenceStore,
}: StoreData): StoreEnhancer<{
  dispatch: unknown;
}, Record<string, unknown>> {
  const actionDependencyInjection = createStoreDependencyInjections({
    initialState,
    persistenceStore,
  });
  const middlewares = [...definedPlatformMiddleware, createActionWatcherMiddleware(actionDependencyInjection, actionWatchers), injectDependencies({ ...actionDependencyInjection,
    headers,
    cookies,
  }), promiseMiddleware, pendingActionsMiddleware()];
  const enableLogger = config.debug.reduxLogger && process.env.IS_BROWSER;

  if (enableLogger) {
    const logger = createLogger({
      collapsed: true,
      // Convert immutable to JSON.
      stateTransformer: (state: RootState) => JSON.parse(JSON.stringify(state)),
    });
    // Logger must be the last middleware in chain.

    middlewares.push(logger);
  }

  // eslint-disable-next-line no-underscore-dangle
  const enableDevToolsExtension = config.debug.devTools && process.env.IS_BROWSER && window.__REDUX_DEVTOOLS_EXTENSION__;
  // eslint-disable-line no-underscore-dangle
  const appliedMiddlewares = applyMiddleware(...middlewares);

  return enableDevToolsExtension ? compose<any>(appliedMiddlewares, window.__REDUX_DEVTOOLS_EXTENSION__()) // eslint-disable-line no-underscore-dangle
    : appliedMiddlewares;
}
