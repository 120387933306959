type Validator = (value: any) => string | null;
type ErrorReplacement = string | ((arg0: string) => string);
export default function altMessage(validator: Validator, error: ErrorReplacement) {
  return (value: any) => {
    const result = validator(value);

    if (result === null) {
      return result;
    }

    return typeof error === 'string' ? error : error(result);
  };
}
