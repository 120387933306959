import fetch from 'isomorphic-fetch';

export type Discount = {
  amount: number,
  percent: number,
  type: string,
}

export type ApplicationOffer = {
  term: number;
  newPrincipal: number;
  totalPrincipal: number;
  interestRate: number;
  newInterest: number;
  newInterestBeforeDiscount: number;
  firstPaymentInterest: number;
  currentPrincipal: number;
  currentPrincipalWithInterest: number;
  currentPrincipalWithInterestAndInitialCommission: number;
  monthlyPayment: number;
  agreementEndDate: string;
  isAdditionalAmount: boolean;
  discountAmount: number;
  discountPercent: number;
  discounts: Discount[],
  annualPercentageRate: number;
  totalInterest: number;
  initialCommission: number;
  newInitialCommission: number;
  initialCommissionYearlyRate: number;
  totalCostOfCredit: number;
  totalRepayableAmount: number;
  firstPaymentDate: string;
  // extensionFees: {}
  // loanExtensionFees: []
  newAmountUsedDays: number;
  newAmountWithInterestAndInitialCommission: number;
  newPrincipalApr: number;
  totalRoundedAmount: number;
};

export async function fetchOffer(amount: number, term: number): Promise<ApplicationOffer> {
  const response = await fetch(`/webapi/application/first-loan-offer?amount=${amount.toString()}&term=${term.toString()}`);

  return response.json();
}
