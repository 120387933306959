const ERROR = 'must_be_number';
const REGEX = new RegExp('^[0-9]+$');

export default function numbersOnly(canStartWithZero = true) {
  return (value: string | null | undefined): string | null | undefined => {
    if (value) {
      if (!canStartWithZero && value.startsWith?.('0')) {
        return 'must_not_start_with_zero';
      }

      return !REGEX.test(value) ? ERROR : null;
    }

    return null;
  };
}
