import * as R from '../../browser/routes/routesList';

export default function forbidAuth({
  getState,
  dispatch,
}) {
  const {
    authentication: {
      isLoggedIn,
    },
    registration,
  } = getState();

  const isJustRegistered = registration.get('isJustRegistered');

  if (!isLoggedIn) {
    return null;
  }

  dispatch({
    type: 'GUARD_FORBID_AUTH_FAILED',
  });

  if (isJustRegistered) {
    return R.APPLICATION_CONFIRM;
  }

  return R.DASHBOARD;
}
