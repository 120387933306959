import injectStyles from '4finance-components-pl';
import Loading from '../../shared/components/Loading';

import styles from './LoadingScreen.jss';

type Props = {
  classes: Record<string, any>;
};

const LoadingScreen = ({
  classes,
}: Props) => (
  <div className={classes.wrapper}>
    <Loading />
  </div>
);

export default injectStyles(styles)(LoadingScreen);
