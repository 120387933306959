export default (() => ({
  root: {
    margin: '0 -8px',
    '& button': {
      margin: '0 8px',
    },
    '&.justifyEnd': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}));
