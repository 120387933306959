
import { BodyText } from '../../../../shared/components/typography';
import { pick } from 'lodash';
import classnames from 'classnames';
import injectStyles, { RadioInput } from '4finance-components-pl';
import styles from './PaymentOption.jss';

type Props = {
  classes: Record<string, any>;
  // Own props
  title: string;
  onChange: (...args: Array<any>) => any;
  value: 'fullRepayment' | 'currentInstallment';
  current: 'fullRepayment' | 'currentInstallment';
  displayedAmount: string;
  footnote?: string;
};

const PaymentOption = (props: Props): JSX.Element => {
  const {
    classes,
    title,
    current,
    value,
    onChange,
    displayedAmount,
    footnote,
  } = props;
  const overrides = ['optionText', 'wrapper', 'base', 'base__selected'];
  const selected = current === value;

  return (
    <div className={classes.root}>
      <div className={classes.inputContainer}>
        <RadioInput classes={pick(classes, overrides)} onChange={onChange} value={value} selected={selected} />
        <BodyText className={classnames(classes.label, {
          selected,
        })}
        >
          <span>{title}</span>
          <span className={classes.displayedValue}>
            {displayedAmount}
          </span>
        </BodyText>
      </div>
      {footnote && (
      <div className={classes.footnote}>
        {footnote}
      </div>
      )}
    </div>
  );
};

PaymentOption.styleRoot = 'PaymentOption';
export default injectStyles(styles)(PaymentOption);
