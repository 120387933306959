
import { Background } from '@shared/components/layout';
import { ReactChildren, useCallback, useState } from 'react';
import classnames from 'classnames';
import Disclaimer from '@shared/components/Disclaimer';
import injectStyles, { Container } from '4finance-components-pl';
import QA_CLASSES from '@browser/lib/qa';
import styles from './Proposition.jss';
import type { ContentMainpageProposition } from '@browser/lib/content/types';

type Props = {
  classes: Record<string, any>;
  content: ContentMainpageProposition;
  children: ReactChildren;
};

const Proposition = (props: Props): JSX.Element => {
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  const {
    classes,
    content,
    children,
  } = props;
  const toggleDisclaimer = useCallback(() => {
    setDisclaimerOpen(open => {
      window.document.body.style.overflow = !open ? 'hidden' : 'visible';

      return !open;
    });
  }, []);
  const {
    backgroundDesktop,
  } = content.fields;
  const {
    headline1,
    headline2,
    headline3,
    legalTextTitle,
    legalTextContent,
  } = content.fields;

  return (
    <>
      <Background src={backgroundDesktop.fields.file.url} height={'100vh'} className={classes.background} breakpoint={'breakpointLaptopSmall'} position={'top center'}>
        <Container className={classes.innerContainer}>
          <div className={classes.textContainer}>
            <div className={classes.headline1}>
              {headline1}
            </div>
            <div className={classes.headline2}>
              {headline2}
            </div>
            <div className={classes.headline3}>
              {headline3}
            </div>
            <Disclaimer className={classes.disclaimer} content={legalTextContent} title={legalTextTitle} isOpen={disclaimerOpen} toggle={toggleDisclaimer} />
          </div>
          <div className={classnames(classes.desktopCalculator, QA_CLASSES.PROPOSITION_CALCULATOR_DESKTOP)}>
            {children}
          </div>
        </Container>
      </Background>
      <div className={classnames(classes.mobileCalculator, QA_CLASSES.PROPOSITION_CALCULATOR_MOBILE)}>
        {children}
      </div>
    </>
  );
};

Proposition.styleRoot = 'Proposition';
export default injectStyles(styles)(Proposition);
