
import { compose } from 'redux';
import { connectActions, connectPrefetch } from '../../../../connect';
import { Hyperlink } from '../../../../shared/components/typography';
import { useCallback } from 'react';
import { useTranslator } from '../../../../../localizations';
import Consent from './Consent';
import injectStyles from '4finance-components-pl';
import statementGroup from '../../../registration/statementGroup';
import styles from './Consents.jss';

type Props = {
  classes: Record<string, any>;
  sendChangeConsent: (...args: Array<any>) => any;
  // Own props
  set: string;
};

const Consents = (props: Props): JSX.Element => {
  const {
    classes,
    sendChangeConsent,
    set,
  } = props;
  const {
    msg,
  } = useTranslator();
  const consents = statementGroup[set];
  const {
    statements,
  } = consents?.fields || {};
  const onSelectAll = useCallback(async () => {
    if (!statements) {
      return;
    }

    await sendChangeConsent(statements, true);
  }, [statements]);

  return (
    <div className={classes.root}>
      {statements && statements.map((s, index) => <Consent content={s} key={index} />)}
      <Hyperlink className={classes.selectAll} onClick={onSelectAll}>
        {msg('consents.select_all')}
      </Hyperlink>
    </div>
  );
};

Consents.styleRoot = 'Consents';
export default compose<any>(connectActions({
  sendChangeConsent: ['settings', 'sendChangeConsent'],
}), connectPrefetch([['api', 'fetchClientConsents']]), injectStyles(styles))(Consents);
