export default (({
  colors,
}) => ({
  schedule: {
    color: colors.primary,
    border: 'none',
    backgroundColor: 'transparent',
    display: 'inline',
    font: 'inherit',
    padding: 0,
    cursor: 'pointer',
  },
}));
