export default (({
  colors,
}) => ({
  column: {
    flex: '0 0 50%',
    padding: '24px',
    '&.yellow': {
      backgroundColor: colors.secondary,
    },
    '&.white': {
      backgroundColor: colors.white,
    },
  },
  icon: {
    width: '200px',
  },
}));
