
import { BodyText, SecondaryHeading } from '../../../../shared/components/typography';
import { compose } from 'redux';
import { connectActions, connectPrefetch, connectState } from '../../../../connect';
import { useCallback, useMemo } from 'react';
import { useTranslator } from '../../../../../localizations';
import Back from '../Back';
import getBankName from '../../../../lib/banks/getBankName';
import injectStyles from '4finance-components-pl';
import styles from '../Change.jss';

type Props = {
  classes: Record<string, any>;
  banks: any;
  selectBank: (...args: Array<any>) => any;
  updatePath: (...args: Array<any>) => any;
  formType: string;
  confirmPath: string;
};

const ChangeByBankTransfer = (props: Props): JSX.Element => {
  const { classes, banks, selectBank, updatePath, formType, confirmPath } = props;
  const { msg, cnt } = useTranslator();

  const banksList = useMemo(() => { // eslint-disable-line
    return banks ? banks.toJS() : [];
  }, [banks]);

  const onSelectBank = useCallback((e: Record<string, any>) => {
    const value = e.target.value;
    const masterBank = banksList.find(a => a.masterAccount);

    selectBank(value === 'other' ? masterBank : banksList[value]);
    updatePath(confirmPath);
  }, [banksList]);

  const title = msg(`dashboard.settings.change_by_bank_transfer.formType.${formType.toLowerCase()}.title`);
  const information = msg(`dashboard.settings.change_by_bank_transfer.formType.${formType.toLowerCase()}.information`);
  const informationBankChangedMessage = cnt(`dashboard.settings.change_by_bank_transfer.formType.${formType.toLowerCase()}.information_bank_changed_message`);
  const informationBankChangedContact = cnt(`dashboard.settings.change_by_bank_transfer.formType.${formType.toLowerCase()}.information_bank_changed_contact_details`);
  const chooseBankText = msg(`dashboard.settings.change_by_bank_transfer.formType.${formType.toLowerCase()}.chooseBankText`);

  return (
    <>
      <SecondaryHeading>
        {title}
      </SecondaryHeading>
      <BodyText className={classes.text}>
        {information}
      </BodyText>
      <BodyText className={classes.text}>
        {informationBankChangedMessage}
      </BodyText>
      <BodyText className={classes.text}>
        {informationBankChangedContact}
      </BodyText>
      <BodyText className={classes.text}>
        {chooseBankText}
      </BodyText>
      <div className={classes.selectContainer}>
        <select className={classes.select} value={''} onChange={onSelectBank}>
          <option disabled value={''}>
            {msg('dashboard.settings.change_by_bank_transfer.choose')}
          </option>
          {banksList.map((b, index) => (
            <option value={index} key={index}>
              {getBankName(b)}
            </option>
          ))}
          <option value={'other'}>
            {msg('dashboard.settings.change_by_bank_transfer.other')}
          </option>
        </select>
      </div>
      <Back />
    </>
  );
};

ChangeByBankTransfer.styleRoot = 'ChangeByBankTransfer';

export default compose<any>(
  connectPrefetch([
    ['api', 'fetchClientBanks'],
  ]),
  connectActions({
    selectBank: ['settings', 'selectBank'],
    updatePath: ['router', 'updatePath'],
  }),
  connectState({
    banks: ['api', 'fetch', 'client', 'banks', 'query', 'data', 'templates'],
  }),
  injectStyles(styles),
)(ChangeByBankTransfer);
