import * as Immutable from 'immutable';
import { DEVICES } from '../../common/lib/constants';

export class Device {
  name: string;
  maxWidth: number;

  constructor(name: string, maxWidth: number) {
    this.name = name;
    this.maxWidth = maxWidth;
  }

  atLeast(sizeName: string) {
    const searchDevice = devices.find(device => device.name === sizeName);

    return devices.indexOf(searchDevice) <= devices.indexOf(this);
  }

  atMost(sizeName: string) {
    const searchDevice = devices.find(device => device.name === sizeName);

    return devices.indexOf(searchDevice) >= devices.indexOf(this);
  }
}

export const devices = Immutable.List<Device>([
  new Device('phone', DEVICES.phone),
  new Device('tablet', DEVICES.tablet),
  new Device('desktop', DEVICES.desktop),
  new Device('desktopLarge', DEVICES.desktopLarge),
  new Device('large', DEVICES.large),
]);
