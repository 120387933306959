import * as guards from '@common/routeGuards';
import * as R from '../routesList';
import { requireBankSelected } from '@common/registration/guards';
import requireApplicationConfirmed from '@common/registration/guards/requireApplicationConfirmed';

export default {
  [R.REGISTER]: [guards.forbidAuth],
  [R.AWAITING_LOGIN]: [guards.forbidAuth],
  [R.PROFILE]: [guards.requireAuth],
  [R.EMPLOYMENT]: [guards.requireAuth],
  [R.APPLICATION_CONFIRM]: [guards.requireAuth],
  [R.APPLICATION_BANK_IDENTIFICATION]: [guards.requireAuth],
  [R.APPLICATION_BANK_IDENTIFICATION_PENNY_TRANSFER]: [guards.requireAuth, requireBankSelected],
  [R.DECISION]: [guards.requireAuth, requireBankSelected],
  [R.APPLICATION_CREDIT_CHECK]: [guards.requireAuth, requireApplicationConfirmed],
  [R.APPLICATION_PENDING]: [guards.requireAuth, requireApplicationConfirmed],
  [R.APPLICATION_FAILED]: [guards.requireAuth],
};
