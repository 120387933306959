
import { compose } from 'redux';
import { connectState } from '../../../connect';
import { Section } from '../../../shared/components/layout';
import { useLoanContext } from '../../dashboard/components/LoanContext';
import { useTranslator } from '../../../../localizations';
import DashboardHeader from '../DashboardHeader';
import injectStyles from '4finance-components-pl';
import Message from '../Message';
import QA_CLASSES from '../../../lib/qa';
import styles from './DashboardSection.jss';

type Props = {
  classes: Record<string, any>;
  children: React.ReactChildren;
  validationResolutionDetail: string;
  // Own props
  title: string;
};

const DashboardSection = (props: Props): JSX.Element => {
  const {
    classes,
    children,
    title,
    validationResolutionDetail,
  } = props;
  const {
    msg,
  } = useTranslator();
  const {
    isDebtCollection,
    isLatePayment,
    hasPenalties,
  } = useLoanContext();
  const isPenalty = (validationResolutionDetail === 'OPEN_PENALTIES' || validationResolutionDetail === 'OPEN_LOANS_LIMIT') && hasPenalties;
  const isPendingTransfer = validationResolutionDetail === 'LOAN_IN_PROGRESS';

  return (
    <>
      {isDebtCollection && <Message title={msg('dashboard.debt_collection.message_title')} body={msg('dashboard.debt_collection.message_body')} className={QA_CLASSES.ALERT_WARNING} />}
      {isLatePayment && <Message title={msg('dashboard.late_payment.message_title')} body={msg('dashboard.late_payment.message_body')} className={QA_CLASSES.LATE_WARNING} />}
      {isPenalty && <Message title={msg('dashboard.penalties.message_title')} body={msg('dashboard.penalties.message_body')} className={QA_CLASSES.PENALTY_WARNING} />}
      {isPendingTransfer && <Message body={msg('dashboard.transfer_pending.message_body')} className={QA_CLASSES.TRANSFER_PENDING} />}
      <Section
        gray
        classes={{
          container: classes.outerContainer,
        }}
      >
        <DashboardHeader title={title} />
        <div className={classes.container}>
          {children}
        </div>
      </Section>
    </>
  );
};

DashboardSection.styleRoot = 'DashboardSection';
export default compose<any>(connectState({
  validationResolutionDetail: ['api', 'fetch', 'client', 'query', 'data', 'validationResolutionDetail'],
}), injectStyles(styles))(DashboardSection);
