import * as R from '../../../../routes/routesList';

import { compose } from 'redux';
import { connectActions, connectPrefetch, connectState } from '../../../../connect';
import { connectSubmit, Form } from '4finance-onion-form-pl';
import { Hyperlink } from '../../../../shared/components/typography';
import { IoBlackBox } from '@dynamic/registration/fields';
import { MARKETING_CONSENTS } from '../../../../../common/lib/constants';
import { Offer } from '../../../../calculator/components';
import { useCallback } from 'react';
import { useRenderConsentContentWithSchedule } from '../../../../shared/components/AcceptInformationFormConsent';
import { useTranslator } from '../../../../../localizations';
import Button from '../../../../shared/components/Button';
import Consents from '../../../components/Consents';
import injectStyles from '4finance-components-pl';
import styles from './NoActiveLoan.jss';
import type { ImmutableMap } from '../../../../../common/lib/types';

const Submit = connectSubmit(Button);

type Props = {
  classes: Record<string, any>;
  acceptProposal: (...args: Array<any>) => any;
  offer: ImmutableMap | null | undefined;
};

const Proposal = (props: Props): JSX.Element => {
  const {
    classes,
    acceptProposal,
    offer,
  } = props;
  const {
    msg,
  } = useTranslator();
  const onSubmit = useCallback(({
    values,
  }) => {
    acceptProposal(values);
  }, []);
  const renderConsentContent = useRenderConsentContentWithSchedule('proposal');

  return (
    <>
      <div className={classes.proposalOfferContainer}>
        <Offer pending={!offer} offer={offer && offer.toJS()} />
      </div>
      <Form name={'acceptProposal'} onSubmit={onSubmit}>
        <IoBlackBox />
        <Consents setName={'Registration'} formName={'acceptProposal'} hide={MARKETING_CONSENTS} renderContent={renderConsentContent} />
        <Submit fullWidth>
          {msg('dashboard.loan.no_active.proposal.accept')}
        </Submit>
        <Hyperlink to={R.DASHBOARD} className={classes.proposalReject}>
          {msg('dashboard.loan.no_active.proposal.reject')}
        </Hyperlink>
      </Form>
    </>
  );
};

Proposal.styleRoot = 'Proposal';
export default compose<any>(connectPrefetch([['dashboard', 'fetchProposalOffer']]), connectState((props: Props, state: Record<string, any>) => {
  const {
    api,
  } = state;
  const proposal = api.getIn(['fetch', 'client', 'application', 'query', 'data', 'proposal']);

  if (!proposal) {
    return {};
  }

  const amount = proposal.get('amount').toString();
  const term = proposal.get('term').toString();

  return {
    offer: ['api', 'fetch', 'client', 'application', 'offer', amount, term?.toString?.() || '', 'default', 'data'],
  };
}), connectActions({
  acceptProposal: ['dashboard', 'acceptProposal'],
}), injectStyles(styles))(Proposal);
