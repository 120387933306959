import { compose } from 'redux';
import { connectSelectors, connectState } from '../../../connect';
import { createElement } from 'react';
import { MainHeading, SecondaryHeading } from '../../../shared/components/typography';
import { useTranslator } from '../../../../localizations';
import injectStyles from '4finance-components-pl';
import QA_CLASSES from '../../../lib/qa';
import Separator from '../../../shared/components/Separator';
import styles from './DashboardHeader.jss';
import type { ImmutableMap } from '../../../../common/lib/types';

type Props = {
  classes: Record<string, any>;
  clientNumber: number;
  latestLoan: ImmutableMap | null | undefined;
  // Own props
  title: string;
};
type ConfigProps = { className: Props['classes']; lighter: boolean; }

type Config = [
  string |
  FunctionComponent<ConfigProps> |
  ComponentClass<ConfigProps, any>, ConfigProps
]

const DashboardHeader = (props: Props): JSX.Element => {
  const {
    classes,
    title,
    clientNumber,
    latestLoan,
  } = props;
  const {
    msg,
  } = useTranslator();
  const config: Config = [!title ? SecondaryHeading : 'div', {
    className: classes.number,
    lighter: 'true',
  }];

  return (
    <>
      <MainHeading>
        {title}
      </MainHeading>
      <div className={classes.numbersContainer}>
        {createElement(...config, [msg('dashboard.client_number'), <b key={Math.random().toString()} className={QA_CLASSES.DB_CLIENT_NUMBER}>{clientNumber}</b>])}
        {latestLoan && createElement(...config, [msg('dashboard.loan_number'), <b key={Math.random().toString()} className={QA_CLASSES.DB_LOAN_NUMBER}>{latestLoan.get('loanNumber')}</b>])}
      </div>
      <Separator />
    </>
  );
};

DashboardHeader.styleRoot = 'DashboardHeader';
export default compose<any>(connectState({
  clientNumber: ['api', 'fetch', 'client', 'query', 'data', 'number'],
}), connectSelectors({
  latestLoan: ['dashboard', 'latestLoan'],
}), injectStyles(styles))(DashboardHeader);

