
import { compose } from 'redux';
import { connectActions } from '../../../../connect';
import { useCallback } from 'react';
import Button from '../../../../shared/components/Button';
import classnames from 'classnames';
import config from '4finance-configuration-pl';
import injectStyles from '4finance-components-pl';
import QA_CLASSES from '../../../../lib/qa';
import styles from './RandomFill.jss';

type Props = {
  classes: Record<string, any>;
  randomFill: (...args: Array<any>) => any;
  formNames?: string[];
  className?: string;
};

const RandomFill = (props: Props): JSX.Element => {
  const {
    classes,
    randomFill,
    formNames,
    className,
  } = props;
  const onClick = useCallback(() => {
    randomFill(formNames);
  }, [randomFill, formNames]);

  return (
    <Button className={classnames(classes.root, QA_CLASSES.RANDOM_FILL_BUTTON, className)} onClick={onClick}>
      Random fill
    </Button>
  );
};

RandomFill.styleRoot = 'RandomFill';
const ConnectedRandomFill = compose<any>(connectActions({
  randomFill: ['registration', 'randomFill'],
}), injectStyles(styles))(RandomFill);

export default config.faker.enabled ? ConnectedRandomFill : () => null;
