
import classnames from 'classnames';
import Container from '../Container';
import injectStyles from '4finance-components-pl';
import Loading from '@shared/components/Loading';
import styles from './Loader.jss';

type Props = {
  classes: Record<string, any>;
  // Own props
  pending: boolean;
  transparent: boolean | null | undefined;
  children: React.ReactChildren;
};

const Loader = (props: Props): JSX.Element | React.ReactChildren => {
  const {
    classes,
    children,
    pending,
    transparent = false,
  } = props;
  const rootClass = pending ? classnames(classes.root, {
    transparent,
  }) : '';

  return pending ? (
    <Container className={rootClass}>
      <Loading />
    </Container>
  ) : children;
};

Loader.styleRoot = 'Loader';
export default injectStyles(styles)(Loader);
