
import { debounce } from 'lodash';
import { fetchOffer } from './requests/fetchOffer';
import { Loader } from './components';
import { useCallback, useEffect, useState } from 'react';
import fetchConstraints from './requests/fetchConstraints';
import StaticCalculatorTerm from './StaticCalculatorTerm';

type Props = {
  transparent?: boolean;
  onApply: (...args: Array<any>) => any;
};

const StandaloneCalculator = (props: Props): JSX.Element => {
  const [pending, setPending] = useState(true);
  const [constraints, setConstraints] = useState<any>(null);
  const [offer, setOffer] = useState<any>(null);
  const [amount, setAmount] = useState<number>(0);
  const [term, setTerm] = useState<null | number>(null);

  const updateOffer = useCallback(debounce(async (amt: number, trm: number) => {
    const updatedOffer = await fetchOffer(amt, trm);

    setOffer(updatedOffer);
    setPending(false);
  }, 600), []);

  useEffect(() => {
    (async () => {
      const initialConstraints = await fetchConstraints();
      const {
        amountInterval,
        termInterval,
      } = initialConstraints;
      const defaultAmount = amountInterval.defaultValue;
      const defaultTerm = termInterval.defaultValue;
      const initialOffer = await fetchOffer(defaultAmount, defaultTerm);

      setAmount(defaultAmount);
      setTerm(defaultTerm);
      setConstraints(initialConstraints);
      setOffer(initialOffer);
      setPending(false);
    })();
  }, []);

  useEffect(() => {
    if (!amount || !term) {
      return;
    }

    setPending(true);
    updateOffer(amount, term);
  }, [amount, term]);

  return (
    <Loader pending={!constraints || !offer} {...props}>
      <StaticCalculatorTerm // Loader does not render children when pending
    // it might be impossible for flow to infer this.
        constraints={constraints}
        amount={amount}
        term={term}
        offer={offer}
        pending={pending}
        onChangeAmount={setAmount}
        onChangeTerm={setTerm}
        {...props}
      />
    </Loader>
  );
};

export default StandaloneCalculator;
