import * as C from '../../../../../common/lib/constants';
import * as R from '../../../../routes/routesList';

import { BodyText, SecondaryHeading } from '../../../../shared/components/typography';
import { compose } from 'redux';
import { connectActions, connectState } from '../../../../connect';
import { useEffect } from 'react';
import { useTranslator } from '../../../../../localizations';
import Button from '../../../../shared/components/Button';
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import QA_CLASSES from '../../../../lib/qa';
import styles from '../Change.jss';

type Props = {
  classes: Record<string, any>;
  bank: Record<string, any>;
  selectBank: (...args: Array<any>) => any;
  formType: string;
  clientNumber: number;
};

const ChangeByBankTransferConfirm = (props: Props): JSX.Element => {
  const {
    classes,
    bank,
    selectBank,
    formType,
    clientNumber,
  } = props;
  const {
    msg,
  } = useTranslator();
  const {
    clientIdentificationNumber,
    receiver,
    accountNumber,
  } = bank || {};

  useEffect(() => {
    return () => selectBank(null);
  }, []);
  const title = msg(`dashboard.settings.confirm_change_by_bank_transfer.formType.${formType.toLowerCase()}.title`);
  const text = msg(`dashboard.settings.confirm_change_by_bank_transfer.formType.${formType.toLowerCase()}.text`);
  const transferTitleText = msg(`dashboard.settings.confirm_change_by_bank_transfer.formType.${formType.toLowerCase()}.transferTitleText`, { clientNumber });

  return (
    <>
      <SecondaryHeading bigger lighter>
        {title}
      </SecondaryHeading>
      <BodyText className={classes.text} html>
        {text}
      </BodyText>
      <BodyText className={classes.transfer}>
        <div>
          <b>{msg('dashboard.settings.confirm_change_by_bank_transfer.transfer.name.title')}</b>
          <span className={QA_CLASSES.RECEIVER}>
            {receiver}
          </span>
        </div>
        <div>
          <b>{msg('dashboard.settings.confirm_change_by_bank_transfer.transfer.address.title')}</b>
          <span className={QA_CLASSES.ADDRESS}>
            {msg('dashboard.settings.confirm_change_by_bank_transfer.transfer.address.text')}
          </span>
        </div>
        <div>
          <b>{msg('dashboard.settings.confirm_change_by_bank_transfer.transfer.account.title')}</b>
          <span className={QA_CLASSES.BANK_ACCOUNT_NUMBER}>
            {accountNumber}
          </span>
        </div>
        <div>
          <b>{msg('dashboard.settings.confirm_change_by_bank_transfer.transfer.title.title')}</b>
          {transferTitleText}
          {formType === C.BANK_ACCOUNT && (
          <span className={QA_CLASSES.CLIENT_IDENTIFICATION_NUMBER}>
            {clientIdentificationNumber}
          </span>
          )}
        </div>
      </BodyText>
      <Button className={classnames(classes.close, QA_CLASSES.CHANGE_BY_BANK_TRANSFER_CLOSE)} to={R.CLIENT_SETTINGS}>
        {msg('dashboard.settings.confirm_change_by_bank_transfer.close')}
      </Button>
    </>
  );
};

ChangeByBankTransferConfirm.styleRoot = 'ChangeByBankTransferConfirm';
export default compose<any>(connectState({
  bank: ['settings', 'bank'],
  clientNumber: ['api', 'fetch', 'client', 'query', 'data', 'number'],
}), connectActions({
  selectBank: ['settings', 'selectBank'],
}), injectStyles(styles))(ChangeByBankTransferConfirm);
