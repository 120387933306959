
import { compose } from 'redux';
import { connectActions, connectState } from '../../../connect';
import { Map } from 'immutable';
import { useEffect } from 'react';
import { useTranslator } from '../../../../localizations';
import getCurrentSchedulePath from './getCurrentSchedulePath';
import LoanSchedule from '../LoanSchedule';
import Modal from '../Modal';
import type { ImmutableMap } from '../../../../common/lib/types';

type Props = {
  fetchApplicableLoanSchedule: (...args: Array<any>) => any;
  schedule: ImmutableMap | null | undefined;
  // Own props
  open: boolean;
  onClose: (...args: Array<any>) => any;
  context: 'calculator' | 'application' | 'proposal';
};

const ScheduleModal = (props: Props): JSX.Element => {
  const {
    open,
    onClose,
    schedule,
    fetchApplicableLoanSchedule,
    context,
  } = props;
  const {
    msg,
  } = useTranslator();

  useEffect(() => {
    if (open) {
      fetchApplicableLoanSchedule(context);
    }
  }, [open, context]);

  return (
    <Modal open={open} onClose={onClose} title={msg('schedule.modal_title')}>
      <LoanSchedule schedule={schedule && Map({
        items: schedule,
      })}
      />
    </Modal>
  );
};

ScheduleModal.styleRoot = 'ScheduleModal';
export default compose<any>(connectActions({
  fetchApplicableLoanSchedule: ['registration', 'fetchApplicableLoanSchedule'],
}), connectState((props, state) => {
  const isLoggedIn = state.authentication.get('isLoggedIn');
  const application = state.api.getIn(['fetch', 'client', 'application', 'query', 'data']);

  return application || !isLoggedIn ? {
    schedule: getCurrentSchedulePath(props, state),
  } : {};
}))(ScheduleModal);
