export default (({
  colors,
}) => ({
  selectAll: {
    margin: '16px 0',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        height: '12px',
        marginRight: '16px',
        '& path': {
          fill: colors.primary,
        },
      },
    },
  },
  hidden: {
    display: 'none',
  },
  legalNotice: {
    fontSize: '12px',
    padding: '0 0 14px 35px',
  },
}));
