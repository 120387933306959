
import { BodyText } from '../typography';
import { useTranslator } from '../../../../localizations';
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import Loading from '../Loading';
import QA_CLASSES from '../../../lib/qa';
import styles from './LoanSchedule.jss';
import type { ImmutableMap } from '../../../../common/lib/types';

type Props = {
  classes: Record<string, any>;
  schedule: ImmutableMap | null | undefined;
};

const LoanSchedule = (props: Props): JSX.Element => {
  const {
    classes,
    schedule,
  } = props;
  const {
    msg,
    formatDate,
    numberToCurrency,
  } = useTranslator();

  if (!schedule) {
    return <Loading />;
  }

  const items = schedule.get('items');
  const options = {
    precision: 2,
  };

  return (
    <BodyText className={classnames(classes.root, QA_CLASSES.LOAN_SCHEDULE_TABLE)}>
      <div className={classnames(classes.row, 'header')}>
        <span>
          {msg('dashboard.loan.active.dueDate')}
        </span>
        <span className={classes.mobileHidden}>
          {msg('dashboard.loan.active.principal')}
        </span>
        <span className={classes.mobileHidden}>
          {msg('dashboard.loan.active.interest')}
        </span>
        <span className={classes.mobileHidden}>
          {msg('dashboard.loan.active.commission')}
        </span>
        <span>
          {msg('dashboard.loan.active.installment')}
        </span>
      </div>
      {items.map((i, index) => (
        <div className={classnames(classes.row, QA_CLASSES.AMOUNTS_FIELD)} key={index}>
          <span className={QA_CLASSES.SCHEDULE_DUE_DATE}>
            {formatDate(i.get('dueDate'))}
          </span>
          <span className={classnames(classes.mobileHidden, QA_CLASSES.SCHEDULE_ITEM_CAPITAL)}>
            {numberToCurrency(i.get('principalAmount'), options)}
          </span>
          <span className={classnames(classes.mobileHidden, QA_CLASSES.SCHEDULE_INTEREST_AMOUNT)}>
            {numberToCurrency(i.get('interestAmount'), options)}
          </span>
          <span className={classnames(classes.mobileHidden, QA_CLASSES.SCHEDULE_INITIAL_COMMISSION)}>
            {numberToCurrency(i.get('initialCommissionAmount'), options)}
          </span>
          <span className={QA_CLASSES.SCHEDULE_TOTAL_AMOUNT_LEFT}>
            {numberToCurrency(i.get('status') === 'PAID' ? 0 : i.get('totalAmount'), options)}
          </span>
        </div>
      ))}
    </BodyText>
  );
};

LoanSchedule.styleRoot = 'LoanSchedule';
export default injectStyles(styles)(LoanSchedule);
