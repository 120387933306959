
import { compose } from 'redux';
import { connectActions } from '../../../../connect';
import { Section } from '../../../../shared/components/layout';
import { useEffect } from 'react';
import Loading from '../../../../shared/components/Loading';

type Props = {
  creditCheck: (...args: Array<any>) => any;
};

const CreditCheck = (props: Props): JSX.Element => {
  useEffect(() => {
    props.creditCheck();
  }, []);

  return (
    <Section gray>
      <Loading />
    </Section>
  );
};

export default compose<any>(connectActions({
  creditCheck: ['registration', 'creditCheck'],
}))(CreditCheck);
