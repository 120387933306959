import './styles.css';
import '4finance-chatbot-pl/dist/styles.css';

import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import StaticChatbot from './StaticChatbot';
import useContentContext from '../../../lib/content/useContentContext';

type ClientData = {
  client: any;
  latestLoan: any;
};

async function fetchClientData(): Promise<ClientData> {
  const clientResponse = await fetch('/webapi/client');

  if (clientResponse.ok) {
    const loanResponse = await fetch('/webapi/client/loans/latest');

    if (loanResponse.ok) {
      return {
        client: clientResponse.json(),
        latestLoan: await loanResponse.json(),
      };
    }
  }

  return {
    client: undefined,
    latestLoan: undefined,
  };
}

type Props = {
  configOverride?: Record<string, any>;
};

const StandaloneChatbot = (props: Props) => {
  const {
    configOverride,
  } = props;
  const {
    chatbot,
  } = useContentContext();
  // eslint-disable-next-line space-infix-ops
  const [clientData, setClientData] = useState<ClientData>({
    client: undefined,
    latestLoan: undefined,
  });

  useEffect(() => {
    (async () => {
      setClientData(await fetchClientData());
    })();
  }, []);
  const updatePath = useCallback((path: string) => {
    window.location.href = path;
  }, []);
  const msg = useCallback((path: string) => {
    return get({
      chatBot: chatbot,
    }, path);
  }, []);

  return <StaticChatbot {...clientData} msg={msg} updatePath={updatePath} configOverride={configOverride} />;
};

export default StandaloneChatbot;
