import { useEffect, useRef, useState } from 'react';
import type { ContentImage } from '../types';

type Props = {
  content: ContentImage;
  className?: string;
};

const Svg = (props: Props): JSX.Element => {
  const {
    content,
    ...elementProps
  } = props;
  const [html, setHtml] = useState(null);
  const cancelled = useRef();

  useEffect(() => {
    cancelled.current = false;

    (async () => {
      const data = await fetch(content.fields.file.url);
      const text = await data.text();

      if (!cancelled.current) {
        setHtml(text);
      }
    })();

    return () => {
      cancelled.current = true;
    };
  }, [content]);

  return html ? (
    <div
      {...elementProps}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  ) : null;
};

Svg.styleRoot = 'Svg';
export default Svg;
