export default ({ colors, mediaQueries }) => ({
  heading: {
    fontSize: '24px',
    lineHeight: 1.1,
    fontWeight: '500',
    '&.white': {
      color: colors.white,
    },
    '&.brown': {
      color: colors.brown,
    },
    '& b': {
      fontWeight: '700',
    },
    '&.bigger': {
      fontSize: '30px',
      [mediaQueries.breakpointTablet]: {
        fontSize: '26px',
      },
    },
    '&.lighter': {
      fontWeight: '300',
    },
    '&.centered': {
      textAlign: 'center',
    },
    '&.modalWidth': {
      maxWidth: '320px',
    },
  },
});
