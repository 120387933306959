export default (() => ({
  root: {
    '& label > div:first-child': {
      boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.2)',
    },
  },
  selectAll: {
    textAlign: 'center',
    display: 'block',
    margin: '0 auto',
  },
}));
