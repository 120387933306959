
import classnames from 'classnames';
import injectStyles, { OfferRow } from '4finance-components-pl';
import QA_CLASSES from '../../../lib/qa';
import styles from './Offer.jss';
import useContentContext from '../../../lib/content/useContentContext';
import useTranslator from '../../../../localizations/useTranslator';
import type { ApplicationOffer } from '../../requests/fetchOffer';

type Props = {
  classes: Record<string, any>;
  offer: ApplicationOffer;
  pending: boolean;
  short?: boolean;
};

const ANNUAL_PERCENTAGE_RATE = 'annual_percentage_rate';
const MONTHLY_PAYMENT = 'monthly_payment';
const NEW_PRINCIPAL = 'new_principal';
const TERM = 'term';
const TOTAL_COST_OF_CREDIT = 'total_cost_of_credit';
const TOTAL_REPAYABLE_AMOUNT = 'total_repayable_amount';

const Offer = (props: Props): JSX.Element => {
  const {
    classes,
    offer,
    pending,
    short,
  } = props;
  const {
    numberToCurrency,
    formatDate,
    termToMonths,
    numberToPercentage,
  } = useTranslator();
  const {
    calculator: content,
  } = useContentContext();
  const {
    principal,
    term,
    costOfCredit,
    monthlyPayment,
    repayableAmount,
    rrso,
    footnote,
  } = content ? content.fields
    : {
      principal: '',
      term: '',
      costOfCredit: '',
      monthlyPayment: '',
      repayableAmount: '',
      rrso: '',
      footnote: '',
    };

  const fields = [
    {
      fieldName: NEW_PRINCIPAL,
      qaClass: QA_CLASSES.OFFERED_TOTAL_PRINCIPAL,
      title: principal,
      ommitedIfShort: false,
    },
    {
      fieldName: TERM,
      qaClass: QA_CLASSES.OFFERED_TERM,
      title: term,
      ommitedIfShort: false,
    },
    {
      fieldName: TOTAL_COST_OF_CREDIT,
      qaClass: QA_CLASSES.OFFERED_TOTAL_COST_OF_CREDIT,
      title: costOfCredit,
      ommitedIfShort: true,
    },
    {
      fieldName: TOTAL_REPAYABLE_AMOUNT,
      qaClass: QA_CLASSES.OFFERED_TOTAL_REPAYABLE_AMOUNT,
      title: repayableAmount,
      ommitedIfShort: true,
    },
    {
      fieldName: ANNUAL_PERCENTAGE_RATE,
      qaClass: QA_CLASSES.OFFERED_RRSO,
      title: rrso,
      ommitedIfShort: true,
    },
    {
      fieldName: MONTHLY_PAYMENT,
      qaClass: QA_CLASSES.OFFERED_MONTHLY_PAYMENT,
      title: monthlyPayment,
      ommitedIfShort: false,
    },
  ];

  const fieldValues = {
    [NEW_PRINCIPAL]: () => numberToCurrency(offer.newPrincipal, { precision: 2 }),
    [TERM]: () => termToMonths(offer.term),
    [TOTAL_COST_OF_CREDIT]: () => numberToCurrency(offer.totalCostOfCredit),
    [TOTAL_REPAYABLE_AMOUNT]: () => numberToCurrency(offer.totalRepayableAmount),
    [ANNUAL_PERCENTAGE_RATE]: () => numberToPercentage(offer.annualPercentageRate),
    [MONTHLY_PAYMENT]: () => numberToCurrency(offer.monthlyPayment),
  };

  const getFieldValue = (fieldName: string): Record<string, any> => fieldValues[fieldName](offer);

  const getDiscount = (fieldName: string): Record<string, any> | null => {
    const { discountAmount } = offer;
    const discount = {};

    if (discountAmount) {
      if (fieldName === TOTAL_COST_OF_CREDIT) {
        return {
          hasDiscount: true,
          discountAmount: numberToCurrency(discountAmount + offer.totalCostOfCredit, { precision: 2 }),
        };
      }
      if (fieldName === TOTAL_REPAYABLE_AMOUNT) {
        return {
          hasDiscount: true,
          discountAmount: numberToCurrency(discountAmount + offer.totalRepayableAmount, { precision: 2 }),
        };
      }
      if (fieldName === MONTHLY_PAYMENT) {
        return {
          hasDiscount: true,
          discountAmount: numberToCurrency((discountAmount / offer.term) + offer.monthlyPayment, { precision: 2 }),
        };
      }
    }

    return Object.keys(discount).length > 0 ? discount : null;
  };

  // eslint-disable-next-line react/no-unused-prop-types
  const renderRow = ({ fieldName, qaClass, title }:{ fieldName: string, qaClass: string, title: string }) => {
    const hasDiscount = offer.discountAmount > 0;
    const discount: any = hasDiscount ? getDiscount(fieldName) : null;
    const value = getFieldValue(fieldName);

    return (
      <OfferRow
        key={fieldName}
        className={qaClass}
        title={title}
        discount={discount}
        loading={pending}
        last={fieldName === MONTHLY_PAYMENT}
      >
        {value}
      </OfferRow>
    );
  };

  const fieldsToRender = short ? fields.filter(field => !field.ommitedIfShort) : fields;

  return content && offer ? (
    <div className={classnames(classes.container, QA_CLASSES.OFFER)}>
      {fieldsToRender.map(field => (
        renderRow(field)
      ))}
      {!short && (
      <div className={classes.footnote}>
        {footnote}
        <br />
        <span className={QA_CLASSES.FIRST_INSTALLMENT_DATE}>
          {formatDate(offer.firstPaymentDate)}
        </span>
      </div>
      )}
    </div>
  ) : <div className={classnames(classes.container, QA_CLASSES.OFFER)} />;
};

Offer.styleRoot = 'Offer';
export default injectStyles(styles)(Offer);
