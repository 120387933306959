import { compose } from 'redux';
import { connectActions, connectState } from '../../../../connect';
import { omit, pick } from 'lodash';
import { useCallback } from 'react';
import classnames from 'classnames';
import injectStyles, { RadioInput } from '4finance-components-pl';
import QA_CLASSES from '../../../../lib/qa';
import styles from './BankOption.jss';

type Props = {
  classes: Record<string, any>;
  // Own props
  value: string;
  setBank: (...args: Array<any>) => any;
  current: {
    name: string;
  } | null;
  className?: string;
  text?: string;
  method: 'pennyTransfer';
  data: {
    accountNumber?: string;
    clientIdentificationNumber?: string;
    receiver?: string;
    homePageUrl?: string;
    ideentification?: string;
  };
};

const BankOption = (props: Props): JSX.Element => {
  const { classes, className, value, current, setBank, text, data, method } = props;
  const onChange = useCallback(
    ({ value: bank }) => {
      setBank({
        name: bank,
        ...pick(data, ['accountNumber', 'clientIdentificationNumber', 'receiver', 'homePageUrl', 'identification']),
      });
    },
    [data],
  );

  return (
    <div className={classnames(classes.root, QA_CLASSES.BANK_OPTION, className)} data-bank={value}>
      <RadioInput
        value={value}
        onChange={onChange}
        classes={omit(classes, ['root'])}
        selected={current && current.name === value}
        text={text}
      />
    </div>
  );
};

BankOption.styleRoot = 'BankOption';
export default compose<any>(
  connectState({
    current: ['registration', 'bank'],
  }),
  connectActions({
    setBank: ['registration', 'setBank'],
  }),
  injectStyles(styles),
)(BankOption);
