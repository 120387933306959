export default (({
  colors,
}: any): any => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    '&.transparent': {
      backgroundColor: 'transparent',
    },
  },
}));
