import * as R from '../../../../routes/routesList';

import { BodyText } from '../../../../shared/components/typography';
import { compose } from 'redux';
import { connectState } from '@browser/connect';
import { PERSONAL_ID_RESTRICTED } from '@common/lib/constants';
import { Section } from '../../../../shared/components/layout';
import { useTranslator } from '../../../../../localizations';
import ApplicationPESELRestricted from '@dynamic/registration/components/ApplicationPESELRestricted';
import Button from '../../../../shared/components/Button';
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import QA_CLASSES from '../../../../lib/qa';
import styles from './ApplicationFailed.jss';

type Props = {
  classes: Record<string, any>;
  resolution: string;
  resolutionDetail?: string | null,
  resolutionMessage?: string | null
};

const ApplicationFailed = (props: Props): JSX.Element => {
  const {
    classes,
    resolution,
    resolutionDetail,
    resolutionMessage,
  } = props;
  const {
    msg,
  } = useTranslator();

  const isTechnicalError = resolution === 'REJECTED' && resolutionDetail === 'ERROR' && resolutionMessage === 'not enough data';

  const isPESELRestricted = resolutionDetail === PERSONAL_ID_RESTRICTED;

  if (isPESELRestricted) {
    return <ApplicationPESELRestricted />;
  }

  return (
    <Section title={msg(isTechnicalError ? 'registration.application_failed.technicalError' : 'registration.application_failed.title')} headingClass={QA_CLASSES.LOAN_DENIED_HEADING} separator gray>
      <BodyText className={classnames(QA_CLASSES.LOAN_DENIED_TEXT, classes.bodyText)}>
        {msg('registration.application_failed.heading')}
      </BodyText>
      <Button to={R.DASHBOARD}>
        {msg('actions.next')}
      </Button>
    </Section>
  );
};

ApplicationFailed.styleRoot = 'ApplicationFailed';
export default compose<any>(
  connectState({
    resolution: ['api', 'fetch', 'client', 'application', 'query', 'data', 'resolution'],
    resolutionDetail: ['api', 'fetch', 'client', 'application', 'query', 'data', 'resolutionDetail'],
    resolutionMessage: ['api', 'fetch', 'client', 'application', 'query', 'data', 'resolutionMessage'],
  }),
  injectStyles(styles),
)(ApplicationFailed);
