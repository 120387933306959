export default ({ colors }: { colors: Record<string, unknown> }): Record<string, unknown> => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '20px',
    height: '20px',
    backgroundSize: '100% auto',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    margin: '0 16px 0 8px',
    '& *': {
      fill: colors.white,
    },
    '& svg': {
      height: '20px',
      width: '20px',
      '& *': {
        fill: colors.white,
      },
    },
  },
  link: {
    color: colors.white,
    opacity: 0.6,
    fontSize: '14px',
    textDecoration: 'none',
    lineHeight: 2,
    width: '100%',
    '&:hover': {
      color: colors.white,
      opacity: 1,
      textDecoration: 'none',
    },
  },
  image: {
    maxWidth: '100%',
    display: 'flex',
    backgroundRepeat: 'no-repeat',
    padding: '12px 0',
    height: '72px',
    '& *': {
      maxHeight: '100%',
    },
  },
});
