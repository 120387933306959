export default ({ colors, mediaQueries }) => ({
  row: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr',
    borderBottom: `1px solid ${colors.gray}`,
    gridColumnGap: '4px',
    padding: '8px 0',
    fontSize: '12px',
    [mediaQueries.breakpointSmall]: {
      gridTemplateColumns: '2fr 0fr 0fr 0fr 1fr',
    },
    '& span': {
      textAlign: 'center',
      '&:last-child': {
        textAlign: 'right',
      },
      '&:first-child': {
        textAlign: 'left',
      },
    },
    '&.header': {
      fontWeight: 600,
      fontSize: '14px',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  header: {
    display: 'flex',
    fontWeight: '600',
    justifyContent: 'space-between',
  },
  mobileHidden: {
    [mediaQueries.breakpointSmall]: {
      overflow: 'hidden',
    },
  },
});
