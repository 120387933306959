import * as R from '../../routes/routesList';

import { BodyText } from '../../shared/components/typography';
import { compose } from 'redux';
import { connectActions, connectPending, connectState } from '../../connect';
import { connectField, connectSubmit, Form } from '4finance-onion-form-pl';
import { email, isRequired, maxLength, minLength, password } from '4finance-validation-pl';
import { Link } from 'react-router-dom';
import { SUBMIT_LOGIN } from '../../../common/authentication/actions';
import { translate, useTranslator } from '../../../localizations';
import { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../shared/components/Button';
import getCaptchaKey from '@browser/lib/getCaptchaKey';
import injectStyles, { TextField } from '4finance-components-pl';
import PageHeader from '../../shared/components/PageHeader';
import QA_CLASSES from '../../lib/qa';
import Reaptcha from 'reaptcha';
import Section from '../../shared/components/layout/Section';
import styles from './Login.jss';

type Props = {
  classes: Record<string, any>;
  clearLoginError: () => void;
  submitLogin: (...args: Array<any>) => any;
  loginError: string | null;
  submitting: boolean;
};
export const Username = translate(connectField('username', {
  type: 'email',
  value: '',
}, [isRequired(), maxLength(90), email()])(TextField));
export const Password = translate(connectField('password', {
  type: 'password',
  value: '',
  hint: ' ',
}, [isRequired(), minLength(6), maxLength(30), password({
  strong: false,
})])(TextField));
export const SubmitButton = connectSubmit(Button);

const Login = (props: Props) => {
  const {
    classes,
    submitLogin,
    loginError,
    clearLoginError,
    submitting,
  } = props;
  const {
    msg,
  } = useTranslator();
  const [isRecaptchaRendered, setIsRecaptchaRendered] = useState<boolean>(false);
  const values = useRef({ username: '', password: '' });

  const onUsernameChange = ({ value }: { value: string }) => {
    values.current.username = value;
  };

  const onPasswordChange = ({ value }: { value: string }) => {
    if (loginError) {
      clearLoginError();
    }
    values.current.password = value;
  };

  const onCaptchaRender = (): void => {
    setIsRecaptchaRendered(true);
  };

  const captcha = useRef();

  const onSubmit = (): void => {
    captcha.current.execute();
  };

  const onCaptchaExpire = (): void => { captcha.current?.reset(); };

  const onCaptchaVerify = async (responseToken: string) => {
    await submitLogin({
      username: values.current.username,
      password: values.current.password,
      redirect: '/dashboard',
      responseToken,
    });

    captcha.current?.reset();
  };

  return (
    <Section gray noTopPadding>
      <PageHeader title={msg('sign_in.title')} />
      <div className={classes.formContainer}>
        <Form name={'login'} onSubmit={onSubmit}>
          <Username bottomMargin={false} onChange={onUsernameChange} />
          <Password topMargin={false} onChange={onPasswordChange} />
          {loginError && (
            <BodyText className={classes.error}>
              {msg(`sign_in.submitErrors.${loginError}`) || msg('sign_in.submitErrors.fallback')}
            </BodyText>
          )}
          <SubmitButton type="submit" className={QA_CLASSES.LOGIN_SUBMIT_BUTTON} disabled={submitting || !isRecaptchaRendered}>
            {msg('sign_in.button')}
          </SubmitButton>
        </Form>
        <Link to={R.PASSWORD_RECOVERY} className={classes.recovery}>
          {msg('sign_in.password_recovery')}
        </Link>
        <div className={classes.recaptchaWrapper}>
          <Reaptcha
            ref={captcha}
            sitekey={getCaptchaKey()}
            onVerify={onCaptchaVerify}
            onRender={onCaptchaRender}
            onExpire={onCaptchaExpire}
            size="invisible"
            badge="inline"
          />
        </div>
      </div>
    </Section>
  );
};

Login.styleRoot = 'Login';
export default compose<any>(connectActions({
  submitLogin: ['authentication', 'submitLogin'],
  clearLoginError: ['authentication', 'clearLoginError'],
}), connectState({
  loginError: ['authentication', 'loginError'],
}), connectPending({
  submitting: SUBMIT_LOGIN,
}), injectStyles(styles))(Login);
