
import { useTranslator } from '../../../../../localizations';
import Button from '../../../../shared/components/Button';
import injectStyles from '4finance-components-pl';
import styles from './VivusButton.jss';
import VivusIcon from '../../../../svg/vivus-icon.svg';

const URL = 'https://www.vivus.pl/new?utm_source=zaplo.pl&utm_medium=site&utm_campaign=zaplo_register_button';

type Props = {
  classes: Record<string, any>;
};

const VivusButton = (props: Props): JSX.Element => {
  const {
    classes,
  } = props;
  const {
    msg,
  } = useTranslator();

  return (
    <div className={classes.root}>
      <a href={URL} target={'_blank'} rel={'noopener noreferrer'}>
        <Button className={classes.button} fullWidth>
          <VivusIcon />
          {msg('registration.register.vivus_button.text')}
        </Button>
      </a>
      <div className={classes.legal}>
        {msg('registration.register.vivus_button.legal')}
      </div>
    </div>
  );
};

VivusButton.styleRoot = 'VivusButton';
export default injectStyles(styles)(VivusButton);
