
import { connectField } from '4finance-onion-form-pl';
import { createValidChars, email, equalToField, isRequired, LocalizedCharacterSet, minLength, regex } from '4finance-validation-pl'; // eslint-disable-line no-unused-vars

import { IoBlackBox as IoBlackBoxField } from '4finance-iovation-pl';
import { TextField } from '4finance-components-pl';
import { tidyCurrency,
  tidyIdentityCardNumber,
  tidyLettersSpaceAndHyphenOnly,
  tidyMobilePhone,
  tidyNumbersOnly,
  tidyPersonalId,
  tidyPostalCode,
  tidyStreetAndNumber,
} from '../../../common/lib/tidy';
import { translate } from '../../../localizations';
import address from '../../../common/validations/address';
import altMessage from '../../../common/validations/altMessage';
import city from '../../../common/validations/city';
import currency from '../../../common/validations/currency';
import greaterThan from '../../../common/validations/greaterThan';
import identityCardNumber from '../../../common/validations/identityCardNumber';
import isRequiredAltMessage from '../../../common/validations/isRequiredAltMessage';
import lastName from '../../../common/validations/lastName';
import maxLength from '../../../common/validations/maxLength';
import mobilePhone from '../../../common/validations/mobilePhone';
import notOnlyHyphens from '../../../common/validations/notOnlyHyphens';
import noUnderscoreInDomain from '../../../common/validations/noUnderscroreInDomain';
import numbersOnly from '../../../common/validations/numbersOnly';
import passwordBackCompatible from '../../../common/validations/passwordBackCompatible';
import passwordStrict from '@common/validations/passwordStrict';
import personalId from '../../../common/validations/personalId';
import SelectField from '../components/SelectField';
import smallerThan from '../../../common/validations/smallerThan';

const {
  letters,
  lettersAndSpace,
} = createValidChars('pl');
const characterSet = new LocalizedCharacterSet('pl', true);
const employerName = characterSet.add(...'.,-&/+-\\ ', '\\s').addRange(0, 9).toValidator('invalid_employer_name');

function translateField(name: string, defaultProps: Record<string, any>, validations: ((...args: Array<any>) => any)[]) {
  return (FieldComponent: React.ComponentType<any>) => translate(connectField(name, defaultProps, validations)(FieldComponent));
}

export const PlaceOfBirth = translateField('placeOfBirth', {}, [isRequired(), lettersAndSpace()])(TextField);
export const FirstName = translateField('firstName', {}, [isRequired(), minLength(2), maxLength(90), letters(), notOnlyHyphens()])(TextField);
export const LastName = translateField('lastName', {}, [isRequired(), minLength(2), maxLength(30), lastName()])(TextField);
export const PersonalId = translateField('personalId', {
  type: 'tel',
  tidy: ['trim', tidyPersonalId],
}, [isRequiredAltMessage('errorMasculine'), maxLength(11, 'personal_id'), personalId()])(TextField);
export const BirthCountry = translateField('placeOfBirth', {}, [isRequired()])(SelectField);
export const IdentityCardNumber = translateField('identityCardNumber', {
  tidy: ['uppercase', tidyIdentityCardNumber],
}, [isRequiredAltMessage('errorPlural'), identityCardNumber(), maxLength(10)])(TextField);
export const MobilePhone = translateField('mobilePhone', {
  type: 'tel',
  tidy: [tidyNumbersOnly, tidyMobilePhone],
}, [isRequiredAltMessage('errorMasculine'), mobilePhone(), numbersOnly(), maxLength(9)])(TextField);
export const StreetAndNumber = translateField('streetAndHouseFlatNumber', {
  tidy: [tidyStreetAndNumber],
}, [isRequiredAltMessage('errorPlural'), address(), minLength(3), maxLength(90)])(TextField);
export const City = translateField('city', {
  tidy: [tidyLettersSpaceAndHyphenOnly],
}, [isRequiredAltMessage('errorFeminine'), city(), minLength(2), maxLength(40)])(TextField);
export const PostalCode = translateField('postalCode', {
  tidy: [tidyPostalCode],
}, [isRequiredAltMessage('errorMasculine'), regex(/^\d{2}[-]?\d{3}$/), maxLength(6)])(TextField);
export const Email = translateField('email', {
  tidy: ['trim'],
}, [isRequiredAltMessage('errorMasculine'), maxLength(90), email(), noUnderscoreInDomain()])(TextField);
export const ConfirmEmail = translateField('confirmEmail', {
  tidy: ['trim'],
}, [isRequired(), maxLength(90), email(), equalToField('email')])(TextField);
export const Password = translateField('password', {
  type: 'password',
}, [isRequired(), minLength(7), maxLength(30), passwordBackCompatible()])(TextField);
export const PasswordStrict = translateField('password', {
  type: 'password',
}, [isRequired(), minLength(10), maxLength(64), passwordStrict()])(TextField);
export const IoBlackBox = connectField('ioBlackBox')(IoBlackBoxField);
export const EmploymentStartMonth = translateField('employmentStartMonth', {
  width: 'half',
}, [isRequired()])(SelectField);
export const EmploymentStartYear = translateField('employmentStartYear', {
  width: 'xSmall',
}, [isRequiredAltMessage('errorMasculine'), numbersOnly()])(SelectField);
export const EmploymentStatus = translateField('employmentStatus', {}, [isRequired()])(SelectField);
export const EmployerIndustry = translateField('employerIndustry', {}, [isRequired()])(SelectField);
export const EmployerName = translateField('employerName', {}, [isRequiredAltMessage('errorFeminine'), employerName(), minLength(2), maxLength(255)])(TextField);
export const TotalExpense = translateField('totalExpense', {
  tidy: ['trim', tidyCurrency],
}, [isRequiredAltMessage('errorPlural'), // eslint-disable-next-line no-confusing-arrow
  altMessage(currency(), e => e === 'must_not_start_with_zero' ? 'expense_must_not_start_with_zero' : e), altMessage(greaterThan(9, true), 'expense_too_small'), smallerThan(1000000, 'expense')])(TextField);
export const TotalIncome = translateField('totalIncome', {
  tidy: ['trim', tidyCurrency],
}, [isRequiredAltMessage('errorMasculine'), currency(), greaterThan(9, true), smallerThan(1000000, 'income')])(TextField);
export const NumberOfInhabitants = translateField('numberOfInhabitants', {
  tidy: ['trim', tidyNumbersOnly],
}, [isRequiredAltMessage('errorFeminine'), altMessage(greaterThan(0), 'number_of_inhabitants_too_small'), numbersOnly(false), smallerThan(99, 'number_of_inhabitants')])(TextField);
