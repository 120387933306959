import * as R from '../../routes/routesList';
import { StandaloneCalculatorTerm } from '../../calculator';
import { useCallback, useEffect } from 'react';
import config from '4finance-configuration-pl';
import content from '../../../localizations/partials/proposition.json';
import LoadingScreen from './LoadingScreen';
import MainLayout from '../../shared/layouts/MainLayoutDynamic';
import Proposition from './Proposition';
import sendGTMEvent from '../../../common/lib/sendGTMEvent';
import useAuthentication from '../../lib/hooks/useAuthentication';

const Homepage = () => {
  const isBrowser = typeof window !== 'undefined';

  if (isBrowser) {
    const currentPath = window.location.href;
    const shouldRedirect = currentPath.indexOf('client') > -1 && config.environment === 'production';

    if (shouldRedirect) {
      window.location = R.ROOT_EXTERNAL;

      return <LoadingScreen />;
    }
  }

  const isLoggedIn = useAuthentication();

  if (isLoggedIn && isBrowser) {
    window.location = R.DASHBOARD;
  }

  const onApply = useCallback(({
    amount,
    term,
  }) => {
    window.location.assign(`${R.REGISTER}?amount=${encodeURIComponent(amount)}&term=${encodeURIComponent(term)}`);
  }, []);

  useEffect(() => {
    sendGTMEvent({
      event: 'homepage',
    });
  }, []);

  return (
    <MainLayout noTopPadding>
      <Proposition content={content}>
        <StandaloneCalculatorTerm onApply={onApply} />
      </Proposition>
    </MainLayout>
  );
};

export default Homepage;
