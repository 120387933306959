import * as R from '../../../browser/routes/routesList';
import * as routerFlow from '../routerFlow';
import { actions as api } from '../../api';
import { push as updatePath } from 'connected-react-router';
import delay from '../../lib/delay';

function isStatusCheckComplete() {
  return async ({
    getApiResponse,
  }: Record<string, any>) => {
    const [client, application] = await Promise.all([getApiResponse(['fetch', 'client'], {
      forceFetch: true,
    }), getApiResponse(['fetch', 'client', 'application'], {
      forceFetch: true,
    })]);

    return application.status === 'CLOSED' || (client.status !== 'IDENTIFIED' && client.markedForManualIdentification);
  };
}

export function creditCheck() {
  return async ({
    dispatch,
    getApiResponse,
  }: Record<string, any>) => {
    const {
      error,
    } = await dispatch(api.patchClientApplicationCheck());

    if (error) {
      throw new Error('Unable to perform credit check.');
    }

    let retries = 1;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (retries > 300) {
        dispatch(updatePath(R.APPLICATION_IDENTIFICATION_FAILED));
        throw new Error('Max retries exceeded.');
      }

      const isComplete = await dispatch(isStatusCheckComplete());

      if (isComplete.payload) {
        break;
      }

      await delay(1000);
      retries++;
    }

    dispatch(updatePath(await routerFlow.getNextRouteAfterApplicationCreditCheck(getApiResponse)));
  };
}
