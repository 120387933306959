export default (() => ({
  root: {
    maxWidth: '600px',
  },
  details: {
    margin: '24px 0',
    '& b': {
      marginRight: '4px',
    },
  },
  marginBottom: {
    marginBottom: '24px',
  },
  message: {
    marginTop: '3px',
  },
}));
