import 'react-hot-loader';
import { loadableReady } from '@loadable/component';
import { Provider } from 'react-redux';
import App, { store } from './App';

import ReactDOM from 'react-dom';
import removeServerSideStylesheet from './lib/removeServerSideStylesheet';

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;

loadableReady(() => {
  renderMethod(
    <Provider store={store}>
      <App />
    </Provider>, document.getElementById('app'), removeServerSideStylesheet,
  );
});
