import * as DashboardSection from '../../../components/DashboardSection';
import * as R from '../../../../routes/routesList';

import { BodyText, SecondaryHeading } from '../../../../shared/components/typography';
import { compose } from 'redux';
import { connectState } from '../../../../connect';
import { useTranslator } from '../../../../../localizations';
import Background from '../../../../shared/components/layout/Background';
import Button from '../../../../shared/components/Button';
import classnames from 'classnames';
import getBankNameFromAccount from '../../../../lib/banks/getBankNameFromAccount';
import injectStyles from '4finance-components-pl';
import QA_CLASSES from '../../../../lib/qa';
import styles from './ConfirmBankAccount.jss';

type Props = {
  classes: Record<string, any>;
  bankAccount: string;
};

const ConfirmBankAccount = (props: Props): JSX.Element => {
  const {
    classes,
    bankAccount,
  } = props;
  const {
    msg,
  } = useTranslator();
  const bankName = getBankNameFromAccount(bankAccount);

  return (
    <>
      <DashboardSection.Block className={classes.image}>
        <Background src={'/assets/img/hand-with-coin.svg'} position={'right'} size={'64% auto'} height={'100%'} />
      </DashboardSection.Block>
      <DashboardSection.Block yellow>
        <SecondaryHeading bigger className={classes.marginBottom}>
          {msg('dashboard.confirm_bank_account.title')}
        </SecondaryHeading>
        <BodyText className={classes.marginBottom}>
          {msg('dashboard.confirm_bank_account.text')}
        </BodyText>
        <div className={classes.marginBottom}>
          <div className={classnames(classes.field, QA_CLASSES.BANK_ACCOUNT_NUMBER_FIELD)}>
            <div>{msg('dashboard.confirm_bank_account.bank_account')}</div>
            <div className={QA_CLASSES.BANK_ACCOUNT_NUMBER}>
              {bankAccount}
            </div>
          </div>
          {bankName && (
          <div className={classnames(classes.field, QA_CLASSES.BANK_NAME_FIELD)}>
            <div>{msg('dashboard.confirm_bank_account.bank_name')}</div>
            <div className={QA_CLASSES.BANK_NAME}>
              {bankName}
            </div>
          </div>
          )}
        </div>
        <Button to={R.PROFILE} fullWidth className={classnames(classes.marginBottom, QA_CLASSES.CORRECT_BANK_ACCOUNT_NUMBER_BUTTON)}>
          {msg('dashboard.confirm_bank_account.confirm')}
        </Button>
        <Button to={R.CHANGE_BANK_ACCOUNT_NUMBER} fullWidth secondary className={QA_CLASSES.CHANGE_BANK_ACCOUNT_NUMBER_BUTTON}>
          {msg('dashboard.confirm_bank_account.change')}
        </Button>
      </DashboardSection.Block>
    </>
  );
};

ConfirmBankAccount.styleRoot = 'ConfirmBankAccount';
export default compose<any>(connectState({
  bankAccount: ['api', 'fetch', 'client', 'query', 'data', 'bankAccount'],
}), injectStyles(styles))(ConfirmBankAccount);
