export const ERROR = 'too_large';
export default function smallerThan(number: number, prefix?: string) {
  return (value: string) => {
    if (Number(value) >= number) {
      return `${prefix ? `${prefix}_` : ''}${ERROR}`;
    }

    return null;
  };
}
