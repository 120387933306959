export default ({ colors }) => ({
  calculatorBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    marginBottom: '16px',
  },
  sliderContainer: {
    padding: 0,
  },
  footnote: {
    fontSize: '12px',
    color: colors.gray,
  },
  button: {
    marginTop: '24px',
  },
  offerContainer: {
    backgroundColor: `${colors.veryLightGray} !important`,
    padding: '24px',
  },
  legal: {
    textAlign: 'center',
    display: 'block',
    width: '100%',
  },
  detailsHeading: {
    marginBottom: '24px',
  },
  changesSeparator: {
    height: '16px',
    backgroundColor: colors.veryLightGray,
  },
});
