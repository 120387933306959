import * as R from '../../../../routes/routesList';

import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { useTranslator } from '../../../../../localizations';
import Consents from '../Consents';
import injectStyles from '4finance-components-pl';
import Modal from '../../../../shared/components/Modal';
import styles from '../Change.jss';

const ChangeConsents = (): JSX.Element => {
  const {
    msg,
  } = useTranslator();
  const history = useHistory();

  const goBack = () => history.push(R.CLIENT_SETTINGS);

  return (
    <Modal title={msg('dashboard.settings.change_consents.title')} open onClose={goBack}>
      <Consents set={'Marketing'} />
    </Modal>
  );
};

ChangeConsents.styleRoot = 'ChangeConsents';
export default compose<any>(injectStyles(styles))(ChangeConsents);
