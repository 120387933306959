/* eslint-disable no-else-return */
import passwordStrict from './passwordStrict';

function passwordStrictPasswordRecovery() {
  return (value: string): string | null => {
    const passwordValidation = passwordStrict();

    if (value.length < 10) {
      return 'too_short';
    } else if (value.length > 64) {
      return 'too_long';
    }

    return passwordValidation(value);
  };
}

export default passwordStrictPasswordRecovery;
