
type Props = {
  content: string;
};

const Cnt = ({
  content,
}: Props) => (
  <span
    className="cnt"
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
);

export default Cnt;
