import * as C from '@common/lib/constants';
import * as R from '@routes/routesList';

import { Route } from 'react-router-dom';
import Dashboard from '@browser/dynamic/dashboard';
import DocumentsModal from '@browser/dynamic/components/DocumentsModal';
import MainLayoutDynamic from '@shared/layouts/MainLayoutDynamic';
import Settings from '@browser/dynamic/settings';

const Router = () => (
  <MainLayoutDynamic navShadow logoUrl={R.DASHBOARD}>
    <Route path={R.AGREEMENTS} exact component={() => <DocumentsModal />} />
    <Route path={R.DASHBOARD} exact component={Dashboard} />
    <Route path={R.PAYMENT} exact component={Dashboard} />
    <Route path={R.APPLICATION_CONFIRM_BANK_ACCOUNT} exact component={Dashboard} />
    <Route path={R.EXTENSION} exact component={Dashboard} />
    <Route path={R.APPLICATION_ADDITIONAL_AMOUNT} exact component={Dashboard} />

    <Route path={R.CLIENT_SETTINGS} exact component={Settings} />
    <Route path={R.CHANGE_EMAIL} exact component={() => <Settings formType={C.EMAIL} />} />
    <Route path={R.CHANGE_EMAIL_CONFIRM} exact component={() => <Settings type={C.PHONE} />} />
    <Route path={R.CHANGE_EMAIL_SUCCESS} exact component={() => <Settings type={C.EMAIL} />} />
    <Route path={R.CHANGE_ADDRESS} exact component={Settings} />
    <Route path={R.CHANGE_ADDRESS_SUCCESS} exact component={() => <Settings formType={C.ADDRESS} />} />
    <Route path={R.CHANGE_IDENTITY_CARD_NUMBER} exact component={Settings} />
    <Route path={R.CHANGE_ID_SUCCESS} exact component={() => <Settings formType={C.ID} />} />
    <Route path={R.CHANGE_PHONE} exact component={() => <Settings formType={C.PHONE} />} />
    <Route path={R.CHANGE_PHONE_CONFIRM} exact component={() => <Settings formType={C.PHONE} />} />
    <Route path={R.CHANGE_PHONE_SUCCESS} exact component={() => <Settings formType={C.PHONE} />} />
    <Route path={R.CHANGE_PASSWORD} exact component={Settings} />
    <Route path={R.CHANGE_PASSWORD_SUCCESS} exact component={() => <Settings formType={C.PASSWORD} />} />
    <Route path={R.CHANGE_BANK_ACCOUNT_NUMBER} exact component={Settings} />
    <Route path={R.CHANGE_NAME} exact component={Settings} />
    <Route path={R.CONFIRM_NAME} exact component={Settings} />
    <Route path={R.CONFIRM_BANK_ACCOUNT} exact component={Settings} />
    <Route path={R.CHANGE_CONSENTS} exact component={Settings} />
  </MainLayoutDynamic>
);

export default Router;
