export const ERROR = 'letters_and_hyphen_only';

const CONSTRUCTED_REGEX = new RegExp('[A-ZĄŚĘĆŹŻŁÓŃ][-]{0,1}[A-ZĄŚĘĆŹŻŁÓŃ]+$', 'i');

// validation that allows for the customer to enter a name consisting of either
// one word or multiple words separated by a single hyphen
export default function lastName() {
  return (value: string): string | null => {
    if (value && !CONSTRUCTED_REGEX.test(value)) {
      return ERROR;
    }

    return null;
  };
}
