import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useEffect, useRef } from 'react';

type Props = {
  location: Location;
};

const ScrollToTop = ({
  location,
}: Props & RouteComponentProps): JSX.Element => {
  // eslint-disable-next-line no-self-compare,no-undef,space-infix-ops
  const previousLocation = useRef<string | null>(null);

  useEffect(() => {
    if (previousLocation.current !== location.pathname) {
      window.scrollTo(0, 0);
      previousLocation.current = location.pathname;
    }
  }, [location]);

  return null;
};

export default withRouter(ScrollToTop);
