import { compose } from 'redux';
import { connectState } from '../../../connect';
import { List as ImmutableList } from 'immutable';
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';

import styles from './PageLoader.jss';

const excludedUrls: string[] = [// Do not show page loader on some specific pages
];
const excludedActions: string[] = [// Do not show page loader when there is specific actions
  'CALCULATOR_CHANGE', 'FETCH_CLIENT_APPLICATION_OFFER'];

type Props = {
  classes: Record<string, any>;
  pathname: string;
  pendingActions: ImmutableList<any>;
};

const PageLoader = (props: Props) => {
  const {
    pathname,
    classes,
    pendingActions,
  } = props;
  const hasPendingActions = !!pendingActions.size;
  const modifiedPendingActions = hasPendingActions && pendingActions.filterNot(item => excludedActions.includes(item));
  const visible = !!modifiedPendingActions.size && !excludedUrls.includes(pathname);

  return (
    <div className={classnames(classes.container, visible && classes.visible)}>
      <div className={classnames(classes.line, classes.first)} />
      <div className={classnames(classes.line, classes.second)} />
      <div className={classnames(classes.line, classes.third)} />
      <div className={classnames(classes.line, classes.fourth)} />
    </div>
  );
};

PageLoader.styleRoot = 'PageLoader';
export default compose<any>(connectState({
  pendingActions: ['pendingActions'],
}), injectStyles(styles))(PageLoader);
