import { createSelector } from '4finance-connect-pl';

const apiSelector = state => state.api;

export const masterAccount = createSelector([apiSelector], api => {
  const banks = api.getIn(['fetch', 'client', 'banks', 'query', 'data', 'templates']);

  if (!banks) {
    return null;
  }

  return banks.find(b => b.get('masterAccount'));
});
