import { createContext } from 'react';

type Context = {
  LinkComponent: React.ComponentType<any> | string;
};
const defaultContext = {
  LinkComponent: 'a',
};
const ComponentContext = createContext<Context>(defaultContext);

export const ComponentProvider = ComponentContext.Provider;
export default ComponentContext;
