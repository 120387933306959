
import injectStyles from '4finance-components-pl';
import MainHeading from '../typography/MainHeading/MainHeading';
import Separator from '../Separator';
import styles from './PageHeader.jss';

type Props = {
  classes: Record<string, any>;
  title: string;
};

const PageHeader = (props: Props): JSX.Element => {
  const {
    classes,
    title,
  } = props;

  return (
    <div className={classes.container}>
      <MainHeading>{title}</MainHeading>
      <Separator />
    </div>
  );
};

PageHeader.styleRoot = 'PageHeader';
export default injectStyles(styles)(PageHeader);
