const ERROR = 'invalid_email';
const REGEX = new RegExp('_');

export default function noUnderscoreInDomain() {
  return (value: string | null | undefined): string | null | undefined => {
    if (value && value.includes('@')) {
      const domain: string = value.split('@')[1];

      return REGEX.test(domain) ? ERROR : null;
    }

    return null;
  };
}
