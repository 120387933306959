export default (({
  mediaQueries,
}) => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(100%, -32%)',
    height: '264px',
    width: '200px',
    display: 'block',
    [mediaQueries.breakpointLaptop]: {
      transform: 'translate(0, -105%)',
      height: '124px',
      width: '288px',
    },
    [mediaQueries.breakpointTablet]: {
      display: 'none',
    },
  },
}));
