export default function parseParams(searchString: string): Record<string, any> {
  const parsedParams: Record<string, any> = {};
  const searchTerm: any = searchString.replace('?', '').split('&');

  searchTerm.forEach((par: string, i: number) => {
    searchTerm[i] = par.split('=');
    parsedParams[searchTerm[i][0]] = Number(searchTerm[i][1]);
  });

  return parsedParams;
}
