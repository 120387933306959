const ERROR_TOO_LONG = 'too_long';
const ERROR_NUMBERS_ONLY = 'numbers_only';
const REGEX = new RegExp('^[0-9]+$');

export default function phoneNumberValidation() {
  return (value: string) => {
    if (value && value.length > 9) {
      return ERROR_TOO_LONG;
    }

    if (!REGEX.test(value)) {
      return ERROR_NUMBERS_ONLY;
    }

    return null;
  };
}
