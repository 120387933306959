
import { BodyText } from '../../../shared/components/typography';
import classnames from 'classnames';
import injectStyles, { Container } from '4finance-components-pl';
import styles from './Message.jss';

type Props = {
  classes: Record<string, any>;
  // Own props
  className?: string;
  title?: string;
  body: string;
};

const Message = (props: Props): JSX.Element => {
  const {
    classes,
    className,
    title,
    body,
  } = props;

  return (
    <BodyText className={classnames(classes.root, className)}>
      <Container className={classes.container}>
        {title && (
        <b className={classes.title}>
          {title}
        </b>
        )}
        {body}
      </Container>
    </BodyText>
  );
};

Message.styleRoot = 'Message';
export default injectStyles(styles)(Message);
