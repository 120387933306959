
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import styles from './Loading.jss';

type Props = {
  classes: Record<string, any>;
  className?: string;
};

const Loading = (props: Props): JSX.Element => {
  const {
    classes,
    className,
  } = props;

  return (
    <div className={classnames(classes.root, className)}>
      <div className={classes.innerContainer}>
        {Object.keys([...Array(5)]).map(k => <span key={k} />)}
      </div>
    </div>
  );
};

Loading.styleRoot = 'Loading';
export default injectStyles(styles)(Loading);
