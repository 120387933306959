import { connectActions } from '../connect';
import { Control, Loader } from './components';
import { Diff } from 'utility-types';
import StaticCalculator from './StaticCalculator';
import type { PassedProps } from './components';
import type { Props as StaticCalculatorPropsT } from './StaticCalculator';

type StaticCalculatorPassedProps = Diff<PassedProps, {
  isInitialLoading: boolean;
  isLoadingOffer: boolean;
  isWrappedInFormWithBlackBox: boolean;
}>;

type Props = Diff<StaticCalculatorPropsT, StaticCalculatorPassedProps & {
  pending: boolean;
  updateMonthlyPaymentInterval: (...args: Array<any>) => any;
}>;

const DynamicCalculator = (props: Props): JSX.Element => {
  const {
    isWrappedInFormWithBlackBox,
    updateMonthlyPaymentInterval,
  } = props;

  return (
    <Control render={(passedProps: PassedProps) => {
      const {
        isInitialLoading,
        isLoadingOffer,
        onChangeAmount,
        onAfterChangeAmount,
        ...StaticCalculatorProps
      } = passedProps;

      const onChangeAmountWithMonthlyIntervalUpdate = async value => {
        await updateMonthlyPaymentInterval(value);

        onChangeAmount(value);
        onAfterChangeAmount();
      };

      return (
        <Loader transparent={props.transparent} pending={isInitialLoading}>
          <StaticCalculator pending={isLoadingOffer} {...StaticCalculatorProps} {...props} onChangeAmount={onChangeAmount} onAfterChangeAmount={onChangeAmountWithMonthlyIntervalUpdate} isWrappedInFormWithBlackBox={isWrappedInFormWithBlackBox} />
        </Loader>
      );
    }}
    />
  );
};

export default connectActions({
  updateMonthlyPaymentInterval: ['calculator', 'updateMonthlyPaymentInterval'],
})(DynamicCalculator);
