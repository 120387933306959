export default (({
  colors,
}) => ({
  wrapper: {
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.yellow,
  },
}));
