import IntlMessageFormat from 'intl-messageformat';
import pl from '../releases/pl.json';

type LocaleKey = keyof typeof pl
export type Translated = IntlMessageFormat | null | string
type Message = typeof pl | Record<string, any>

function translatePath(translations: typeof pl, pathString: LocaleKey | string, values?: Record<string, any>): Translated {
  const message: typeof translations | Record<string, any> = pathString.split('.').reduce<typeof translations>(
    (prevKey: Record<string, any>, currentKey: string) => prevKey?.[currentKey], translations,
  );

  if (!message) {
    return null;
  }

  if (values) {
    return new IntlMessageFormat(message.toString(), 'pl').format(values);
  }

  return message;
}

export default function msg(translations: typeof pl, paths: string, values?: Record<string, any>): Translated {
  const pathsArray = ([] as string[]).concat(paths);

  while (pathsArray.length) {
    const pathString = pathsArray.shift() || '';
    const translated = translatePath(translations, pathString, values);

    if (translated) {
      return translated;
    }
  }

  return '';
}
