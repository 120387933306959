import * as actions from './actions';
import { fromJS, Record } from 'immutable';

interface ICalculatorInitialState {
  amount: number | null
  monthlyPayment: number | null
  term: number | null
}

interface ICalculatorState {
  default: ICalculatorInitialState,
  additionalAmount: ICalculatorInitialState,
  proposal: ICalculatorInitialState,
  monthlyPaymentInterval: Record<string, any> | null,
}

const calculatorInitialState = {
  amount: null,
  monthlyPayment: null,
  term: null,
};

class CalculatorState
  extends Record({
    default: { ...calculatorInitialState,
    },
    additionalAmount: { ...calculatorInitialState,
    },
    proposal: { ...calculatorInitialState,
    },
    monthlyPaymentInterval: {},
  }) {
  static fromJS(value: ICalculatorState): CalculatorState {
    return new CalculatorState(value);
  }
}

const initialState = new CalculatorState();

export default function reducer(state = initialState, action: actions.CalculatorActions): CalculatorState {
  if (!(state instanceof CalculatorState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case actions.CALCULATOR_SET_MONTHLY_PAYMENT_INTERVAL:
      return state.setIn(['monthlyPaymentInterval', action.payload.amount], action.payload.paymentInterval);

    case actions.CALCULATOR_SET_AMOUNT:
    case actions.CALCULATOR_SET_MONTHLY_PAYMENT:
    case actions.CALCULATOR_SET_TERM:
    {
      // eslint-disable-next-line no-nested-ternary
      const property = action.type === actions.CALCULATOR_SET_AMOUNT ? 'amount' : action.type === actions.CALCULATOR_SET_TERM ? 'term' : 'monthlyPayment';

      return state.mergeDeep(fromJS({
        [action.payload.id]: {
          [property]: ['amount', 'term'].includes(property) ? (action.payload.value || localStorage.getItem(property)) : action.payload.value,
        },
      }));
    }
  }

  return state;
}
