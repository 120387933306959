const MENU_TRANSITION = 'right 0.6s';

export default (({
  colors,
  mediaQueries,
}) => ({
  outerWrapper: {
    overflow: 'hidden',
  },
  wrapper: {
    position: 'relative',
    right: 0,
    transition: MENU_TRANSITION,
    '& .nav-container, & .nav-menu': {
      transition: MENU_TRANSITION,
    },
    '&.open': {
      right: '200px',
      '& .nav-container': {
        right: '200px',
      },
      '& .nav-menu': {
        right: 0,
      },
    },
  },
  outerContainer: {
    position: 'absolute',
    zIndex: 999,
    top: 0,
    right: 0,
    width: '100%',
    '&.shadow': {
      boxShadow: '0 2px 5px rgba(0,1,1,.14)',
    },
    [mediaQueries.breakpointLaptopSmall]: {
      position: 'fixed',
      boxShadow: 'none !important',
    },
  },
  container: {
    paddingTop: '18px',
    paddingBottom: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1200px',
    [mediaQueries.breakpointLaptopSmall]: {
      paddingTop: '13px',
      paddingBottom: '13px',
      backgroundColor: colors.primary,
      maxWidth: '100%',
    },
    '&.white': {
      color: colors.white,
    },
  },
  logoContainer: {
    height: '44px',
    display: 'block',
    color: 'inherit',
    '& svg': {
      height: '100%',
    },
    [mediaQueries.breakpointLaptopSmall]: {
      height: '34px',
      color: '#fff',
    },
  },
  linksContainer: {
    display: 'flex',
    alignItems: 'center',
    [mediaQueries.breakpointLaptopSmall]: {
      display: 'none',
    },
  },
  menuIcon: {
    cursor: 'pointer',
  },
  mobileMenu: {
    position: 'fixed',
    top: 0,
    right: '-200px',
    width: '200px',
    backgroundColor: colors.primaryDarker,
    height: '100%',
    boxShadow: 'inset 5px 0 10px 0 rgba(0,0,0,.1)',
  },
  contentContainer: {
    paddingTop: '80px',
    [mediaQueries.breakpointLaptopSmall]: {
      paddingTop: '60px',
    },
    '&.noTopPadding': {
      paddingTop: 0,
    },
  },
}));
