import * as R from '@routes/routesList';
import { Classes } from '@common/types';
import { compose } from 'redux';
import { Section } from '@shared/components/layout';
import { useTranslator } from '@localizations';
import Button from '@shared/components/Button';
import DataEncryptionIcon from '@browser/svg/data-encryption.svg';
import IconRejected from '@browser/svg/cancel.svg';
import injectStyles from '4finance-components-pl';
import QA_CLASSES from '@browser/lib/qa';
import styles from './ApplicationPESELRestricted.jss';

type Props = {
  classes: Classes<typeof styles>,
};

function ApplicationPESELRestricted(props: Props) {
  const { classes } = props;
  const { msg, cnt } = useTranslator();

  return (
    <>
      <Section
        title={msg('registration.application_PESEL_restricted.heading')}
        centered
        classes={{ centeredInnerContainer: classes.panel, container: classes.outerContainer }}
        headingClass={QA_CLASSES.LOAN_DENIED_HEADING}
        separator
      >
        <IconRejected className={classes.icon} />

        <h4 className={classes.subheading}>
          {cnt('registration.application_PESEL_restricted.subheading')}
        </h4>
        <div className={classes.detailPanel}>
          <DataEncryptionIcon className={classes.svg} />
          <div className={classes.detailsText}>
            <span className={classes.note}>{msg('registration.application_PESEL_restricted.note')}</span>
            <br />
            <span className={classes.noteButton}>{cnt('registration.application_PESEL_restricted.note_button')}</span>
          </div>
        </div>
        <p className={classes.textBeforeButton}>
          {msg('registration.application_PESEL_restricted.text_before_button')}
        </p>
        <div className={classes.buttonWrapper}>
          <Button
            primary
            to={R.DASHBOARD}
          >
            {msg('registration.application_PESEL_restricted.button')}
          </Button>
        </div>
      </Section>
    </>
  );
}

ApplicationPESELRestricted.styleRoot = 'CompleteApplication';

export default compose(
  injectStyles(styles),
)(ApplicationPESELRestricted);
