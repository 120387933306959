export default ({ colors }) => ({
  root: {
    padding: '24px 0',
    color: colors.red,
    fontSize: '14px',
    '& *': {
      fontSize: '14px',
    },
    '& ul': {
      margin: '0 12px',
      '& li': {
        marginTop: 0,
        fontWeight: '600',
        '& > a': {
          marginLeft: '8px',
        },
      },
    },
  },
});
