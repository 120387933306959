
import { createContext, useContext } from 'react';
import type { ContentCalculator, ContentFooter, ContentLoginModal, ContentNavigation, ContentPDFPageBase } from './types';

type TranslationsLogin = {
  title: string;
  subtitle: string;
  button: string;
  // eslint-disable-next-line camelcase
  password_recovery: string;
  email: string;
  password: string;
  errors: Record<string, any>;
  // eslint-disable-next-line camelcase
  error_message: string;
};
type TranslationsChatbot = Record<string, any>;
type Context = {
  footer: ContentFooter | null;
  calculator: ContentCalculator | null;
  pdfPageBase: ContentPDFPageBase | null;
  navigation: ContentNavigation | null;
  loginModal: ContentLoginModal | null;
  login: TranslationsLogin | null;
  chatbot: TranslationsChatbot | null;
};
const defaultContext = {
  footer: null,
  calculator: null,
  pdfPageBase: null,
  navigation: null,
  loginModal: null,
  login: null,
  chatbot: null,
};
const ContentContext = createContext<Context>(defaultContext);

export const ContentContextProvider = ContentContext.Provider;

function useContentContext(): Context {
  return useContext(ContentContext);
}

export default useContentContext;
