
import { compose } from 'redux';
import { EditableCalculator } from '@browser/calculator';
import injectStyles from '4finance-components-pl';
import styles from './ContainerWithCalculator.jss';

type Props = {
  classes: Record<string, any>;
  children: React.ReactChildren;
};

const ContainerWithCalculator = (props: Props): JSX.Element => {
  const {
    classes,
    children,
  } = props;

  return (
    <div className={classes.root}>
      <div>
        {children}
      </div>
      <div className={classes.calculatorContainer}>
        <EditableCalculator />
      </div>
    </div>
  );
};

ContainerWithCalculator.styleRoot = 'ContainerWithCalculator';
export default compose<any>(injectStyles(styles))(ContainerWithCalculator);
