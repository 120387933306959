import { getCardChecksum } from '../lib/faker/identityCardNumber';

const REGEX = /^[A-Z]{3}\s?\d{6}$/;
const FORMAT_ERROR = 'incorrect_format';
const IDENTITY_CARD_ERROR = 'invalid_id_card_format';

export default function identityCardNumber() {
  return (value: string | null | undefined): string | null | undefined => {
    if (value) {
      if (!REGEX.test(value)) {
        return FORMAT_ERROR;
      }

      const sanitizedValue: string = value.replace(/\s/g, '');

      return Number(sanitizedValue[3]) === getCardChecksum(sanitizedValue) ? null : IDENTITY_CARD_ERROR;
    }

    return null;
  };
}
