
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import styles from './Block.jss';

type Props = {
  classes: Record<string, any>;
  children: React.ReactChildren;
  // Own props
  className?: string;
  yellow?: boolean;
};

const Block = (props: Props): JSX.Element => {
  const {
    classes,
    children,
    className,
    yellow,
  } = props;

  return (
    <div className={classnames(classes.root, className, {
      yellow,
    })}
    >
      {children}
    </div>
  );
};

Block.styleRoot = 'Block';
export default injectStyles(styles)(Block);
