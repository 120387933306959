export default ({ colors, mediaQueries }) => ({
  leftPanel: {
    padding: 0,
    backgroundColor: colors.secondary,
    [mediaQueries.breakpointTablet]: {
      height: '400px',
    },
  },
  backgroundWrapper: {
    padding: '32px',
    backgroundPosition: 'bottom center',
    backgroundSize: '150%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: "url('/assets/img/background_dashboard_03_2022.png')",
    height: '100%',
    backgroundColor: '#f2f2f2',
    [mediaQueries.breakpointLaptopSmall]: {
      backgroundSize: 'auto 250px',
      height: '160px',
      backgroundImage: 'none',
    },
  },

  calculatorContainer: {
    width: '100% !important',
  },

  blocked: {
    minHeight: '600px',
    padding: '40px 20px',

    [mediaQueries.breakpointLaptopSmall]: {
      minHeight: 'initial',
    },
  },
  headingForeigner: {
    marginBottom: '40px',
  },
});
