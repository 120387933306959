
import { Hyperlink } from '../../../../shared/components/typography';
import { useTranslator } from '../../../../../localizations';
import injectStyles from '4finance-components-pl';
import styles from './Back.jss';

type Props = {
  classes: Record<string, any>;
  to?: string;
};

const Back = (props: Props): JSX.Element => {
  const {
    classes,
    to,
  } = props;
  const {
    msg,
  } = useTranslator();

  return (
    <div className={classes.root}>
      <Hyperlink to={to || '/client-settings'}>
        {msg('actions.back')}
      </Hyperlink>
    </div>
  );
};

Back.styleRoot = 'Back';
export default injectStyles(styles)(Back);
