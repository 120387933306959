type CurrentPathParams = {
  amount: number | string;
  term: number;
  id: string | number;
}

export function getOfferPath(isLoggedIn: boolean):(string | number | boolean)[] {
  const offers = {
    offer: 'offer',
    firstLoanOffer: 'firstLoanOffer',
  };

  return ['api', 'fetch', isLoggedIn && 'client', 'application', isLoggedIn ? offers.offer : offers.firstLoanOffer].filter(Boolean);
}

export function getOfferCurrentPath({ amount, term, id }: CurrentPathParams): (string | number | boolean)[] {
  return [typeof amount === 'number' ? amount.toString() : '', term.toString(), id, 'data'].filter(Boolean);
}
