
import { compose } from 'redux';
import { connectActions } from '../connect';
import { Control } from './components';
import { useCallback, useState } from 'react';
import { useTranslator } from '../../localizations';
import classnames from 'classnames';
import Close from '../svg/uEA0D-close.svg';
import Edit from '../svg/uEA0A-edit.svg';
import injectStyles from '4finance-components-pl';
import Loading from '../shared/components/Loading';
import StaticCalculator from './StaticCalculator';
import styles from './MobileEditableCalculator.jss';
import type { PassedProps } from './components';

type Props = {
  classes: Record<string, any>;
  updateMonthlyPaymentInterval: (...args: Array<any>) => any;
};

const MobileEditableCalculator = (props: Props): JSX.Element => {
  const {
    classes,
    updateMonthlyPaymentInterval,
  } = props;
  const { msg, numberToCurrency, termToMonths } = useTranslator();
  const [isEditing, setIsEditing] = useState(false);
  const onToggleEdit = useCallback(() => setIsEditing((v) => !v), [setIsEditing]);

  return (
    <Control
      render={(passedProps: PassedProps) => {
        const {
          isInitialLoading,
          isLoadingOffer,
          onChangeAmount,
          onAfterChangeAmount,
          onChangeTerm,
          onAfterChangeTerm,
          ...StaticCalculatorProps
        } = passedProps;
        const { offer, monthlyPayment, amount } = StaticCalculatorProps;

        const onChangeAmountWithMonthlyIntervalUpdate = async (value) => {
          await updateMonthlyPaymentInterval(value);
          onChangeAmount(value);
          onAfterChangeAmount();
        };

        if (isInitialLoading) {
          return (
            <div className={classes.root}>
              <Loading />
            </div>
          );
        }

        return (
          <>
            <div className={classes.root}>
              <div className={classes.field}>
                <div>{msg('registration.calculator.principal')}</div>
                <div>{amount ? numberToCurrency(amount) : ''}</div>
              </div>
              <div className={classes.field}>
                <div>{msg('registration.calculator.term')}</div>
                <div>{offer ? termToMonths(offer.term) : ''}</div>
              </div>
              <div className={classnames(classes.field, 'bold')}>
                <div>{msg('registration.calculator.monthly_payment')}</div>
                <div>
                  {monthlyPayment
                    ? numberToCurrency(monthlyPayment, {
                      precision: 2,
                    })
                    : ''}
                </div>
              </div>
              <button type="button" className={classes.edit} onClick={onToggleEdit}>
                {isEditing ? <Close /> : <Edit />}
              </button>
            </div>
            {isEditing && (
              <StaticCalculator
                pending={isLoadingOffer}
                classes={{
                  button: classes.staticCalculatorButton,
                  container: classes.staticCalculatorContainer,
                }}
                {...StaticCalculatorProps}
                onChangeAmount={onChangeAmount}
                onAfterChangeAmount={onChangeAmountWithMonthlyIntervalUpdate}
                onChangeTerm={onChangeTerm}
                onAfterChangeTerm={onAfterChangeTerm}
              />
            )}
          </>
        );
      }}
    />
  );
};

MobileEditableCalculator.styleRoot = 'MobileEditableCalculator';
export default compose<any>(connectActions({
  updateMonthlyPaymentInterval: ['calculator', 'updateMonthlyPaymentInterval'],
}), injectStyles(styles))(MobileEditableCalculator);
