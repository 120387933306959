import * as authentication from './authentication/actions';
import * as calculator from './calculator/actions';
import * as dashboard from './dashboard/actions';
import * as persistence from './lib/persistence/actions';
import * as registration from './registration/actions';
import * as settings from './settings/actions';
import { actions as api } from './api';
import { go, goBack, goForward, push, replace, routerActions, push as updatePath } from 'connected-react-router';
import { actions as onionForm } from '4finance-onion-form-pl';

type LocalRouterActions = typeof routerActions & { updatePath: typeof routerActions.push }

const router: LocalRouterActions = {
  updatePath,
  push,
  replace,
  go,
  goBack,
  goForward,
};
const actions: {
  api: any,
  authentication: typeof authentication,
  calculator: calculator.CalculatorActions,
  dashboard: dashboard.DashboardActions,
  onionForm: typeof onionForm,
  persistence: typeof persistence,
  router: LocalRouterActions,
  registration: typeof registration,
  settings: settings.SettingsAction,
} = {
  api,
  authentication,
  calculator,
  dashboard,
  onionForm,
  persistence,
  router,
  registration,
  settings,
};

export default actions;
