import config from '4finance-configuration-pl';

const getCaptchaKey = (): string => {
  const {
    environment,
    passwordRecovery: {
      captchaApiKey,
      captchaTestApiKey,
    },
  } = config;
  const isProduction = environment === 'production';

  return isProduction ? captchaApiKey : captchaTestApiKey;
};

export default getCaptchaKey;
