import { Classes } from '@common/types';
import { pick } from 'lodash';
import { SecondaryHeading } from '../typography';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import injectStyles, { Modal as FourFinanceModal } from '4finance-components-pl';
import styles from './Modal.jss';

type Props = {
  classes: Classes<typeof styles>;
  children: React.ReactChildren;
  // Own props
  open: boolean;
  onClose: (...args: Array<any>) => any;
  title: string;
  legalTermsModal?: boolean,
  passedClasses?: Record<string, any>;
  passedInnerContentClasses?: Record<string, any>;
};

const Modal = (props: Props): JSX.Element => {
  const {
    classes,
    open,
    onClose,
    children,
    title,
    legalTermsModal,
    passedClasses,
    passedInnerContentClasses,
  } = props;
  const [rerender, triggerRerender] = useState(false);

  useEffect(() => {
    window.document.body.style.overflow = open ? 'hidden' : 'visible';
  }, [rerender, open]);

  useEffect(() => triggerRerender(true), [open]);

  return (
    <FourFinanceModal
      className={classnames(classes.root, legalTermsModal && classes.legalTermsRoot, passedClasses && passedClasses)}
      overlayClassName={classes.overlay}
      classes={pick(classes, ['innerContent', 'closeButton'])}
      innerContentClassName={[legalTermsModal && classes.legalTermsInnerContent, passedInnerContentClasses && passedInnerContentClasses]}
      isModalOpen={open}
      onClose={onClose}
    >
      <div className={classes.modalContentWrapper}>
        {title && (
        <SecondaryHeading bigger centered modalWidth className={classes.title}>
          {title}
        </SecondaryHeading>
        )}
        {children}
      </div>
    </FourFinanceModal>
  );
};

Modal.styleRoot = 'Modal';

export default injectStyles(styles)(Modal);
