import { useCallback, useRef } from 'react';
import Button from '@shared/components/Button';
import ButtonGroup from '@shared/components/ButtonGroup';
import injectStyles from '4finance-components-pl';
import Modal from '@shared/components/Modal';
import styles from './LegalTermsModal.jss';
import useAuthentication from '@browser/lib/hooks/useAuthentication';
import useContentContext from '@browser/lib/content/useContentContext';

type Props = {
  classes: Record<string, any>;
  // Own props
  open: boolean;
  onClose: (...args: Array<any>) => any;
  amount: number;
  term: number;
};

const LegalTermsModal = (props: Props): JSX.Element | null => {
  const {
    classes,
    open,
    onClose,
    amount,
    term,
  } = props;
  const {
    calculator: content,
  } = useContentContext();
  const iframeRef = useRef();
  const isSignedIn = useAuthentication();
  const {
    infoModalTitle,
    print,
    save,
  } = content?.fields || {};
  const iframeSrc = isSignedIn ? `/webapi/client/legal-terms?amount=${amount}&term=${term}` : `/webapi/first-loan-legal-terms?amount=${amount}&term=${term}`;
  const downloadUrl = `${iframeSrc}&attachment=true`;
  const onPrint = useCallback(() => {
    if (!iframeRef.current) {
      return;
    }

    iframeRef.current.focus();
    iframeRef.current.contentWindow.print();
  }, [iframeRef.current]);

  if (!amount || !term) {
    return null;
  }

  return (
    <Modal title={infoModalTitle} open={open} onClose={onClose} legalTermsModal>
      <ButtonGroup justifyEnd>
        <Button onClick={onPrint}>
          {print}
        </Button>
        <Button external to={downloadUrl} target={'_blank'}>
          {save}
        </Button>
      </ButtonGroup>
      <iframe ref={iframeRef} className={classes.iframe} src={iframeSrc} title={infoModalTitle} />
    </Modal>
  );
};

LegalTermsModal.styleRoot = 'LegalTermsModal';
export default injectStyles(styles)(LegalTermsModal);
