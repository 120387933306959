import * as R from '../../routes/routesList';

import { compose } from 'redux';
import { connectActions } from '../../connect';
import { Route, withRouter } from 'react-router';
import { useCallback, useEffect } from 'react';
import { useTranslator } from '../../../localizations';
import ActiveLoan from './components/ActiveLoan';
import AdditionalAmount from './components/AdditionalAmount';
import ConfirmBankAccount from './components/ConfirmBankAccount';
import DashboardSection from '../components/DashboardSection';
import Extension from './components/Extension';
import LoanContextProvider from './components/LoanContext';
import NoActiveLoan from './components/NoActiveLoan';
import Payment from './components/Payment';
import withLatestLoan from './withLatestLoan';
import type { ImmutableMap } from '../../../common/lib/types';

type Props = {
  latestLoan?: ImmutableMap | null;
  location: Location;
  setIsJustRegistered: (...args: Array<any>) => any;
};
const PAGE_TITLES = {
  [R.PAYMENT]: 'dashboard.payment.title',
  [R.EXTENSION]: 'dashboard.extension.title',
};

const Dashboard = (props: Props): JSX.Element => {
  const {
    latestLoan,
    location,
    setIsJustRegistered,
  } = props;
  const {
    msg,
  } = useTranslator();
  const title = msg(PAGE_TITLES[location.pathname] || 'dashboard.loan.title');

  useEffect(() => {
    setIsJustRegistered(false);
  }, []);
  // eslint-disable-next-line arrow-body-style
  const renderMainView = useCallback(() => {
    return latestLoan ? <ActiveLoan /> : <NoActiveLoan />;
  }, [latestLoan]);

  return (
    <LoanContextProvider>
      <DashboardSection title={title}>
        <Route exact path={R.PAYMENT} component={Payment} />
        <Route exact path={R.DASHBOARD} render={renderMainView} />
        <Route exact path={R.APPLICATION_CONFIRM_BANK_ACCOUNT} component={ConfirmBankAccount} />
        <Route exact path={R.EXTENSION} component={Extension} />
        <Route exact path={R.APPLICATION_ADDITIONAL_AMOUNT} component={AdditionalAmount} />
      </DashboardSection>
    </LoanContextProvider>
  );
};

export default compose<any>(withRouter, withLatestLoan, connectActions({
  setIsJustRegistered: ['registration', 'setIsJustRegistered'],
}))(Dashboard);
