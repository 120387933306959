/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { StylesheetParams } from '@common/types';

export default ({ colors, mediaQueries }: StylesheetParams) => ({
  modalWrapper: {
    maxWidth: '667px',
    [mediaQueries.breakpointTablet]: {
      maxWidth: '100vw',
      height: '100vh',
    },
  },
  modalInnerContent: {
    padding: '60px 50px 50px 50px',
  },
  instructionImg: {
    content: 'url("/assets/img/example.png")',
    marginTop: '20px',
    maxWidth: '100%',
    [mediaQueries.breakpointTablet]: {
      content: 'url("/assets/img/example_mobile.png")',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  heading: {
    display: 'flex',
    width: '100%',
    marginBottom: '40px',
    alignItems: 'center',
    '& svg': {
      marginRight: '12px',
      marginLeft: '-7px',
      width: '47px',
    },
  },
  bulletCircle: {
    backgroundColor: colors.primary,
    color: '#fff',
    width: '30px',
    height: '30px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    marginRight: '20px',
    fontSize: '12px',
    lineHieght: '0px',
  },
  rowWrapper: {
    display: 'flex',
    marginBottom: '15px',
  },
  separator: {
    width: '100%',
    height: '1px',
    background: '#DADADA',
    border: 'none',
  },
  column: {
    flexDirection: 'column',
  },
  lineWrapper: {
    fontSize: '12px',
    width: '527px',
    [mediaQueries.breakpointTablet]: {
      width: '67vw',
    },
  },
  note: {
    display: 'flex',
    margin: '20px',
    [mediaQueries.breakpointTablet]: {
      width: '80vw',
      display: 'block',
    },
    '& span': {
      maxWidth: '350px',
      textAlign: 'center',
    },
    '& svg': {
      width: '14px',
      [mediaQueries.breakpointTablet]: {
        marginRight: '10px',
      },
    },
  },
  info: {
    marginTop: '20px',
    color: '#747486',
    fontSize: '12px',
  },
});
