
import theme from '../extendedTheme';

type Props = {
  whiteStripes: boolean;
};

const Logo = (props: Props): JSX.Element => {
  const {
    whiteStripes,
  } = props;
  const colors = theme.getIn(['globals', 'colors']);
  const stripeColor = whiteStripes ? colors.get('white') : colors.get('primary');

  return (
    <svg version={'1.1'} viewBox={'29.901 217.139 451.891 82.791'} preserveAspectRatio={'xMinYMin meet'}>
      <g fill={'currentColor'}>
        <path d={'M84.275,270.643H84.07c-1.229,1.895-2.765,3.277-4.608,4.148c-1.843,0.869-3.891,1.279-6.144,1.279 c-1.536,0-3.021-0.205-4.505-0.614s-2.765-1.024-3.892-1.895c-1.126-0.819-2.048-1.895-2.765-3.123 c-0.717-1.28-1.024-2.714-1.024-4.403c0-1.792,0.307-3.328,0.973-4.659c0.666-1.28,1.536-2.354,2.611-3.226 s2.355-1.536,3.789-2.048c1.434-0.512,2.918-0.871,4.506-1.126c1.536-0.256,3.123-0.41,4.71-0.512 c1.587-0.051,3.072-0.102,4.454-0.102h2.1v-0.922c0-2.099-0.717-3.687-2.15-4.71s-3.277-1.587-5.53-1.587 c-1.792,0-3.43,0.307-4.966,0.921s-2.867,1.485-3.994,2.56l-4.403-4.403c1.843-1.895,4.045-3.277,6.502-4.096 c2.509-0.819,5.069-1.28,7.731-1.28c2.355,0,4.352,0.256,5.99,0.819c1.638,0.512,2.97,1.229,4.045,2.048s1.894,1.792,2.458,2.867 c0.563,1.075,0.973,2.202,1.229,3.328c0.256,1.126,0.41,2.202,0.461,3.226c0.051,1.075,0.051,1.997,0.051,2.765v19.251h-7.526 v-4.506H84.275z M83.763,260.25h-1.741c-1.178,0-2.458,0.051-3.891,0.153s-2.816,0.308-4.096,0.665 c-1.28,0.359-2.355,0.871-3.226,1.537c-0.87,0.665-1.331,1.638-1.331,2.815c0,0.769,0.153,1.434,0.512,1.997 c0.358,0.512,0.819,0.973,1.332,1.382c0.563,0.358,1.177,0.614,1.894,0.819c0.717,0.154,1.383,0.256,2.1,0.256 c2.867,0,5.018-0.666,6.4-2.048s2.048-3.226,2.048-5.632V260.25z'} />
        <path d={'M97.178,241.767h7.936v5.018h0.153c1.229-2.048,2.816-3.533,4.864-4.455 c2.048-0.921,4.25-1.382,6.604-1.382c2.56,0,4.864,0.461,6.912,1.382s3.789,2.15,5.223,3.738c1.434,1.587,2.56,3.43,3.277,5.581 s1.126,4.455,1.126,6.913c0,2.457-0.409,4.762-1.177,6.912s-1.895,3.993-3.328,5.58c-1.434,1.588-3.123,2.816-5.12,3.738 c-1.997,0.921-4.198,1.382-6.554,1.382c-1.587,0-2.969-0.153-4.198-0.513c-1.229-0.307-2.304-0.768-3.226-1.279 s-1.741-1.075-2.355-1.639c-0.666-0.562-1.178-1.126-1.587-1.689h-0.205v20.07h-8.397v-49.356H97.178z M124.928,258.509 c0-1.28-0.205-2.56-0.666-3.738c-0.46-1.229-1.075-2.304-1.945-3.226c-0.871-0.921-1.895-1.689-3.124-2.252 c-1.229-0.563-2.56-0.819-4.096-0.819s-2.918,0.256-4.096,0.819c-1.229,0.563-2.252,1.331-3.123,2.252 c-0.871,0.922-1.485,1.997-1.946,3.226c-0.46,1.229-0.666,2.458-0.666,3.738c0,1.331,0.205,2.56,0.666,3.789 c0.461,1.229,1.075,2.253,1.946,3.226c0.871,0.922,1.895,1.689,3.123,2.252c1.229,0.564,2.56,0.82,4.096,0.82 s2.918-0.308,4.096-0.82c1.229-0.562,2.253-1.279,3.124-2.252c0.87-0.922,1.485-1.997,1.945-3.226 C124.723,261.068,124.928,259.84,124.928,258.509'} />
        <rect x={'136.96'} y={'225.946'} width={'8.397'} height={'49.305'} />
        <path d={'M148.89,258.509c0-2.663,0.461-5.069,1.434-7.219s2.253-3.994,3.891-5.53 c1.639-1.536,3.533-2.713,5.786-3.533c2.253-0.819,4.608-1.28,7.117-1.28s4.864,0.41,7.117,1.28 c2.253,0.819,4.147,1.997,5.786,3.533c1.639,1.536,2.918,3.379,3.891,5.53c0.973,2.15,1.434,4.557,1.434,7.219 c0,2.663-0.46,5.069-1.434,7.22c-0.973,2.15-2.252,3.993-3.891,5.529c-1.638,1.535-3.533,2.713-5.786,3.533 c-2.252,0.818-4.608,1.229-7.117,1.229s-4.864-0.41-7.117-1.229c-2.253-0.82-4.147-2.049-5.786-3.533 c-1.638-1.536-2.918-3.379-3.891-5.529S148.89,261.172,148.89,258.509 M157.235,258.509c0,1.331,0.205,2.56,0.666,3.789 c0.461,1.229,1.075,2.253,1.945,3.226c0.871,0.922,1.895,1.689,3.124,2.252c1.229,0.564,2.56,0.82,4.096,0.82 c1.536,0,2.918-0.308,4.096-0.82c1.229-0.562,2.253-1.279,3.123-2.252c0.871-0.922,1.485-1.997,1.946-3.226 c0.461-1.229,0.666-2.458,0.666-3.789c0-1.28-0.205-2.56-0.666-3.738c-0.461-1.229-1.075-2.304-1.946-3.226 c-0.87-0.921-1.894-1.689-3.123-2.252c-1.229-0.563-2.56-0.819-4.096-0.819c-1.536,0-2.918,0.256-4.096,0.819 c-1.229,0.563-2.253,1.331-3.124,2.252c-0.87,0.922-1.484,1.997-1.945,3.226C157.491,255.949,157.235,257.229,157.235,258.509'} />
        <path d={'M195.174,271.924c0,0.973-0.358,1.843-1.024,2.61c-0.717,0.769-1.587,1.126-2.713,1.126 c-1.126,0-1.997-0.357-2.714-1.126c-0.717-0.768-1.024-1.638-1.024-2.61c0-0.974,0.358-1.844,1.024-2.611 c0.717-0.769,1.587-1.178,2.714-1.178s2.048,0.409,2.713,1.178C194.867,270.08,195.174,270.95,195.174,271.924'} />
        <polygon points={'29.901,267.52 47.821,248.627 47.821,248.474 30.72,248.474 30.72,241.767 57.958,241.767 57.958,249.395 39.782,268.596 58.778,268.596 58.778,275.251 29.901,275.251'} />
        <rect x={'239.001'} y={'225.946'} width={'4.608'} height={'49.305'} />
        <path d={'M199.833,241.817h4.608v5.53h0.154c0.717-1.126,1.638-2.099,2.663-2.918 c1.024-0.819,2.099-1.485,3.226-1.997c1.126-0.512,2.252-0.922,3.43-1.126c1.178-0.256,2.304-0.358,3.379-0.358 c2.56,0,4.915,0.461,7.014,1.331c2.099,0.871,3.942,2.099,5.479,3.687c1.536,1.536,2.713,3.379,3.584,5.53s1.28,4.454,1.28,6.962 c0,2.51-0.409,4.813-1.28,6.964s-2.048,3.993-3.584,5.529c-1.536,1.587-3.379,2.765-5.479,3.636 c-2.099,0.87-4.454,1.331-7.014,1.331c-1.075,0-2.202-0.103-3.379-0.358s-2.355-0.615-3.43-1.127 c-1.126-0.512-2.202-1.178-3.226-1.996c-1.024-0.819-1.895-1.793-2.663-2.919h-0.154v21.657h-4.608V241.817z M229.632,258.406 c0-1.844-0.307-3.584-0.87-5.171c-0.563-1.587-1.382-2.97-2.458-4.199c-1.075-1.177-2.355-2.099-3.892-2.816 c-1.484-0.666-3.226-1.024-5.12-1.024c-1.895,0-3.635,0.358-5.223,1.024c-1.587,0.666-2.97,1.639-4.096,2.816 c-1.178,1.178-2.048,2.56-2.714,4.199c-0.666,1.587-0.973,3.327-0.973,5.171s0.307,3.584,0.973,5.172 c0.666,1.587,1.536,3.021,2.714,4.197c1.177,1.178,2.56,2.1,4.096,2.816c1.587,0.666,3.328,1.024,5.223,1.024 c1.894,0,3.635-0.358,5.12-1.024c1.536-0.717,2.816-1.639,3.892-2.816c1.075-1.177,1.894-2.56,2.458-4.197 C229.376,261.99,229.632,260.25,229.632,258.406'} />
      </g>
      <g className={'svg-logo-image'}>
        <rect x={'268.083'} y={'217.139'} fill={'#FFD700'} width={'172.441'} height={'8.807'} />
        <rect x={'268.083'} y={'229.479'} fill={stripeColor} width={'70.349'} height={'8.806'} />
        <rect x={'268.083'} y={'241.817'} fill={'#F40000'} width={'185.088'} height={'8.807'} />
        <rect x={'268.083'} y={'254.157'} fill={'#492B2C'} width={'213.709'} height={'8.806'} />
        <rect x={'268.083'} y={'266.445'} fill={'#F40000'} width={'92.519'} height={'8.806'} />
        <rect x={'268.083'} y={'278.784'} fill={stripeColor} width={'153.651'} height={'8.806'} />
        <rect x={'268.083'} y={'291.123'} fill={'#FFD700'} width={'119.962'} height={'8.807'} />
      </g>
    </svg>
  );
};

export default Logo;
