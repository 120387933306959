const ERROR = 'invalid_phone_format';

export default function notOnlyHyphens() {
  return (value: string | null | void): string | null | void => {
    if (value) {
      const clearedValue = value.replace(/[-]/g, '');

      return clearedValue.length > 0 ? null : ERROR;
    }

    return null;
  };
}
