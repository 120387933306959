export default (({
  mediaQueries,
  colors,
}) => ({
  root: {
    width: '400px',
    backgroundColor: colors.white,
    minHeight: '590px',
    boxShadow: '0 0 32px 0 rgba(0, 0, 0, 0.1)',
    [mediaQueries.breakpointLaptop]: {
      width: '320px',
    },
    [mediaQueries.breakpointLaptopSmall]: {
      width: '100%',
    },
    '&.transparent': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
}));
