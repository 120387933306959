export default ({ colors }) => ({
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(100, 100, 100, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1001,
    height: '100%',
    width: '100%',
  },
  innerContainer: {
    borderRadius: '5px',
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#fff',
    padding: '32px',
    position: 'relative',
  },
  formContainer: {
    padding: '32px',
  },
  subtitle: {
    margin: '8px 0 24px 0',
    fontSize: '16px',
  },
  recovery: {
    marginTop: '24px',
    textAlign: 'center',
    '& a': {
      color: colors.primary,
      fontSize: '12px',
      textAlign: 'center',
    },
  },
  close: {
    position: 'absolute',
    top: '8px',
    right: '8px',
  },
});
