window.Promise = require('../common/lib/configureBluebird');

/*
 * Full Story object ('FS') is injected via GTM and
 * is present only on production. So on other environments
 * to avoid errors when using FS and be able to debug it
 * conveniently, I am just creating FS emulator
 */

declare global {
  interface Window {
    FS: any;
    onCntLinkClick: (...args: any) => any;
    dataLayer: any;
  }
}

if (!('FS' in window)) {
  window.FS = {
    log: (message) => console.log(`FS: ${message}`),
    // eslint-disable-line no-console
    identify: (message) => console.log(`FS identify: ${message}`), // eslint-disable-line no-console
  };
}

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/serviceWorker.js');
  });
}

require('./main');
