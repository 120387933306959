
import { components } from 'react-select';
import { IconSelect } from '4finance-components-pl';

const DropdownIndicator = (props: any) => components.DropdownIndicator && (
<components.DropdownIndicator {...props}>
  <IconSelect style={{
    height: '16px',
    width: '7px',
    float: 'right',
  }}
  />
</components.DropdownIndicator>
);

export default DropdownIndicator;
