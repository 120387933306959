import * as DashboardSection from '../../../components/DashboardSection';

import { compose } from 'redux';
import { connectActions, connectPrefetch } from '../../../../connect';
import { Control, Offer, Sliders, slidersPropNames } from '../../../../calculator/components';
import { pick } from 'lodash';
import { SecondaryHeading } from '../../../../shared/components/typography';
import { useCallback } from 'react';
import { useLoanContext } from '../LoanContext';
import { useTranslator } from '../../../../../localizations';
import Button from '../../../../shared/components/Button';
import ChangesRow from './ChangesRow';
import injectStyles from '4finance-components-pl';
import LegalTerms from '../../../../calculator/components/LegalTerms/LegalTerms';
import Loading from '../../../../shared/components/Loading';
import styles from './AdditionalAmount.jss';
import type { PassedProps } from '../../../../calculator/components';

type Props = {
  classes: Record<string, any>;
  createApplication: (...args: Array<any>) => any;
  updatePath: (...args: Array<any>) => any;
};

const AdditionalAmount = (props: Props): JSX.Element => {
  const {
    classes,
    updatePath,
    createApplication,
  } = props;
  const {
    nextInstallment,
  } = useLoanContext();
  const {
    msg,
    numberToCurrency,
  } = useTranslator();
  const onAccept = useCallback(() => {
    createApplication({ redirect: true, id: 'additionalAmount' });
  }, [updatePath, createApplication]);

  return (
    <Control
      id={'additionalAmount'}
      render={(passedProps: PassedProps) => {
        const {
          isInitialLoading,
          offer,
          isLoadingOffer,
          amount,
        } = passedProps;

        return (
          <>
            <DashboardSection.Block className={classes.calculatorBlock}>
              <div>
                <SecondaryHeading bigger lighter className={classes.header}>
                  {msg('dashboard.additional_amount.heading')}
                </SecondaryHeading>
                {!isInitialLoading ? (
                  <Sliders
                    classes={{
                      sliderContainer: classes.sliderContainer,
                    }}
                    {...pick(passedProps, slidersPropNames)}
                    transparent
                  />
                ) : <Loading />}
                {msg('dashboard.additional_amount.note')}
              </div>
              <div className={classes.footnote}>
                {msg('dashboard.additional_amount.footnote')}
              </div>
            </DashboardSection.Block>
            <DashboardSection.Block yellow>
              <SecondaryHeading bigger className={classes.detailsHeading}>
                {msg('dashboard.additional_amount.details.heading')}
              </SecondaryHeading>
              <ChangesRow
                titles={[msg('dashboard.additional_amount.details.current_balance'), msg('dashboard.additional_amount.details.consolidation_loan_amount')]}
                values={[numberToCurrency(offer?.currentPrincipal || 0, {
                  precision: 2,
                }), numberToCurrency(offer?.totalPrincipal || 0, {
                  precision: 2,
                })]}
              />
              <div className={classes.changesSeparator} />
              <ChangesRow
                titles={[msg('dashboard.additional_amount.details.current_monthly_payment'), msg('dashboard.additional_amount.details.consolidation_loan_monthly_payment')]}
                values={[nextInstallment.amount, numberToCurrency(offer?.monthlyPayment || 0, {
                  precision: 2,
                })]}
              />
              <Offer
                classes={{
                  container: classes.offerContainer,
                }}
                offer={offer}
                pending={isLoadingOffer}
              />
              <Button className={classes.button} fullWidth onClick={onAccept} disabled={isLoadingOffer || isInitialLoading}>
                {msg('dashboard.additional_amount.button')}
              </Button>
              <LegalTerms className={classes.legal} amount={amount} term={offer?.term} />
            </DashboardSection.Block>
          </>
        );
      }}
    />
  );
};

AdditionalAmount.styleRoot = 'AdditionalAmount';
export default compose<any>(connectPrefetch([['api', 'fetchClientApplicationConstraints']]), connectActions({
  updatePath: ['router', 'updatePath'],
  createApplication: ['registration', 'createApplication'],
}), injectStyles(styles))(AdditionalAmount);
