
import classnames from 'classnames';
import injectStyles, { Heading } from '4finance-components-pl';
import styles from './SecondaryHeading.jss';

type Props = {
  classes: Record<string, any>;
  children: React.ReactChildren;
  // Own props
  className?: string;
  white?: boolean;
  brown?: boolean;
  bigger?: boolean;
  lighter?: boolean;
};

const SecondaryHeading = (props: Props): JSX.Element => {
  const {
    classes,
    className,
    children,
    white,
    brown,
    bigger,
    lighter,
  } = props;
  const headingClassName = classnames(classes.heading, {
    [className || '']: className,
    white,
    brown,
    bigger,
    lighter,
  });

  return (
    <Heading kind={'h2'} className={headingClassName} noMargin>
      {children}
    </Heading>
  );
};

SecondaryHeading.styleRoot = 'SecondaryHeading';
export default injectStyles(styles)(SecondaryHeading);
