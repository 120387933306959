
import ContextAwareLink from '../ContextAwareLink';
import FacebookIcon from '../../../svg/uE014-facebook.svg';
import injectStyles from '4finance-components-pl';
import MailIcon from '../../../svg/uEA06-envelope.svg';
import PhoneIcon from '../../../svg/uEA09-phone.svg';
import PZIPIcon from '../../../svg/pzip.svg';
import styles from './FooterGroupElement.jss';
import YoutubeIcon from '../../../svg/uE018-youtube.svg';
import type { ContentFooterHyperlinkGroupElementType, ContentImage } from '../../../lib/content/types';

const ICONS = {
  facebook: FacebookIcon,
  mail: MailIcon,
  phone: PhoneIcon,
  pzip: PZIPIcon,
  youtube: YoutubeIcon,
};

type Props = {
  classes: Record<string, any>;
  // Own props
  title: string;
  icon?: ContentImage;
  type: ContentFooterHyperlinkGroupElementType;
  url: string;
  image?: ContentImage;
};

const FooterGroupElement = (props: Props): JSX.Element => {
  const {
    classes,
    title,
    icon,
    type,
    url,
    image,
  } = props;
  const urlPrefix = type === 'phone' ? 'tel:' : type === 'email' ? 'mailto:' : ''; // eslint-disable-line
  const prefixedUrl = `${urlPrefix}${url}`;
  const Icon = icon ? ICONS[icon.fields.name] : null;
  const Image = image ? ICONS[image.fields.name] : null;

  return (
    <div className={classes.container}>
      {(icon && Icon) && <Icon className={classes.icon} />}
      <ContextAwareLink className={classes.link} to={prefixedUrl} external={type !== 'internal'} destinationRouteType={'static'}>
        {(image && Image) ? <Image className={classes.image} /> : title}
      </ContextAwareLink>
    </div>
  );
};

FooterGroupElement.styleRoot = 'FooterGroupElement';
export default injectStyles(styles)(FooterGroupElement);
