export default ({ colors }) => ({
  root: {
    width: 'auto',
    padding: '16px 32px 14px 32px',
    color: colors.white,
    textTransform: 'none',
    textDecoration: 'none',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: 1,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 'unset',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.2)',
    border: '2px solid transparent',
    '&:active': {
      color: colors.white,
    },
    '&.fullWidth': {
      display: 'flex',
      width: '100%',
    },
    '&.secondary': {
      backgroundColor: 'transparent',
      borderColor: colors.primary,
      color: colors.primary,
      transition: 'opacity 0.3s',
      '&:hover': {
        opacity: '0.8',
      },
      '&:active': {
        opacity: '0.6',
      },
    },
    '& span': {
      textDecoration: 'none',
    },
  },
});
