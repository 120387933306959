export default ({ colors, mediaQueries }) => ({
  root: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.4,
    [mediaQueries.breakpointSmall]: {
      fontSize: '14px',
    },
    '& a': {
      color: colors.primary,
    },
    '&.white': {
      color: colors.white,
    },
    '&.brown': {
      color: colors.brown,
    },
    '& ul': {
      margin: '16px 0',
      padding: '0 24px',
      '& li': {
        marginTop: '8px',
        listStyle: 'disc',
      },
    },
    '&.listCheckmarks': {
      '&.yellow li::after, &.yellow li::before': {
        backgroundColor: colors.yellow,
      },
      '&.blue li::after, &.blue li::before': {
        backgroundColor: colors.primary,
      },
      '&.gray li::after, &.gray li::before': {
        backgroundColor: colors.darkGray,
      },
      '& ul': {
        paddingLeft: '40px',
        '& li': {
          listStyle: 'none',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: '-36px',
            height: '7px',
            top: '10px',
            transform: 'rotate(-45deg)',
            width: '3px',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            left: '-30px',
            height: '11px',
            top: '5px',
            transform: 'rotate(45deg)',
            width: '3px',
          },
        },
      },
    },
  },
});
