import { actions as api } from '../api';
import { DispatchType } from '@common/types';
import { RootState } from '@browser/configureStoreWithHistory';
import debounce from 'debounce-promise';

export const CALCULATOR_CHANGE = 'CALCULATOR_CHANGE';
export const CALCULATOR_FETCH_OFFER = 'CALCULATOR_FETCH_OFFER';
export const CALCULATOR_SET_AMOUNT = 'CALCULATOR_SET_AMOUNT';
export const CALCULATOR_SET_MONTHLY_PAYMENT = 'CALCULATOR_SET_MONTHLY_PAYMENT';
export const CALCULATOR_SET_TERM = 'CALCULATOR_SET_TERM';
export const CALCULATOR_SET_MONTHLY_PAYMENT_INTERVAL = 'CALCULATOR_SET_MONTHLY_PAYMENT_INTERVAL';
export const CALCULATOR_UPDATE_MONTHLY_PAYMENT_INTERVAL = 'CALCULATOR_UPDATE_MONTHLY_PAYMENT_INTERVAL';

export interface SetAmount {
  type: typeof CALCULATOR_SET_AMOUNT
  payload: {
    id: string
    value: number
  }
}
export interface SetMonthlyPayment {
  type: typeof CALCULATOR_SET_MONTHLY_PAYMENT
  payload: {
    id: string
    value: number
  }
}
export interface SetTerm {
  type: typeof CALCULATOR_SET_TERM
  payload: {
    id: string
    value: number
  }
}

export interface FetchOffer {
  type: typeof CALCULATOR_FETCH_OFFER
  payload: (args0: string, args1: ActionArguments) => any
}

export interface SetValuesFromApplication {
  type: typeof CALCULATOR_FETCH_OFFER
  payload: (args0: string, args1: ActionArguments) => Promise<void>
}

export interface SetMonthlyPaymentInterval {
  type: typeof CALCULATOR_SET_MONTHLY_PAYMENT_INTERVAL
  payload: { paymentInterval: Record<string, any>; amount: number; }
}

export interface UpdateMonthlyPaymentInterval {
  type: typeof CALCULATOR_UPDATE_MONTHLY_PAYMENT_INTERVAL
  payload: (args0: string, args1: ActionArguments) => Promise<void>
}

export type CalculatorActions =
  SetAmount |
  SetMonthlyPayment |
  SetTerm |
  FetchOffer |
  SetValuesFromApplication |
  SetMonthlyPaymentInterval |
  UpdateMonthlyPaymentInterval

interface ActionArguments {
  dispatch: DispatchType<SetAmount | SetMonthlyPayment | SetTerm>,
  getState: () => RootState,
  getApiResponse: (path: string[], options?: {forceFetch: boolean}) => Record<string, any>
}

export function setAmount(value: number, id = 'default'): SetAmount {
  localStorage.setItem('amount', value.toString());

  return {
    type: CALCULATOR_SET_AMOUNT,
    payload: {
      id,
      value,
    },
  };
}
export function setMonthlyPayment(value: number, id = 'default'): SetMonthlyPayment {
  return {
    type: CALCULATOR_SET_MONTHLY_PAYMENT,
    payload: {
      id,
      value,
    },
  };
}
export function setTerm(value: number, id = 'default'): SetTerm {
  localStorage.setItem('term', value.toString());

  return {
    type: CALCULATOR_SET_TERM,
    payload: {
      id,
      value,
    },
  };
}

const getTermInterval = (constraints: Record<string, any>, amount: number | string): Record<string, any> => {
  const termLimits = constraints.termLimits?.filter(lim => lim.amountFrom <= amount && amount <= lim.amountTo)[0];

  return {
    min: termLimits ? termLimits.termFrom : constraints.termInterval.min,
    max: termLimits ? termLimits.termTo : constraints.termInterval.max,
    defaultValue: termLimits ? termLimits.termTo : constraints.termInterval.defaultValue,
  };
};

export function fetchApplicationFirstLoanOffer(amount, term, productNumber) {
  const url = (productNumber === 'default' || !productNumber)
    ? '/application/first-loan-offer'
    : `/application/first-loan-offer/product-number/${productNumber}`;

  return ({ httpGet }: { httpGet: (path: string, params: Record<string, any>) => any }) => ({
    type: 'FETCH_APPLICATION_FIRST_LOAN_OFFER',
    meta: { amount, term, productNumber },
    payload: {
      promise: httpGet(
        url,
        { amount, term },
      ),
    },
  });
}

async function fetchOfferPromise(id: string, {
  dispatch,
  getState,
  getApiResponse,
}: ActionArguments): Promise<void> {
  let {
    [id]: {
      amount = null,
      term = null,
    } = {},
  } = getState().calculator.toJS();
  const {
    authentication: {
      isLoggedIn,
    },
  } = getState();
  const constraintsPath = ['fetch', isLoggedIn && 'client', 'application', 'constraints'].filter(Boolean);
  const offerAction = isLoggedIn ? api.fetchClientApplicationOffer : fetchApplicationFirstLoanOffer;
  const constraints = await getApiResponse(constraintsPath);
  const termInterval = getTermInterval(constraints, amount || constraints.amountInterval.defaultValue);

  if ([amount, term].includes(null)) {
    dispatch(setAmount(constraints.amountInterval.defaultValue, id));
    dispatch(setTerm(termInterval.defaultValue, id));
    amount = constraints.amountInterval.defaultValue;
    term = termInterval.defaultValue;
  }

  if (termInterval.min === termInterval.max && isLoggedIn) {
    dispatch(fetchApplicationFirstLoanOffer(amount, termInterval.min, id));
  }

  const { payload } = await dispatch(offerAction(amount, term, id));

  dispatch(setMonthlyPayment(payload.monthlyPayment, id));
}

const fetchDebouncedOfferPromise = debounce(fetchOfferPromise, 100);

export function fetchOffer(id = 'default'): (a: ActionArguments) => FetchOffer {
  return ({
    dispatch,
    getState,
    getApiResponse,
  }) => ({
    type: CALCULATOR_FETCH_OFFER,
    payload: fetchDebouncedOfferPromise(id, {
      dispatch,
      getState,
      getApiResponse,
    }),
  });
}
export function setValuesFromApplication(): (a: ActionArguments) => Promise<void> {
  const id = 'default';

  return async ({
    dispatch,
    getState,
    getApiResponse,
  }) => {
    if (!getState().authentication.get('isLoggedIn')) {
      return;
    }

    const {
      amount,
      term,
    } = await getApiResponse(['fetch', 'client', 'application'], {
      forceFetch: true,
    });
    const {
      payload: monthlyPayment,
    } = await dispatch(api.fetchClientApplicationMonthlyPayment(amount, term));

    dispatch(setMonthlyPayment(monthlyPayment, id));
    dispatch(setAmount(amount, id));
    dispatch(fetchOffer(id));
  };
}
export function setMonthlyPaymentInterval(paymentInterval: Record<string, any>, amount: number): SetMonthlyPaymentInterval {
  return {
    type: CALCULATOR_SET_MONTHLY_PAYMENT_INTERVAL,
    payload: {
      paymentInterval,
      amount,
    },
  };
}
export function updateMonthlyPaymentInterval(amount: number): (a: {
  dispatch: DispatchType<SetMonthlyPaymentInterval | SetMonthlyPayment>
  httpGet: (path: string) => Promise<Response>
  getState: () => RootState
}) => Promise<void> {
  return async ({
    dispatch,
    httpGet,
    getState,
  }) => {
    const currentPaymentInterval = getState().calculator.getIn(['monthlyPaymentInterval', amount]);
    const isLoggedIn = getState().authentication.get('isLoggedIn');
    const prefix = isLoggedIn ? '/client' : '';

    if (currentPaymentInterval) {
      return;
    }

    const paymentInterval = await httpGet(`${prefix}/application/monthly-payment-interval?amount=${amount}`);

    await dispatch(setMonthlyPaymentInterval(paymentInterval, amount));
    const newPaymentInterval = getState().calculator.getIn(['monthlyPaymentInterval', amount]);

    dispatch(setMonthlyPayment(newPaymentInterval.defaultValue));
  };
}
