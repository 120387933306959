import * as R from '../../../browser/routes/routesList';

export default async function requireApplicationConfirmed({
  dispatch,
  getApiResponse,
}) {
  const application = await getApiResponse(['fetch', 'client', 'application'], {
    forceFetch: true,
  });

  if (application?.confirmed) {
    return null;
  }

  dispatch({
    type: 'GUARD_APPLICATION_CONFIRMED',
  });

  return R.DASHBOARD;
}
