
import { Image } from '../../../lib/content/components';
import Background from '../../components/layout/Background';
import Button from '../../components/Button';
import Disclaimer from '../../components/Disclaimer';
import injectStyles from '4finance-components-pl';
import styles from './Banner.jss';
import type { ContentLoginModalBanner } from '../../../lib/content/types';

type Props = {
  classes: Record<string, any>;
  content: ContentLoginModalBanner;
};

const Banner = (props: Props): JSX.Element => {
  const {
    classes,
    content,
  } = props;
  const {
    logo,
    url,
    line1,
    line2,
    line3,
    line4,
    buttonText,
    loanInfoText,
    legal,
  } = content.fields;

  return (
    <a href={url} className={classes.outerContainer} target={'_blank'} rel={'noopener noreferrer'}>
      <Background src="/assets/img/modal-mrhi.png" position={'96% bottom'} size={'40% auto'} height={'100%'}>
        <div className={classes.innerContainer}>
          <Image content={logo} className={classes.logo} />
          <div className={classes.line1}>{line1}</div>
          <div className={classes.line2}>{line2}</div>
          <div className={classes.line1}>{line3}</div>
          <div className={classes.line2}>{line4}</div>
          <Button className={classes.button}>
            {buttonText}
          </Button>
          <div>
            <Disclaimer
              title={loanInfoText}
              content={(
                <div dangerouslySetInnerHTML={{
                  __html: legal,
                }}
                />
)}
              className={classes.disclaimer}
            />
          </div>
        </div>
      </Background>
    </a>
  );
};

Banner.styleRoot = 'Banner';
export default injectStyles(styles)(Banner);
