import * as R from '../../browser/routes/routesList';

export default async function requireActiveLoan({
  getApiResponse,
  dispatch,
}) {
  const { hasOpenLoan } = await getApiResponse(['fetch', 'client']);

  if (hasOpenLoan) {
    return null;
  }

  dispatch({
    type: 'GUARD_REQUIRE_ACTIVE_LOAN_FAILED',
  });

  return R.DASHBOARD;
}
